import React, { useEffect, useMemo, useState, useRef } from 'react';

import classNames from 'classnames';
import { Form } from 'react-final-form';
import { useParams } from 'react-router-dom';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { ProgressBar } from '@travauxlib/shared/src/components/DesignSystem/components/ProgressBar';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { godModeFlows } from '@travauxlib/shared/src/lib/GodMode/gdm_flows';
import { Flow } from '@travauxlib/shared/src/lib/GodMode/gdm_flows/Flow';
import {
  ExtractTypeFromField,
  StepField,
} from '@travauxlib/shared/src/lib/GodMode/gdm_steps/BaseStep';
import { resolvedDeferred, deferred, Deferred } from '@travauxlib/shared/src/utils/deferred';
import { request } from '@travauxlib/shared/src/utils/request';

import { PageTitle } from 'components/PageTitle';

import { Field } from './Field';
import { Warnings } from './Warnings';

type FlowState = 'pending' | 'running' | 'error' | 'completed';

export const FlowExecution: React.FC = () => {
  const { flowName } = useParams<{ flowName: string }>();
  const initialValueSeed = useMemo(() => Date.now().toString(10), []);
  const [flowState, setFlowState] = useState<FlowState>('pending');
  const [outText, setOutText] = useState<string>('');
  const [successFullSteps, setSuccessFullSteps] = useState<number>(0);

  const [isStepByStep, setIsStepByStep] = useState(false);

  // we give a promise to the execution to allow halting it between steps
  // each new step resolve that promise and creates a new one to be awaited
  const deferredRef = useRef<Deferred<void>>(resolvedDeferred(undefined));

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 100);
  }, [outText]);

  const selectedFlow: Flow<any, any> | undefined = useMemo(() => {
    if (!flowName) {
      return undefined;
    }
    return godModeFlows[flowName]?.();
  }, [flowName]);

  useEffect(() => {
    if (successFullSteps === selectedFlow?.stack.length) {
      setFlowState('completed');
    }
  }, [successFullSteps]);

  if (!flowName) {
    return <>Not flow specified</>;
  }
  if (!selectedFlow) {
    return <>Flow not found</>;
  }

  if (APP_CONFIG.apiURL === 'https://api.hemea.com/api') {
    return <>Non mais ca va pas bien la tete</>;
  }

  const execFlow = async (
    params: ExtractTypeFromField<StepField[]> & { seed: string },
  ): Promise<void> => {
    let isError = false;
    setFlowState('running');

    await selectedFlow.executeFlow(
      step => {
        const [stdout, stderr] = step.getOutputs();
        setSuccessFullSteps(s => s + 1);
        setOutText(
          previousText =>
            `${previousText ? `${previousText}\n\n` : ''}[OUT][${step.getName()}]: ${stdout} ${
              stderr ? `\n[ERR][${step.getName()}]: ${stderr}` : ''
            }`,
        );
      },
      () => {
        isError = true;
        setFlowState('error');
        setOutText(t => `${t}\n\n/!\\ The flow has failed ! Check logs and console /!\\`);
      },
    )(
      {
        ...params,
        apiURL: APP_CONFIG.apiURL,
        adminURL: APP_CONFIG.adminURL,
        appURL: APP_CONFIG.appURL,
        request: request,
      },
      { deferredRef },
    );

    if (isError) {
      setOutText(prevText => `${prevText}\n\nFlow aborted due to error !`);
    } else {
      setOutText(prevText => `${prevText}\n\nFlow completed !`);
    }
  };

  const flowFields: { flow: string; fields: readonly StepField[] }[] = [
    { flow: 'Global', fields: [{ name: 'seed', initialValue: initialValueSeed, required: true }] },
    ...selectedFlow.stack
      .map(step => ({ flow: step.name, fields: step.fields }))
      .filter(({ fields }) => fields.length !== 0),
  ];

  return (
    <>
      <Warnings />
      <div className="text-ds-h3">{flowName}</div>
      <Form<ExtractTypeFromField<StepField[]> & { seed: string }> onSubmit={execFlow}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {flowFields.map(({ flow, fields }, index) => (
              <div key={`${flow}-${index}`} className="w-full">
                <h3>{flow}</h3>
                <div className="grid grid-cols-2 md-desktop:grid-cols-4 gap-md mb-md">
                  {fields.map(field => (
                    <Field key={field.name} field={field} />
                  ))}
                </div>
              </div>
            ))}
            <Button className="mr-xs" type="submit" disabled={flowState !== 'pending'}>
              Start flow
            </Button>
            <Button
              onClick={() => {
                setIsStepByStep(true);
                deferredRef.current = deferred();
                execFlow(values);
              }}
              variant="secondary"
              disabled={flowState !== 'pending'}
            >
              Debug flow
            </Button>
          </form>
        )}
      </Form>

      <PageTitle title="Flow - GodMode" />
      {isStepByStep && flowState !== 'completed' && (
        <div className="fixed left-1/2 bottom-0 mb-md z-20">
          <Button
            className="my-xs"
            onClick={() => {
              deferredRef.current.resolve();
              window.setTimeout(() => {
                deferredRef.current = deferred();
              });
            }}
            size="sm"
            type="button"
          >
            Étape suivante
          </Button>
        </div>
      )}
      <ProgressBar
        value={(successFullSteps / selectedFlow.stack.length) * 100}
        label="Flow ..."
        size="lg"
        variant={
          flowState === 'completed' ? 'success' : flowState === 'error' ? 'error' : 'primary'
        }
      />
      {outText && (
        <div className="mb-4xl">
          <MultilineParagraph
            hasLinksOpenedInNewWindow
            text={outText}
            paragraphClassName={classNames(
              'shadow-ds-xs font-mono mt-sm bg-white p-lg my-lg !text-ds-b1 border-l-[8px] border-l-transparent',
              {
                'last:border-l-error-400': flowState === 'error',
                'last:border-l-success-400': flowState === 'completed',
              },
            )}
          />
        </div>
      )}
    </>
  );
};
