import React from 'react';

import { ArchivedToggle } from '@travauxlib/shared/src/components/ArchivedToggle';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';

import { ProCompanyUsers } from './ProCompanyUsers';

import { useOpenHandleProCompanyUserModal } from '../hooks/useOpenAddOrUpdateProUserModal';
import { ProCompanyUsersControlPannelProps } from '../types';

export const ProCompanyUsersControlPannel: React.FC<ProCompanyUsersControlPannelProps> = props => {
  const { className, setShowArchived, showArchived, onSubmit } = props;
  const openHandleProCompanyUserModal = useOpenHandleProCompanyUserModal();

  return (
    <div className={className}>
      <div className="flex items-center mb-xl">
        <h2 className="mr-sm">Utilisateurs</h2>
      </div>
      <div className="flex items-center flex-wrap flex-row justify-start mb-md md-desktop:mb-0">
        <div className="tablet:mx-md mr-md text-neutral-800 text-ds-h6 tablet:text-ds-h5 font-bold sm-desktop:mt-0 mt-md sm-desktop:ml-0 sm-desktop:mb-md md-desktop:mb-0">
          Liste des utilisateurs
        </div>
        <div className="flex grow justify-between flex-wrap">
          <div className="tablet:mr-md mt-md sm-desktop:mt-0">
            <ArchivedToggle
              id="user-panel"
              showArchived={showArchived}
              setShowArchived={setShowArchived}
              activeLabel="Actifs"
            />
          </div>
          <div className="mb-md tablet:mb-0 mt-md sm-desktop:mt-0">
            {!showArchived && (
              <Button
                type="submit"
                onClick={() => openHandleProCompanyUserModal({ onSubmit })}
                className="tablet:mx-md mr-md"
                leftIcon={<PlusSymbol />}
              >
                Inviter un utilisateur
              </Button>
            )}
          </div>
        </div>
      </div>
      <ProCompanyUsers {...props} />
    </div>
  );
};
