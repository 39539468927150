import React from 'react';

import { Navigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { AccountItem } from 'features/Partners/components/AccountItem';
import { useAgency } from 'features/Partners/hooks/useAgency';
import { usePartner } from 'features/Partners/hooks/usePartner';

import { NewAccountButton } from './NewAccountButton';
import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

export const AgencyAccountListing: React.FC = () => {
  const partner = usePartner();
  const agency = useAgency();
  const accountUuid = useSearchParam('accountUuid');
  if (!partner || !agency) {
    return <Navigate to=".." />;
  }

  const isDisabled = partner.isReseauIndependant && agency.accounts.length >= 1;

  return (
    <PartnerDetailsContent>
      <div className="grid grid-cols-12 gap-md">
        <div className="min-h-[120px] col-span-4">
          <NewAccountButton disabled={isDisabled} />
        </div>
        {agency.accounts.map(account => (
          <React.Fragment key={account.email}>
            <AccountItem
              account={account}
              allAgencies={partner.agencies}
              partner={partner}
              agency={agency}
              defaultIsEditing={account.uuid === accountUuid}
            />
          </React.Fragment>
        ))}
      </div>
    </PartnerDetailsContent>
  );
};
