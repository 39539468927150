import React from 'react';

import dayjs from 'dayjs';
import { useForm } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { SlotRDV } from '@travauxlib/shared/src/types';
import { ProUser } from '@travauxlib/shared/src/types/company';
import { required } from '@travauxlib/shared/src/utils/form';
import { toCalendarFormatForDateTime } from '@travauxlib/shared/src/utils/time';

import { ProCompany } from 'types';

export const computeHasSlotsInTheFuture = (slotsRendezVous: SlotRDV[]): boolean => {
  const now = dayjs();

  return slotsRendezVous.some(
    slot => now.isBefore(slot.start) || (now.isAfter(slot.start) && now.isBefore(slot.end)),
  );
};

export const getProUserOption = (proUser: ProUser): { label: string; value: number } => ({
  label: `${proUser.fullName} (${proUser.email} - ${proUser.phoneNumber})`,
  value: proUser.id!,
});

type Props = {
  proCompany: ProCompany;
  slotsRendezVous: SlotRDV[];
};

export const CompanyDetails: React.FC<Props> = ({ proCompany, slotsRendezVous }) => {
  const proUsersOptions = proCompany.proUsers.map(getProUserOption);
  const hasSlotsInTheFuture = computeHasSlotsInTheFuture(slotsRendezVous);
  const form = useForm();
  const { withNoRdv, dureeValiditeHeures } = form.getState().values[proCompany.uuid];

  return (
    <>
      <h3 className="!text-center !mb-lg">{proCompany.name}</h3>
      <DropdownField
        name={`${proCompany.uuid}.proUserId`}
        id={`pro-user-${proCompany.uuid}`}
        label="Utilisateur qui reçoit les notifs (email et sms)"
        options={proUsersOptions}
        className="mb-md"
        validate={required}
      />
      <div className="!mb-lg">
        <NumberInputField
          name={`${proCompany.uuid}.dureeValiditeHeures`}
          id={`duree-validite-${proCompany.uuid}`}
          label="Durée de validité de cette consultation (heures)"
          step={1}
          min={0}
          validate={required}
        />
      </div>
      {hasSlotsInTheFuture || withNoRdv ? (
        <div className="relative px-lg py-sm rounded bg-info-50 !items-center !mb-xl">
          🕗 La consultation expirera{' '}
          {toCalendarFormatForDateTime(dayjs().add(dureeValiditeHeures, 'hour'))}.
        </div>
      ) : (
        <div className="!mb-xl relative px-lg py-sm rounded bg-error-100 text-error">
          Aucune disponibilité du client n'est configurée sur le lot.
        </div>
      )}
      <CheckboxField
        name={`${proCompany.uuid}.withNoRdv`}
        id={`withNoRdv-${proCompany.uuid}`}
        label="RDV non nécessaire"
      />
    </>
  );
};
