import React, { useState } from 'react';

import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';

import Booster from '@travauxlib/shared/src/components/DesignSystem/assets/Booster.svg?react';
import CalculateOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CalculateOutline.svg?react';
import Dashboard from '@travauxlib/shared/src/components/DesignSystem/assets/Dashboard.svg?react';
import DocumentEdit from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentEdit.svg?react';
import HandshakeOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandshakeOutline.svg?react';
import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import LittleStarsOutline from '@travauxlib/shared/src/components/DesignSystem/assets/LittleStarsOutline.svg?react';
import logo from '@travauxlib/shared/src/components/DesignSystem/assets/Logo.svg';
import LogoutTool from '@travauxlib/shared/src/components/DesignSystem/assets/LogoutTool.svg?react';
import ProjectIcon from '@travauxlib/shared/src/components/DesignSystem/assets/Project.svg?react';
import StoreOutline from '@travauxlib/shared/src/components/DesignSystem/assets/StoreOutline.svg?react';

import { useLogout } from 'api/useAuth';
import { adminUrls } from 'utils/urls';

const navItems = [
  {
    Icon: Dashboard,
    to: adminUrls.leadsUrl,
    label: 'Dashboard CDP',
  },
  {
    Icon: DocumentEdit,
    to: adminUrls.proRegistrations,
    label: 'Inscriptions pro',
  },
  {
    Icon: ProjectIcon,
    to: '/projects',
    label: 'Gestion de projet',
  },
  {
    Icon: StoreOutline,
    to: '/entreprises',
    label: 'Entreprises',
  },
  {
    Icon: Booster,
    to: '/leads',
    label: 'Leads',
  },
  {
    Icon: HandyManOutline,
    to: '/chantiers',
    label: 'Chantiers',
  },
  {
    Icon: HandshakeOutline,
    to: adminUrls.partnerUrl,
    label: 'Partenaires',
  },
  {
    Icon: CalculateOutline,
    to: '/comparateur',
    label: 'Comparateur',
  },
  {
    Icon: LittleStarsOutline,
    to: '/trombinoscope',
    label: 'Trombi',
  },
];

export const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const logout = useLogout();

  return (
    <aside
      onMouseEnter={() => setIsOpen(true)}
      onTouchStart={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onTouchEnd={() => setIsOpen(false)}
      className="shrink-0 w-3xl h-dvh"
    >
      <div
        className={classNames(
          'fixed top-0 bottom-0 transition-[width] w-3xl w-3xl bg-white shadow-ds-sm z-30 flex-col py-md px-xs',
          isOpen && '!w-[300px] duration-[500ms]',
        )}
      >
        <div className="mb-xxl">
          <div className="my-xs mx-xs flex overflow-hidden">
            <div className="overflow-hidden max-w-full flex justify-center shrink-0">
              <Link to="/">
                <img src={logo} className="h-xl w-[116px] shrink-0 bg-contain" />
              </Link>
            </div>
          </div>
        </div>
        <div className="mb-xl flex flex-col gap-y-xxs">
          {navItems.map(({ Icon, to, label }) => (
            <NavLink
              onClick={() => setIsOpen(false)}
              key={to}
              to={to}
              className={({ isActive }) =>
                classNames(
                  '!no-underline hover:bg-neutral-100 rounded-sm text-neutral-800 text-ds-b1 font-bold p-sm flex no-wrap overflow-hidden',
                  isActive ? '!text-primary bg-neutral-100' : '!text-neutral-800',
                )
              }
            >
              <Icon className="w-lg h-lg mr-xxs shrink-0" />
              <span className={classNames('hidden whitespace-nowrap', isOpen && '!block')}>
                {label}
              </span>
            </NavLink>
          ))}
        </div>
        <div className="flex flex-col">
          <button
            type="button"
            onClick={logout}
            className="hover:bg-neutral-100 rounded-sm text-neutral-800 text-ds-b1 font-bold p-sm flex no-wrap overflow-hidden bg-transparent"
          >
            <LogoutTool className="w-lg h-lg mr-xxs shrink-0" />
            <span className={classNames('hidden whitespace-nowrap', isOpen && '!block')}>
              Déconnexion
            </span>
          </button>
        </div>
      </div>
    </aside>
  );
};
