import React, { useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';
import { Form } from 'react-final-form';
import { useOutletContext } from 'react-router-dom';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';

import { ConsultationList } from 'features/Entreprises/components/ConsultationList';
import { ProCompany } from 'types';

type FormValues = {
  merCreationDate: Dayjs;
};

export const Consultation: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const [dateSearch, setDateSearch] = useState(formatLocalDate(dayjs()));

  return (
    <div className="!p-lg border border-solid border-gray-300 border-0 !rounded !bg-white !shadow-ds-xs">
      <Form<FormValues>
        onSubmit={({ merCreationDate }) => setDateSearch(formatLocalDate(merCreationDate))}
      >
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="container !p-0 flex flex-wrap -mx-md !mx-0">
              <div className="tablet:w-3/12 tablet:px-md relative">
                <DatePickerField
                  id="merCreationDate"
                  name="merCreationDate"
                  label="Date de création de la consultation jusqu'à :"
                />
              </div>
              <div className="tablet:w-3/12 tablet:px-md relative">
                <Button
                  type="submit"
                  disabled={!values.merCreationDate}
                  disabledMessageTooltip="Pas de date de création renseignée"
                >
                  Rechercher
                </Button>
              </div>
            </div>
            <ConsultationList
              dateSearch={dateSearch}
              proCompany={proCompany}
              title="lots envoyés au total"
            />
          </form>
        )}
      </Form>
    </div>
  );
};
