import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class DashboardCDPTrackingClass extends Tracker<AdminFeatures> {
  onDashboardCPDViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const DashboardCDPTracking = new DashboardCDPTrackingClass(AdminFeatures.DashboardCDP);
