import React from 'react';

import classNames from 'classnames';

import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { LotIcon } from '@travauxlib/shared/src/features/DevisDisplay/components/LotIcon';
import { ComparisonStatus } from '@travauxlib/shared/src/types';

import { getBGColorFromComparisonStatus } from '../utils/getBGColorFromComparisonStatus';

type Props = {
  label: string;
  prixTotalHT: number;
  priceDifference?: number;
  comparisonStatus: ComparisonStatus;
};

export const DevisComparatorLotColumn: React.FC<Props> = ({
  label,
  prixTotalHT,
  comparisonStatus,
  priceDifference,
}) => (
  <div
    className={classNames(
      'w-full flex flex-wrap text-ds-b1 font-bold px-md pt-md rounded-t-xs bg-neutral-0 mr-md pb-xs',
      getBGColorFromComparisonStatus(comparisonStatus),
    )}
  >
    <span className="flex items-center text-neutral-800 text-ds-b1">
      <LotIcon className="pr-xxs" lotLabel={label} />
      {label}
      {(comparisonStatus === 'missing' || comparisonStatus === 'added') && (
        <Tag
          size="md"
          className="mx-xxs font-normal"
          variant={comparisonStatus === 'added' ? 'success' : 'error'}
          label={comparisonStatus === 'added' ? 'Ajouté' : 'Manquant'}
        />
      )}
    </span>
    <div className="flex text-right ml-auto items-center">
      {priceDifference && priceDifference !== 0 ? (
        <span className="text-ds-sm font-normal text-neutral-700 mr-xxs sm-desktop:hidden block">
          {priceDifference > 0 && '+'}
          <EURCurrency amount={priceDifference} /> HT
        </span>
      ) : null}
      <span className="ml-auto">
        {' '}
        <EURCurrency amount={prixTotalHT} /> HT
      </span>
    </div>
  </div>
);
