import React from 'react';

import { Form } from 'react-final-form';

import { CheckboxField } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { QuestionMarkTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip/QuestionMarkTooltip';
import { FileUpload } from '@travauxlib/shared/src/components/FileUpload';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import { TeamsLabel } from '@travauxlib/shared/src/types';
import { required, validateGoogleMapAddress, validateUrl } from '@travauxlib/shared/src/utils/form';

import { PeopleSelectorField } from 'components/PeopleSelector/Field';
import { useUploadLogoPartner } from 'features/Partners/api/useUploadLogoPartner';
import { usePartner } from 'features/Partners/hooks/usePartner';
import { useTeamMembers } from 'features/Trombinoscope/api/useTeams';
import { PartnerOrganization } from 'types';

import { AttributionNameField } from './AttributionNameField';
import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

type Props = {
  onSubmit: (values: PartnerOrganization) => Promise<unknown>;
};

export const OrganizationForm: React.FC<Props> = ({ onSubmit }) => {
  const partner = usePartner();
  const { uploadLogoPartner, isUploadingLogo } = useUploadLogoPartner();
  const { teamMembers: partnersTeamMembers } = useTeamMembers([
    TeamsLabel.Partenaires,
    TeamsLabel.CDPL,
  ]);

  return (
    <Form<PartnerOrganization> onSubmit={onSubmit} initialValues={partner}>
      {({ handleSubmit, dirty, submitting }) => (
        <PartnerDetailsContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Enregistrer',
            type: 'submit',
            disabled: !dirty || submitting,
            loading: submitting,
          }}
        >
          <div className="grid grid-cols-12 gap-md">
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField id="partnerName" name="name" label="Partenaire" validate={required} />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <PeopleSelectorField
                id="internalAccountManager"
                name="internalAccountManager"
                label="Account manager"
                persons={partnersTeamMembers}
                allowEmpty
              />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField id="billingEmail" name="billingEmail" label="Email facturation" />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <PlacesAutocompleteField
                id="googleMapsAddress"
                name="googleMapsAddress"
                label="Adresse"
                validate={validateGoogleMapAddress('postal_code')}
                googleApiKey={APP_CONFIG.googleApiKey}
                initialInputValue={partner?.googleMapsAddress?.formatted_address}
              />
            </div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                id="urlDashboardReporting"
                name="urlDashboardReporting"
                label="Lien du dashboard clicdata"
                validate={url => url && validateUrl(url)}
              />
            </div>

            <div className="col-span-full flex gap-md mb-md">
              <CheckboxField
                id="isArchived"
                name="isArchived"
                label="Désactiver le partenaire"
                defaultValue={false}
              />
              <CheckboxField
                id="isRenovationEnergetique"
                name="isRenovationEnergetique"
                label="Partenaire Rénovation Énergetique"
                defaultValue={false}
              />
              <div className="flex">
                <CheckboxField
                  id="isReseauIndependant"
                  name="isReseauIndependant"
                  label="Réseau d'indépendants"
                  defaultValue={false}
                />
                <QuestionMarkTooltip innerTooltipText="Les agences au sein des réseaux d'indépendants ne peuvent avoir qu'un compte maximum" />
              </div>
            </div>
            {partner?.uuid && (
              <div className="col-span-full sm-desktop:col-span-6">
                <FileUpload
                  isUploading={isUploadingLogo}
                  alreadyUploaded={!!partner.logoUrl}
                  label={partner.logoUrl ? 'Remplacer le logo' : 'Ajouter un logo'}
                  accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
                  onChange={async file => {
                    const { uuid } = partner;
                    const formData = new FormData();
                    formData.append('logo', file);

                    uploadLogoPartner({ formData, uuid });
                  }}
                  onReject={rejectionMessage => toast.error(rejectionMessage)}
                  alignLeft
                />
              </div>
            )}
            <div className="col-start-2 col-end-12">
              <hr className="my-0" />
            </div>
            <div className="col-span-full text-ds-h5">Attribution</div>
            <div className="col-span-full sm-desktop:col-span-6">
              <AttributionNameField />
            </div>
            <div className="col-start-2 col-end-12">
              <hr className="my-0" />
            </div>
            <div className="col-span-full text-ds-h5">Estimateur</div>
            <div className="col-span-full sm-desktop:col-span-6">
              <CheckboxField
                id="estimateurConfigDisabled"
                name="estimateurConfig.disabled"
                label="Bloquer l'accès à l'estimateur"
                className="!block !mb-md"
                defaultValue={false}
              />
              <CheckboxField
                id="estimateurConfigShowLogoInPDF"
                name="estimateurConfig.showLogoInPDF"
                label="Afficher le logo dans les PDF"
                className="!block !mb-md"
                defaultValue={false}
              />
            </div>
            <div className="col-span-full text-ds-h5">Commission</div>
            <div className="col-span-full sm-desktop:col-span-6">
              <InputField
                id="commissionConfig.conditions"
                name="commissionConfig.conditions"
                label="Conditions"
                className="!block !mb-md"
              />
              <NumberInputField
                id="commissionConfig.pourcentage"
                name="commissionConfig.pourcentage"
                label="Pourcentage"
                className="!block !mb-md"
                suffix="%"
                min={0}
                max={100}
              />
            </div>
          </div>
        </PartnerDetailsContent>
      )}
    </Form>
  );
};
