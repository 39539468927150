import React from 'react';

import { useOutletContext } from 'react-router-dom';

import { ProCompany } from 'types';

import { DownloadFiles } from './DownloadFiles';
import { PaymentCreated } from './PaymentCreated';
import { PaymentInformation } from './PaymentInformation';
import { PaymentToCreate } from './PaymentToCreate';

export const Payment: React.FC = () => {
  const { proCompany } = useOutletContext<{ proCompany: ProCompany }>();
  const { slug, isKycCompliant, lemonwayAccountUrl, ribUrl, kbisUrl, insurances } = proCompany;

  return (
    <div className="!p-lg border border-solid border-gray-300 border-t-0 !rounded !bg-white !shadow-ds-xs">
      <PaymentInformation proCompany={proCompany} />
      {lemonwayAccountUrl ? (
        <PaymentCreated
          slug={slug}
          lemonwayAccountUrl={lemonwayAccountUrl}
          isKycCompliant={isKycCompliant}
        />
      ) : (
        <PaymentToCreate slug={slug} />
      )}
      {!isKycCompliant && (
        <DownloadFiles ribUrl={ribUrl} kbisUrl={kbisUrl} insurances={insurances} />
      )}
    </div>
  );
};
