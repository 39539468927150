import React from 'react';

import { FieldArray } from 'react-final-form-arrays';

import Straighten from '@travauxlib/shared/src/components/DesignSystem/assets/Straighten.svg?react';
import { ChipListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { Isolation } from '@travauxlib/shared/src/features/Configurateur/types/Platrerie';
import { TypeBien } from '@travauxlib/shared/src/types';

import { Accordion } from 'features/Configurateur/components/Accordion';
import { AddPrestationButton } from 'features/Configurateur/components/AddPrestationButton';
import { LocationsDimensions } from 'features/Configurateur/components/LocationsDimensions';
import { MultiLocationsPicker } from 'features/Configurateur/components/MultiLocationsPicker';
import { OptionCheckbox } from 'features/Configurateur/components/OptionCheckbox';
import { PrestationDelete } from 'features/Configurateur/components/PrestationDelete';
import { PrestationsSeparator } from 'features/Configurateur/components/PrestationsSeparator';
import { QuestionTitle } from 'features/Configurateur/components/QuestionTitle';

type Props = {
  configuration: Configuration;
};

const MursInterieurs: React.FC<{
  configuration: Configuration;
  name: string;
  value: Isolation;
}> = ({ name, configuration, value }) => (
  <>
    <QuestionTitle prefix="3" title="Dimensions ?" />
    <LocationsDimensions
      configuration={configuration}
      locations={configuration.locations.filter(l => value.locationsUuid?.includes(l.uuid))}
      getFields={locationUuid => [
        {
          name: `${name}.locationQuantities.${locationUuid}.longueur`,
        },
        {
          defaultValue: configuration.hauteurSousPlafond,
          name: `${name}.locationQuantities.${locationUuid}.hauteur`,
        },
      ]}
    />
  </>
);

const MursExterieurs: React.FC<{
  name: string;
}> = ({ name }) => (
  <>
    <QuestionTitle prefix="2" title="Dimensions ?" />
    <div className="flex flex-wrap justify-between text-left">
      <div className="flex items-center mb-md">
        <Straighten className="shrink-0 text-primary h-lg w-lg mr-xs" />
        <label
          htmlFor={`${name}.surfaceFacade`}
          className="text-neutral-800 shrink-0 text-ds-b2 mb-xxs mr-md"
        >
          Surface façade
        </label>
        <NumberInputField
          className="max-w-[9rem]"
          suffix="m²"
          name={`${name}.surfaceFacade`}
          id={`${name}.surfaceFacade`}
        />
      </div>
    </div>
  </>
);

const ComblesPerdusOrComblesAAmenager: React.FC<{
  configuration: Configuration;
  name: string;
  typeIsolation: 'comblesPerdus' | 'comblesAAmenager';
}> = ({ name, typeIsolation }) => (
  <>
    <QuestionTitle prefix="3" title="Dimensions ?" />
    {typeIsolation === 'comblesPerdus' ? (
      <div className="flex flex-wrap justify-between text-left">
        <div className="flex items-center mb-md">
          <Straighten className="shrink-0 text-primary h-lg w-lg mr-xs" />
          <label
            htmlFor={`${name}.comblesPerdusSurfaceAIsoler`}
            className="text-neutral-800 shrink-0 text-ds-b2 mb-xxs mr-md"
          >
            Surface plancher à isoler
          </label>
          <NumberInputField
            className="max-w-[9rem]"
            suffix="m²"
            name={`${name}.comblesPerdusSurfaceAIsoler`}
            id={`${name}.comblesPerdusSurfaceAIsoler`}
          />
        </div>
      </div>
    ) : (
      <div className="flex flex-wrap justify-between text-left">
        <div className="flex items-center mb-md">
          <Straighten className="shrink-0 text-primary h-lg w-lg mr-xs" />
          <label
            htmlFor={`${name}.comblesAAmenagerDimensions.longueur`}
            className="text-neutral-800 shrink-0 text-ds-b2 mb-xxs mr-md"
          >
            Longueur totale
          </label>
          <NumberInputField
            className="max-w-[9rem]"
            suffix="m"
            name={`${name}.comblesAAmenagerDimensions.longueur`}
            id={`${name}.comblesAAmenagerDimensions.longueur`}
          />
        </div>
        <div className="flex items-center mb-md">
          <Straighten className="shrink-0 text-primary h-lg w-lg mr-xs" />
          <label
            htmlFor={`${name}.comblesAAmenagerDimensions.hauteur`}
            className="text-neutral-800 shrink-0 text-ds-b2 mb-xxs mr-md"
          >
            Hauteur totale
          </label>
          <NumberInputField
            className="max-w-[9rem]"
            suffix="m"
            name={`${name}.comblesAAmenagerDimensions.hauteur`}
            id={`${name}.comblesAAmenagerDimensions.hauteur`}
          />
        </div>
      </div>
    )}
  </>
);

const MurInterieurTooltipContent: React.FC = () => (
  <div>
    Types d'isolants selon les gammes :
    <br />- Primo : Plaque de PSE 120 mm
    <br />- Confort : Laine minérale 120 mm
    <br />- Premium & Exclusif : Fibre de bois 140 mm
  </div>
);
const PlafondsTooltipContent: React.FC = () => (
  <div>
    Types de plaques de plâtre et d'isolants selon les gammes :
    <br />- Primo & Confort : Placo standard, laine minérale 45mm
    <br />- Premium & Exclusif : Placo acoustique, fibre de bois 100 mm
  </div>
);

const LocationsChoice: React.FC<{
  configuration: Configuration;
  name: string;
  value: Isolation;
  prefix: string;
}> = ({ name, configuration, value, prefix }) => (
  <>
    <MultiLocationsPicker
      prefix={prefix}
      configuration={configuration}
      name={`${name}.locationsUuid`}
      tooltip={value.cible === 'Murs' ? <MurInterieurTooltipContent /> : <PlafondsTooltipContent />}
    />
    {!!value.locationsUuid?.length && value.cible !== 'Plafonds' && (
      <MursInterieurs name={name} configuration={configuration} value={value} />
    )}
  </>
);

const zoneAIsolerMaison: Array<{ label: string; value: Isolation['cible'] & string }> = [
  {
    label: 'Murs intérieurs',
    value: 'Murs',
  },
  {
    label: 'Murs extérieurs',
    value: 'Murs extérieurs',
  },
  {
    label: 'Plafonds',
    value: 'Plafonds',
  },
  {
    label: 'Combles',
    value: 'Combles',
  },
];

const zoneAIsolerAppart: Array<{ label: string; value: Isolation['cible'] & string }> = [
  {
    label: 'Murs intérieurs',
    value: 'Murs',
  },
  {
    label: 'Plafonds',
    value: 'Plafonds',
  },
  {
    label: 'Combles',
    value: 'Combles',
  },
];

export const Isolations: React.FC<Props> = ({ configuration }) => (
  <Accordion className="mb-md" title="Isolation à faire ?" prefix="2" defaultExpanded>
    <FieldArray<Isolation> name="isolations">
      {({ fields }) => (
        <div>
          {fields.map((name, index) => {
            const value = fields.value[index];
            const isCombles = value.cible === 'Combles';
            const isComblesAndDejaCloisonnes = value.typeIsolation === 'comblesDejaCloisonnes';
            const isComblesAndComblesAAmenager = value.typeIsolation === 'comblesAAmenager';
            const isComblesPerdus = value.typeIsolation === 'comblesPerdus';
            const isCombleAAmenagerOrPerdus = isComblesAndComblesAAmenager || isComblesPerdus;

            return (
              <div key={index} className="mb-lg">
                <div className="flex justify-between">
                  <QuestionTitle
                    prefix="1"
                    title="Zone à isoler ?"
                    tooltip={
                      <div>
                        L'isolation des murs et plafonds par l'intérieur implique une perte de
                        surface au sol et/ou hauteur sous plafond. L’isolation peut prendre 10 cm
                        d’épaisseur en moyenne.
                        <br />
                        <br />
                        L'isolation des plafonds peut être recommandée si la toiture de l'immeuble
                        n'est pas isolée.
                        {configuration.typeLogement !== TypeBien.Appartement && (
                          <>
                            <br />
                            L’isolation extérieure des murs par défaut est du PSE épaisseur 140mm R
                            = 3,7.
                          </>
                        )}
                      </div>
                    }
                  />
                  <PrestationDelete onDelete={() => fields.remove(index)} />
                </div>
                <OptionCheckbox fields={fields} value={value} index={index} />
                <ChipListField
                  name={`${name}.cible`}
                  renderingOptions={{
                    containerClassName: 'mb-md flex flex-wrap gap-xs',
                  }}
                  options={
                    configuration.typeLogement === TypeBien.Appartement
                      ? zoneAIsolerAppart
                      : zoneAIsolerMaison
                  }
                />
                {isCombles && (
                  <>
                    <QuestionTitle
                      prefix="2"
                      title="Type d'isolation ?"
                      tooltip={
                        <div>
                          S’il n’y a pas l’espace d’aménager les combles, on isole simplement le
                          plancher (combles perdus).
                          <br />
                          <br />
                          Si les combles sont déjà cloisonnés, on va isoler sous rampants dans
                          chaque pièce.
                          <br />
                          <br />
                          Si on veut aménager l’espace sous les combles on va isoler par-dessous la
                          toiture (combles sous-rampants). Pour le cloisonnement des combles par la
                          suite, se référer à la question sur les cloison à monter.
                          <br />
                          <br />
                          Type d'isolant par défaut toutes gammes confondues : Laine minérale
                        </div>
                      }
                    />
                    <ChipListField
                      name={`${name}.typeIsolation`}
                      renderingOptions={{
                        containerClassName: 'mb-md flex flex-wrap gap-xs',
                      }}
                      options={[
                        {
                          label: 'Combles perdus',
                          value: 'comblesPerdus',
                        },
                        {
                          label: 'Combles à aménager',
                          value: 'comblesAAmenager',
                        },
                        {
                          label: 'Combles déjà cloisonnés',
                          value: 'comblesDejaCloisonnes',
                        },
                      ]}
                    />
                    {value.typeIsolation &&
                      value.typeIsolation !== 'comblesDejaCloisonnes' &&
                      isCombleAAmenagerOrPerdus && (
                        <ComblesPerdusOrComblesAAmenager
                          name={name}
                          configuration={configuration}
                          typeIsolation={value.typeIsolation}
                        />
                      )}
                  </>
                )}

                {['Murs', 'Plafonds'].includes(value.cible!) || isComblesAndDejaCloisonnes ? (
                  <LocationsChoice
                    prefix={!isCombles ? '2' : '3'}
                    name={name}
                    configuration={configuration}
                    value={value}
                  />
                ) : null}
                {value.cible === 'Murs extérieurs' && <MursExterieurs name={name} />}
                <PrestationsSeparator />
              </div>
            );
          })}
          <AddPrestationButton fields={fields} />
        </div>
      )}
    </FieldArray>
  </Accordion>
);
