import React from 'react';

import { Navigate } from 'react-router-dom';

import { useCreatePartner } from 'features/Partners/api/mutations';
import { usePartner } from 'features/Partners/hooks/usePartner';

import { AgencyForm } from './AgencyForm';

export const CreateAgency: React.FC = () => {
  const partner = usePartner();
  const { createAgency } = useCreatePartner(partner?.uuid);
  if (!partner) {
    return <Navigate to=".." />;
  }

  return (
    <AgencyForm
      onSubmit={values =>
        createAgency({
          ...values,
          organizationUuid: partner.uuid,
        })
      }
      initialValues={{
        internalAccountManager: partner.internalAccountManager,
        estimateurConfig: partner.estimateurConfig,
      }}
    />
  );
};
