import React from 'react';

import { useParams, Outlet, Navigate } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { useProjectContext } from 'features/Project/api/useProjectContext';

import { Header } from './components/Header';

export const ProjectPage: React.FC = () => {
  const { uuid } = useParams();
  const { projectContext, isLoading } = useProjectContext(uuid!);

  if (isLoading) {
    return <LoaderWrapper />;
  }
  if (!projectContext) {
    return <Navigate to="/projects" />;
  }

  return (
    <div className="flex flex-col h-full">
      <Header projectContext={projectContext} />
      <Outlet context={projectContext} />
    </div>
  );
};
