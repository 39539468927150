import React from 'react';

import { useUpdateOrganization } from 'features/Partners/api/mutations';

import { OrganizationForm } from './OrganizationForm';

export const EditOrganization: React.FC = () => {
  const { updateOrganization } = useUpdateOrganization();

  return <OrganizationForm onSubmit={updateOrganization} />;
};
