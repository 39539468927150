import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { DevisForComparator } from '@travauxlib/shared/src/features/DevisDisplay/types';

type Props = {
  firstDocument: DevisForComparator;
  secondDocument: DevisForComparator;
};

export const DevisComparatorTableHeader: React.FC<Props> = ({ firstDocument, secondDocument }) => {
  const priceDifference = secondDocument.prixTotalHT - firstDocument.prixTotalHT;
  return (
    <div className="grid grid-cols-1 sm-desktop:grid-cols-[1fr_1fr_170px] w-full sticky top-[2.5rem] sm-desktop:top-[3.5rem] bg-neutral-100 shadow-[0px_8px_8px_0px_rgba(0,0,0,0.06)]">
      <div className="py-md pr-md pl-xl border-b-1 border-r-1 hidden sm-desktop:block">
        <span className="text-ds-sm font-medium text-neutral-700">{firstDocument.title}</span>
        <div className="flex items-center font-bold">
          <span className="text-ds-h4">{firstDocument.proCompanyName}</span>
          <span className="text-ds-h5 ml-auto">
            <EURCurrency amount={firstDocument.prixTotalHT} /> HT
          </span>
        </div>
      </div>
      <div className="py-md pr-md pl-xl border-b-1 border-r-1 hidden sm-desktop:block">
        <span className="text-ds-sm font-medium text-neutral-700">{secondDocument.title}</span>
        <div className="flex items-center font-bold">
          <span className="text-ds-h4">{secondDocument.proCompanyName}</span>
          <span className="text-ds-h5 ml-auto">
            <EURCurrency amount={secondDocument.prixTotalHT} /> HT
          </span>
        </div>
      </div>
      <div className="flex items-center justify-end font-bold py-md pl-md pr-xl border-b-1 hidden sm-desktop:flex">
        {priceDifference > 0 && '+ '} <EURCurrency amount={priceDifference} />
        &nbsp;HT
      </div>
      <div className="text-left flex flex-col sm-desktop:hidden text-ds-h5 font-bold col-span-1 p-sm pb-xs gap-xxs">
        <div className="mb-xs">
          <span className="text-ds-sm font-medium text-neutral-700">{firstDocument.title}</span>
          <div className="flex">
            {firstDocument.proCompanyName}{' '}
            <EURCurrency className="ml-auto" amount={firstDocument.prixTotalHT} />
            &nbsp;HT
          </div>
        </div>
        <div>
          <span className="text-ds-sm font-medium text-neutral-700">{secondDocument.title}</span>
          <div className="flex">
            {secondDocument.proCompanyName}{' '}
            <EURCurrency className="ml-auto" amount={secondDocument.prixTotalHT} />
            &nbsp;HT
          </div>
        </div>
      </div>
    </div>
  );
};
