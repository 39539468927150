import { AppelDePaiementStatus } from '@travauxlib/shared/src/features/Chantiers/types';
import { TypeSuivi } from '@travauxlib/shared/src/types';

export type MoneyFilterValue = 'indifferent' | 'en_totalite' | 'en_partie' | 'pas_du_tout' | 'n/a';

export type EnAttenteDe = 'provision' | 'validation' | 'liberation';
export type CurrentTab = 'en_cours' | 'termines' | 'tous' | EnAttenteDe;

export const appelDePaiementStatusThatCanBeUpdated: AppelDePaiementStatus[] = [
  'initiated',
  'pending',
];

export type Filters = {
  tab: CurrentTab;
  enAttenteDe?: EnAttenteDe;
  proCompanyName?: string;
  clientAccount?: string;
  pipedriveDealId?: string;
  suivi?: TypeSuivi | 'tous';
  suiviBy?: string;
  prestation: 'tous' | 'travaux' | 'conception';
  demarre?: 'oui' | 'non' | 'indifferent';
  commissionCaptee?: MoneyFilterValue;
  prestationHemeaEncaissee?: MoneyFilterValue;
  versementALentreprise?: MoneyFilterValue;
};
