import React from 'react';

import { Form } from 'react-final-form';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import { Project } from '@travauxlib/shared/src/types';

type Props = {
  project: Project;
  updateProject: (project: Project) => Promise<Project>;
  children: React.ReactElement;
};

export const ProjectForm: React.FC<Props> = ({ project, updateProject, children }) => (
  <Form<Project>
    initialValues={project}
    initialValuesEqual={() => true}
    onSubmit={async values => {
      await updateProject(values);

      return undefined;
    }}
  >
    {({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <AutoSubmitForm />
        {children}
      </form>
    )}
  </Form>
);
