import React from 'react';

import { Helmet } from '@travauxlib/shared/src/components/Helmet';

type PageTitleProps = {
  title: string;
};

export enum PageTitleEnum {
  chantiers = 'Chantiers',
  clientAccounts = 'Comptes client',
  dashboard = 'Dashboard CDP',
  defaultTitle = 'Admin',
  entreprises = 'Entreprises',
  fournitures = 'Fournitures',
  inscriptionPro = 'Inscription pro',
  leads = 'Leads',
  Consultations = 'Consultations',
  partners = 'Partenaires',
  trombinoscope = 'Trombinoscope',
  project = 'Projet',
  comparator = 'Comparateur',
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);
