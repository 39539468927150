import React from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { LigneComparison } from '@travauxlib/shared/src/features/DevisDisplay/types';
import { ComparisonStatus } from '@travauxlib/shared/src/types';

import { DevisComparatorLineColumn } from './DevisComparatorLineColumn';

type Props = {
  comparison: LigneComparison;
  lotComparisonStatus: ComparisonStatus;
  addBaseLotContainerBottom?: boolean;
};

export const DevisComparatorLineRow: React.FC<Props> = ({
  comparison,
  lotComparisonStatus,
  addBaseLotContainerBottom,
}) => {
  const diff = (comparison.compare?.prixHT || 0) - (comparison.base?.prixHT || 0);

  return (
    <>
      <div className="px-md border-r sm-desktop:block hidden">
        {comparison.base && (
          <DevisComparatorLineColumn
            comparison={comparison}
            firstColumn
            lotComparisonStatus="unchanged"
          />
        )}
        {addBaseLotContainerBottom && (
          <div className="bg-neutral-0 rounded-b-xxs pb-md mb-md shadow-ds-xxs" />
        )}
      </div>
      <div className="sm-desktop:px-md border-r-1">
        <DevisComparatorLineColumn
          comparison={comparison}
          lotComparisonStatus={lotComparisonStatus}
        />
      </div>
      <div className="hidden flex-col justify-end text-right text-ds-b2 align-bottom pb-sm sm-desktop:flex ">
        <div className={classNames('pr-xl pl-md text-right text-neutral-700')}>
          {diff > 0 && '+'}
          <EURCurrency amount={diff} suffix=" HT" />
        </div>
      </div>
    </>
  );
};
