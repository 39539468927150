import React, { useEffect, useRef } from 'react';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';
import {
  BaseDePrix,
  Configuration,
  QuestionId,
} from '@travauxlib/shared/src/features/Configurateur/types';
import { formData } from '@travauxlib/shared/src/features/Configurateur/utils/formData';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

import { getOuvrages } from '../utils/getOuvrages';

type Props = {
  questionId: QuestionId;
  onClose: () => void;
  baseDePrix: BaseDePrix;
  configuration: Configuration;
};

export const ResultTable: React.FC<Props> = ({
  questionId,
  baseDePrix,
  onClose,
  configuration,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  });
  const { title, editLink, Icon } = formData[questionId];

  const ouvrages = getOuvrages(baseDePrix, questionId, configuration);

  useOnClickOutside(containerRef, onClose);

  return (
    <>
      <div className="z-50 fixed top-0 h-screen left-0 w-screen bg-gray-900 bg-opacity-[0.64]" />
      <div
        ref={containerRef}
        className="z-50 fixed top-0 h-screen md-desktop:ml-md left-0 md-desktop:left-1/2 right-0 bg-white pt-sm flex flex-col"
      >
        <div className="flex mb-md justify-between pl-lg pr-sm shrink-0">
          <div className="flex items-center">
            <ColoredCircle className="mr-xs" size="sm" variant="beige">
              <Icon />
            </ColoredCircle>
            <div>
              <div className="text-ds-sm">Vous consultez</div>
              <div className="font-bold">{title}</div>
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CrossSymbol />
          </IconButton>
        </div>
        <div className="px-md grow overflow-auto">
          <table className="w-full text-ds-b2 rounded-xs overflow-hidden">
            <thead className="bg-primary text-white font-bold">
              <tr>
                <td className="px-xxs py-xs">N°</td>
                <td className="px-xxs py-xs">Désignation</td>
                <td className="px-xxs py-xs">Quantité</td>
              </tr>
            </thead>
            <tbody>
              {ouvrages.map(({ label, lignes }, indexLot) => (
                <React.Fragment key={label}>
                  <tr data-testid="ligne" className="bg-primary-50">
                    <td data-testid="index" className="px-xxs py-xs border-r">
                      {indexLot + 1}.0
                    </td>
                    <td data-testid="designation" className="px-xxs py-xs font-bold border-r">
                      {label}
                    </td>
                    <td data-testid="quantity" className="px-xxs py-xs" />
                  </tr>
                  {lignes.map(({ designation, quantity, unite, isOption }, index) => (
                    <tr
                      data-testid="ligne"
                      className="even:bg-neutral-100 odd:bg-white "
                      key={designation}
                    >
                      <td data-testid="index" className="px-xxs py-xs border-r">
                        {indexLot + 1}.{index + 1}
                      </td>
                      <td data-testid="designation" className="px-xxs py-xs border-r">
                        {designation}
                      </td>
                      <td data-testid="quantity" className="px-xxs py-xs whitespace-nowrap">
                        {isOption ? '(' : ''}
                        <QuantityLabel quantite={quantity} unite={unite} />
                        {isOption ? ')' : ''}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className="border-t h-[4.5rem] mx-md pt-xs">
          <ButtonLink to={editLink} leftIcon={<Edit />} variant="tertiary" type="button">
            Modifier les prestations
          </ButtonLink>
        </div>
      </div>
    </>
  );
};
