import React from 'react';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';

export function ArchitectFields(): JSX.Element {
  return (
    <div className="flex flex-wrap -mx-md mb-md">
      <div className="tablet:w-4/12 tablet:px-md relative">
        <InputField
          label="Numéro d'inscription à l'Ordre des Architectes"
          placeholder="Numéro d'inscription à l'Ordre"
          id="orderOfArchitectsId"
          name="orderOfArchitectsId"
        />
      </div>
    </div>
  );
}
