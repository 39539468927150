import React from 'react';

import { Navigate } from 'react-router-dom';

import { LeadAssignmentConfig, Person } from '@travauxlib/shared/src/types';

import { useAuth } from 'api/useAuth';
import { usePersons } from 'features/Trombinoscope/api/usePersons';
import { useSalesTeamMembers } from 'features/Trombinoscope/api/useTeams';

import { EditAssignmentConfigForm } from './EditAssignmentConfigForm';

export const EditAssignmentConfig: React.FC = () => {
  const {
    adminUserProfile: { hasPermission },
  } = useAuth();
  const { teamMembers: sales } = useSalesTeamMembers();
  const { createOrUpdatePerson } = usePersons();

  const handleAdminSubmit = (person: Person) => (leadAssignment: LeadAssignmentConfig) =>
    createOrUpdatePerson({
      ...person,
      config: {
        ...person.config,
        leadAssignment,
      },
    });

  if (!hasPermission('UPDATE_ASSIGNMENT_CONFIG')) {
    return <Navigate replace to=".." />;
  }

  return (
    <>
      <h3>Configuration de l'attribution automatique des leads</h3>
      <div className="flex flex-wrap -mx-md">
        {sales.map((person, i) => (
          <React.Fragment key={person.uuid}>
            <div className="w-4/12 px-md relative !p-sm">
              <div className="border border-solid border-gray-300 !rounded !bg-white !shadow-ds-xs !p-sm !h-full">
                <EditAssignmentConfigForm
                  displayName={`${person.firstName} ${person.lastName}`}
                  picture={person.officialPicture}
                  config={person.config.leadAssignment}
                  onSubmit={handleAdminSubmit(person)}
                />
              </div>
            </div>
            {(i + 1) % 3 === 0 && <div className="!w-full" />}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
