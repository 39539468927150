import React from 'react';

import { Form } from 'react-final-form';

import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { SlotRDV } from '@travauxlib/shared/src/types';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';

import { ManualModeReason, ConsultationOffer, ProCompany } from 'types';

import { CompanyDetails, computeHasSlotsInTheFuture } from './CompanyDetails';

import { useCreateConsultationOffer } from '../../api/useCreateConsultationOffer';

export const getInitialValues = (
  proCompany: ProCompany,
): {
  [K: string]: {
    proUserId?: number;
    dureeValiditeHeures: number;
    status: LabellisationStatus;
  };
} => {
  const proUser = proCompany.proUsers.length === 1 ? proCompany.proUsers[0] : undefined;
  const hour = new Date().getHours();

  return {
    [proCompany.uuid]: {
      proUserId: proUser?.id,
      dureeValiditeHeures: hour >= 6 && hour < 16 ? 6 : 20,
      status: proCompany.status,
    },
  };
};

type FormData = {
  [K: string]: {
    proUserId: number;
    dureeValiditeHeures: number;
    withNoRdv?: boolean;
    status: LabellisationStatus;
  };
};

type Props = {
  isOpen: boolean;
  consultationInfoId: number;
  selectedProCompanies: ProCompany[];
  proCompanies?: ProCompany[];
  manualModeReason?: ManualModeReason;
  onClose: () => void;
  slotsRendezVous: SlotRDV[];
  onConsultationDone: (consultationOffers: ConsultationOffer[]) => void;
  companyUuidsNotDisabled?: string[];
};

export const ConsultationModal: React.FC<Props> = ({
  isOpen,
  consultationInfoId,
  selectedProCompanies,
  proCompanies,
  onClose,
  slotsRendezVous,
  manualModeReason,
  onConsultationDone,
  companyUuidsNotDisabled,
}) => {
  const createConsultationOffer = useCreateConsultationOffer();
  const initialValues = selectedProCompanies.reduce(
    (previousValue, currentValue) => ({ ...previousValue, ...getInitialValues(currentValue) }),
    {},
  );

  const merData = {
    proCompanies:
      proCompanies?.map(({ id, status, score: { totalScore: score, ...rest } }, index) => ({
        proCompanyId: id,
        status,
        score,
        rank: index + 1,
        ...rest,
      })) || [],
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Choisir cette entreprise" size="lg">
      <Form<FormData>
        keepDirtyOnReinitialize
        initialValues={initialValues}
        onSubmit={async values => {
          const selectedProCompanyUuids = Object.keys(values);
          const hasOneCompanyToTestBeenSelected = Object.values(values).some(
            proCompanyInfo => proCompanyInfo.status === LabellisationStatus.labellisee,
          );

          const proCompanyUuidsNotTested =
            proCompanies && !hasOneCompanyToTestBeenSelected
              ? proCompanies
                  .filter(
                    proCompany =>
                      proCompany.status === LabellisationStatus.labellisee &&
                      companyUuidsNotDisabled?.includes(proCompany.uuid) &&
                      !selectedProCompanyUuids.includes(proCompany.uuid),
                  )
                  .map(proCompany => proCompany.uuid)
              : [];

          const createdConsultationOfferss = await Promise.all(
            Object.entries(values).map(
              async ([proCompanyUuid, { proUserId, dureeValiditeHeures, withNoRdv }], index) => {
                const rank =
                  proCompanies &&
                  `${proCompanies.findIndex(pc => pc.uuid === proCompanyUuid) + 1}/${
                    proCompanies.length
                  }`;

                return createConsultationOffer({
                  consultationInfoId,
                  proUserId,
                  manualModeReason,
                  merData,
                  rank,
                  dureeValiditeHeures,
                  withRdv: !withNoRdv,
                  proCompanyUuidsNotTested: index === 0 ? proCompanyUuidsNotTested : [],
                });
              },
            ),
          );

          onClose();
          onConsultationDone(createdConsultationOfferss);
        }}
      >
        {({ handleSubmit, invalid, values, submitting }) => (
          <ModalContent
            handleSubmit={handleSubmit}
            validateAction={{
              label: 'Envoyer',
              type: 'submit',
              disabled:
                invalid ||
                submitting ||
                Object.values(values).some(({ withNoRdv }) => {
                  const hasSlotsInTheFuture = computeHasSlotsInTheFuture(slotsRendezVous);

                  return !hasSlotsInTheFuture && !withNoRdv;
                }),
              loading: submitting,
            }}
            cancelAction={{ label: 'Annuler', onClick: onClose }}
          >
            <div className="flex justify-center flex-wrap gap-md">
              {selectedProCompanies.map(selectedProCompany => (
                <div className="flex flex-col flex-grow" key={selectedProCompany.uuid}>
                  <CompanyDetails
                    proCompany={selectedProCompany}
                    slotsRendezVous={slotsRendezVous}
                  />
                </div>
              ))}
            </div>
          </ModalContent>
        )}
      </Form>
    </Modal>
  );
};
