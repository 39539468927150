import React from 'react';

import classNames from 'classnames';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { PrixUnitaireLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/PrixUnitaireLabel';
import { QuantityLabel } from '@travauxlib/shared/src/features/DevisDisplay/components/QuantityLabel';
import { LigneComparison } from '@travauxlib/shared/src/features/DevisDisplay/types';
import { ComparisonStatus } from '@travauxlib/shared/src/types';

import { getBGColorFromComparisonStatus } from '../utils/getBGColorFromComparisonStatus';
import { getLineIcon } from '../utils/getLineIcon';

type Props = {
  comparison: LigneComparison;
  firstColumn?: boolean;
  lotComparisonStatus?: ComparisonStatus;
};

export const DevisComparatorLineColumn: React.FC<Props> = ({
  comparison,
  lotComparisonStatus,
  firstColumn,
}) => {
  const { base, compare } = comparison;
  const resolvedCompare = firstColumn ? base : compare ?? base;
  const comparisonStatus = firstColumn ? 'unchanged' : comparison.status;

  if (!resolvedCompare) {
    return null;
  }

  const icon = getLineIcon(comparisonStatus);

  return (
    <div
      data-testid={firstColumn ? 'base' : 'compare'}
      className={classNames(
        'w-full flex flex-col items-center text-ds-b2 bg-neutral-0 px-md pb-xxs',
        getBGColorFromComparisonStatus(lotComparisonStatus),
      )}
    >
      <div
        className={classNames(
          'w-full flex rounded-xxs pl-xxs p-xs',
          getBGColorFromComparisonStatus(comparisonStatus),
        )}
      >
        <div className="w-full">
          <div
            className={classNames('w-full flex items-center mb-xs', {
              'font-bold text-info-800': base && base.designation !== resolvedCompare.designation,
              'line-through': comparisonStatus === 'missing',
            })}
          >
            <div className="min-w-md w-md mr-xxs flex items-start">{icon}</div>
            {resolvedCompare.designation}
          </div>

          <div className="w-full flex items-center w-full">
            <div className="min-w-md w-md mr-xxs" />
            <div className="w-full flex justify-between">
              <div
                className={classNames({
                  'font-bold text-info-800': base && base.quantite !== resolvedCompare.quantite,
                  'line-through': comparisonStatus === 'missing',
                })}
              >
                <QuantityLabel unite={resolvedCompare.unite} quantite={resolvedCompare.quantite} />
              </div>
              <div
                className={classNames({
                  'font-bold text-info-800':
                    base && base.prixUnitaireHT !== resolvedCompare.prixUnitaireHT,
                  'line-through': comparisonStatus === 'missing',
                })}
              >
                <PrixUnitaireLabel
                  prixUnitaireHT={resolvedCompare.prixUnitaireHT}
                  unite={resolvedCompare.unite}
                />
              </div>
              <div
                className={classNames({
                  'font-bold text-info-800': base && base.prixHT !== resolvedCompare.prixHT,
                  'line-through': comparisonStatus === 'missing',
                })}
              >
                <EURCurrency amount={resolvedCompare.prixHT} /> HT
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
