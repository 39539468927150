import React, { useState } from 'react';

import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useCancelSuiviChantier } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useCancelSuiviChantier';

type OwnProps = {
  suiviChantierUuidToCancel: string;
  chantierSlug: string;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

export const CancelSuiviChantierModal: React.FC<Props> = ({
  suiviChantierUuidToCancel,
  chantierSlug,
  handleClose,
}) => {
  const [acceptedConfirmationCancelCycle, setAcceptedConfirmationCancelCycle] = useState(false);

  const cancelSuiviChantier = useCancelSuiviChantier();

  const dismissAndClearState = (): void => {
    handleClose();
    setAcceptedConfirmationCancelCycle(false);
  };

  return (
    <FeedbackMessages
      isOpen
      variant="error"
      title="Confirmer la suppression"
      message={
        <div>
          <div className="text-neutral-600 text-ds-b1 mb-md">
            Vous allez supprimer l'état d'avancement sélectionné. Cette opération est irréversible.
          </div>
          <Checkbox
            label="Je confirme vouloir supprimer le dernier état d'avancement validé par l'entreprise et comprends les implications de cette opération"
            onChange={() => setAcceptedConfirmationCancelCycle(prev => !prev)}
            checked={acceptedConfirmationCancelCycle}
          />
        </div>
      }
      validateAction={{
        label: 'Confirmer',
        disabled: !acceptedConfirmationCancelCycle,
        onClick: () => {
          cancelSuiviChantier({
            chantierSlug,
            suiviChantierUuid: suiviChantierUuidToCancel,
          });
          dismissAndClearState();
        },
      }}
      handleClose={dismissAndClearState}
      cancelAction={{
        label: 'Annuler',
        onClick: () => dismissAndClearState,
      }}
    />
  );
};

export const useCancelSuiviChantierModal = (): ((
  suiviChantierUuidToCancel: string,
  chantierSlug: string,
) => void) => {
  const openModal = useOpenModal();

  const open = async (suiviChantierUuidToCancel: string, chantierSlug: string): Promise<void> => {
    openModal(CancelSuiviChantierModal, {
      suiviChantierUuidToCancel,
      chantierSlug,
    });
  };

  return open;
};
