import React, { useState } from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import CrossSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbol.svg?react';
import EditUnderscore from '@travauxlib/shared/src/components/DesignSystem/assets/EditUnderscore.svg?react';
import Expand from '@travauxlib/shared/src/components/DesignSystem/assets/Expand.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Textarea } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea';

import { useProjectContext } from 'features/Project/api/useProjectContext';
import { useUpdateProject } from 'features/Project/api/useUpdateProject';

type Status = 'closed' | 'small' | 'big';

export const ConfigurateurNotes: React.FC = () => {
  const projectUuid = useParams().uuid!;
  const updateProject = useUpdateProject();
  const { projectContext } = useProjectContext(projectUuid);
  const [status, setStatus] = useState<Status>('closed');
  const [inputValue, setInputValue] = useState(projectContext?.project?.comments || '');

  if (!projectContext) {
    return null;
  }

  if (status === 'closed') {
    return (
      <button
        type="button"
        onClick={() => setStatus('small')}
        className="fixed bg-primary rounded-md py-md px-lg flex text-white font-bold bottom-xl right-xl"
      >
        <EditUnderscore className="mr-xs text-white" />
        Prendre des notes
      </button>
    );
  }

  return (
    <div className="fixed bottom-xl right-xl flex flex-col items-end z-50">
      <div
        className={classNames(
          'overflow-hidden rounded-xxs shadow-ds-lg mb-md flex flex-col max-h-[calc(100vh-100px)]',
          status === 'small' ? 'w-[400px] h-[264px]' : 'w-[775px] h-[560px]',
        )}
      >
        <div className="bg-primary text-white px-sm flex items-center justify-between py-xs">
          <span>Prise de note</span>
          <IconButton onClick={() => setStatus(status => (status === 'big' ? 'small' : 'big'))}>
            <Expand className="w-lg h-lg" />
          </IconButton>
        </div>
        <div className="bg-white p-md grow shrink flex flex-col overflow-hidden">
          <Textarea
            className="mb-md flex flex-col grow shrink overflow-hidden"
            renderingOptions={{
              inputContainerClassName: 'flex flex-col grow shrink overflow-hidden',
              inputClassName: 'grow shrink overflow-hidden',
            }}
            placeholder="Rédigez votre note"
            id="comment"
            value={inputValue}
            onChange={value => {
              setInputValue(value);
            }}
            onBlur={() => {
              updateProject({ ...projectContext.project, comments: inputValue });
            }}
          />
        </div>
      </div>
      <button
        type="button"
        data-testid="close-notes"
        onClick={() => setStatus('closed')}
        className="bg-primary rounded-md p-md flex"
      >
        <CrossSymbol className="text-white w-lg h-lg" />
      </button>
    </div>
  );
};
