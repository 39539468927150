import React from 'react';

import dayjs from 'dayjs';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Person } from '@travauxlib/shared/src/types';

import { useAuth } from 'api/useAuth';
import { personRoleToLabel } from 'utils/constants';

type Props = {
  person: Person;
};

export const InformationCard: React.FC<Props> = ({ person }) => {
  const {
    adminUserProfile: { isAdmin },
  } = useAuth();

  const {
    firstName,
    lastName,
    nickname,
    birthDate,
    email,
    mobilePhoneNumber,
    jobDescription,
    role,
  } = person;
  const roleLabel = role && personRoleToLabel[role];

  return (
    <Card className="h-full">
      <div className="flex justify-between items-center">
        <h4 className="mb-0">Profile Information</h4>
        {isAdmin && (
          <IconButtonLink to={`/trombinoscope/${person.uuid}/edit`} size="sm">
            <Edit />
          </IconButtonLink>
        )}
      </div>

      <div className="text-ds-b2">{jobDescription ?? 'Pas de job description'}</div>
      <div className="text-ds-b2">{roleLabel ?? 'Pas de rôle'}</div>
      <hr />
      <ul className="list-none pl-0">
        <li className="text-ds-b2 my-xs">
          <strong>Full Name</strong> &nbsp; {firstName} {lastName}
          {nickname && <span> alias {nickname}</span>}
        </li>
        <li className="text-ds-b2 my-xs">
          <strong>Mobile</strong> &nbsp; {mobilePhoneNumber ?? 'N/A'}
        </li>
        <li className="text-ds-b2 my-xs">
          <strong>Email</strong> &nbsp; {email ?? 'N/A'}
        </li>
        {birthDate && (
          <li className="text-ds-b2 my-xs flex">
            <strong>Anniversaire</strong>
            &nbsp; {dayjs(birthDate).format('LL')}
          </li>
        )}
      </ul>
    </Card>
  );
};
