import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class ProjetTrackingClass extends Tracker<AdminFeatures> {
  onProjetViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const ProjetTracking = new ProjetTrackingClass(AdminFeatures.Projet);
