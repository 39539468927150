import React from 'react';

import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';

type Props = {
  fields: {
    push: (payload: object) => void;
  };
};

export const AddPrestationButton: React.FC<Props> = ({ fields }) => (
  <button
    type="button"
    onClick={() => fields.push({ isOption: false })}
    className="relative -left-[2.5rem] w-[calc(100%_+_2.5rem)] bg-neutral-100 border border-primary border-dashed py-sm pl-xs rounded"
  >
    <span className="flex items-center justify-center font-bold text-ds-b1">
      <ColoredCircle className="mr-xs" size="xs" variant="beige">
        <PlusSymbol />
      </ColoredCircle>
      Ajouter une prestation
    </span>
  </button>
);
