import React from 'react';

import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import { Avatar } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar';
import { getInitials } from '@travauxlib/shared/src/components/DesignSystem/components/Avatar/getInitials';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { ProjectListView } from 'types';

import { ProjectCardTag } from './ProjectCardTag';

type Props = {
  project: ProjectListView;
};

export const ProjectCard: React.FC<Props> = ({
  project: {
    title,
    address,
    budgetTravaux,
    uuid,
    pipedriveDealUrl,
    clientName,
    statuses,
    dealOwnerFullName,
  },
}) => (
  <Card className="border-0" bodyClassNames="p-md pt-sm h-full" role="group">
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex gap-xxs">
          {statuses.map(status => (
            <ProjectCardTag key={status} projectStatus={status} />
          ))}
        </div>
        <div className="text-neutral-800 text-ds-b1 font-bold mb-xxs truncate">{title} </div>
        <div>
          {clientName && (
            <div className="flex gap-xxs items-center">
              <div className="h-md w-md flex items-center text-neutral-700">
                <Person />
              </div>
              <div className="text-neutral-700 text-ds-b2">{clientName}</div>
            </div>
          )}
          {address && (
            <div className="flex gap-xxs items-center">
              <div className="h-md w-md flex items-center text-neutral-700">
                <Location />
              </div>
              <div className="text-neutral-700 text-ds-b2">{address}</div>
            </div>
          )}
        </div>
        {budgetTravaux && (
          <div className="flex gap-xxs mt-xxs items-center">
            <div className="h-md w-md flex items-center">
              <Euro />
            </div>
            <EURCurrency
              amount={parseFloat(budgetTravaux)}
              className="text-neutral-800 text-ds-b2 font-medium"
            />
          </div>
        )}
        {dealOwnerFullName && (
          <div className="flex items-center gap-xxs mt-xxs">
            <Avatar size="xs" text={getInitials(dealOwnerFullName)} />
            <div className="text-neutral-800 text-ds-b2">{dealOwnerFullName}</div>
          </div>
        )}
      </div>
      <div className="flex justify-end gap-md mt-md">
        {pipedriveDealUrl && (
          <ButtonLink href={pipedriveDealUrl} target="_blank" variant="secondary" size="sm">
            Accéder au Pipe
          </ButtonLink>
        )}
        <ButtonLink to={`../projects/${uuid}/information`} size="sm">
          Voir le projet
        </ButtonLink>
      </div>
    </div>
  </Card>
);
