import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { CampaignInfos } from 'features/DashboardCDP/types';

type Props = {
  partnerSubCampaigns: CampaignInfos[];
};

export const SubCampaign: React.FC<Props> = ({ partnerSubCampaigns }) => {
  const formApi = useForm();

  useEffect(() => {
    if (partnerSubCampaigns.length === 1) {
      formApi.change('subcampaign', partnerSubCampaigns[0]);
    }
  }, [partnerSubCampaigns.join('')]);

  return (
    <div className="col-span-full sm-desktop:col-span-6 mb-md">
      <DropdownField
        id="subcampaign"
        label="Agence du partenaire"
        name="subcampaign"
        options={partnerSubCampaigns.map(campaign => ({
          label: campaign.attributionName,
          value: campaign,
        }))}
        validate={required}
      />
    </div>
  );
};
