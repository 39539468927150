import React from 'react';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { CampaignInfos } from 'features/DashboardCDP/types';
import { usePartners } from 'features/Partners/api/usePartners';
import { PartnerOrganization } from 'types';
import {
  boucheAOreille,
  partnerOffline,
  partnerOnline,
  recommandationInterne,
  salon,
  salonOptions,
  marketplaceSourceOptions,
  clubProSourceOptions,
  recommandationClient,
} from 'utils/constants/acquisition';

import { CampaignAndSubCampaign } from './CampaignAndSubCampaign';

const filterBySource = (partners: PartnerOrganization[], source: string): CampaignInfos[] =>
  partners
    .filter(partner => partner.agencies.some(agency => agency.source === source))
    .map(partner => ({
      attributionName: partner.attributionName,
      internalAccountManager: partner.internalAccountManager,
    }));

const filterBySourceAndCampaign = (
  partners: PartnerOrganization[],
  source: string,
  campaign?: string,
): CampaignInfos[] =>
  partners
    .filter(partner => partner.attributionName === campaign)
    .reduce((acc, partner) => {
      const subCampaigns = partner.agencies
        .filter(agency => agency.source === source)
        .map(agency => ({
          attributionName: agency.attributionName,
          internalAccountManager: agency.internalAccountManager,
        }));

      return [...acc, ...subCampaigns];
    }, []);

type Props = {
  source?: string;
  campaign?: string;
  isForClubProLead?: boolean;
};

export const Source: React.FC<Props> = ({ source, campaign, isForClubProLead }) => {
  const { partners } = usePartners();

  return (
    <>
      <div className="col-span-full">
        <DropdownField
          id="source"
          label="Source"
          name="source"
          options={isForClubProLead ? clubProSourceOptions : marketplaceSourceOptions}
          validate={required}
        />
      </div>

      {(source === partnerOffline.value || source === partnerOnline.value) && (
        <CampaignAndSubCampaign
          partnerCampaigns={filterBySource(partners, source)}
          partnerSubCampaigns={filterBySourceAndCampaign(partners, source, campaign)}
        />
      )}

      {source === boucheAOreille.value && (
        <div className="col-span-full sm-desktop:col-span-6">
          <InputField name="campaign" id="campaign" label="Qui nous a recommandé ? (optionnel)" />
        </div>
      )}

      {(source === recommandationInterne.value || source === recommandationClient.value) && (
        <div className="col-span-full sm-desktop:col-span-6">
          <InputField
            name="campaign"
            id="campaign"
            label="Copain de qui ?"
            validate={source === recommandationInterne.value ? required : undefined}
          />
        </div>
      )}

      {source === salon.value && (
        <div className="col-span-full sm-desktop:col-span-6">
          <DropdownField
            name="campaign"
            id="campaign"
            label="Nom du salon"
            options={salonOptions}
            validate={required}
          />
        </div>
      )}
    </>
  );
};
