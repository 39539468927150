import React from 'react';

import { Form } from 'react-final-form';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import {
  SimpleModal,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { RadioButtonsListField } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { validateEmail } from '@travauxlib/shared/src/utils/form';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import { noop } from '@travauxlib/shared/src/utils/noop';

import { useCreateCommission } from 'features/Partners/api/useCreateCommission';
import { useUpdateCommission } from 'features/Partners/api/useUpdateCommission';
import { PartnerCommission } from 'types';

type OwnProps = {
  commission: PartnerCommission;
  isCreate: boolean;
};

type InjectedProps = {
  handleClose: () => void;
};

type Props = OwnProps & InjectedProps;

export const EditCommissionModal: React.FC<Props> = ({ commission, handleClose, isCreate }) => {
  const { createCommission, isLoading: isCreating } = useCreateCommission();
  const { updateCommission, isLoading: isUpdating } = useUpdateCommission();

  const isLoading = isCreating || isUpdating;

  const handleSubmit = async (commission: PartnerCommission): Promise<void> => {
    if (isCreate) {
      await createCommission(commission);
    } else {
      await updateCommission(commission);
    }
    handleClose();
  };

  const title = isCreate ? "Création d'une commission" : "Modification d'une commission";

  return (
    <Form onSubmit={handleSubmit} initialValues={commission} initialValuesEqual={() => true}>
      {({ handleSubmit, values }) => (
        <SimpleModal
          isOpen
          isScrollable
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          title={title}
          validateAction={{
            label: 'Confirmer',
            type: 'submit',
            disabled: isLoading,
            loading: isLoading,
          }}
          cancelAction={{ label: 'Annuler', onClick: handleClose }}
        >
          <div className="flex flex-col gap-xs">
            <div>
              <RadioButtonsListField
                inline
                label="Type de commission"
                id="typeCommission"
                name="typeCommission"
                options={[
                  { label: 'Pourcentage commission', value: 'pourcentage' },
                  { label: 'Chèque cadeau', value: 'cheque' },
                ]}
              />
            </div>
            <div>
              <FormLabel label="Conditions commerciales" />
              <InputField id="conditions" name="conditions" />
            </div>
            <div>
              <FormLabel label="Montant commission hemea" />
              <NumberInputField
                disabled
                id="montantCommissionHemeaHT"
                name="montantCommissionHemeaHT"
                suffix="€"
              />
            </div>
            <div>
              <FormLabel label="Pourcentage de commission" />
              <NumberInputField id="pourcentage" name="pourcentage" suffix="%" />
            </div>
            {values.pourcentage !== undefined && (
              <div>
                <FormLabel label="Montant commission partenaire" />
                <NumberInput
                  onChange={noop}
                  disabled
                  id="montantCommissionPartenaire"
                  suffix="€"
                  value={roundToTwoDecimals(
                    (values.montantCommissionHemeaHT * values.pourcentage) / 100,
                  )}
                />
              </div>
            )}
            <div>
              <FormLabel label="Email de facturation" />
              <InputField id="billingEmail" name="billingEmail" validate={validateEmail} />
            </div>
          </div>
        </SimpleModal>
      )}
    </Form>
  );
};

export const useOpenEditCommissionModal = (): ((payload: {
  commission: PartnerCommission;
  isCreate: boolean;
}) => void) => {
  const openModal = useOpenModal();

  const open = async ({
    commission,
    isCreate,
  }: {
    commission: PartnerCommission;
    isCreate: boolean;
  }): Promise<void> => {
    openModal(EditCommissionModal, {
      commission,
      isCreate,
    });
  };

  return open;
};
