import React from 'react';

import { FieldRenderProps } from 'react-final-form';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import PlusSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/PlusSymbol.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { Contact } from 'types';

type Props = FieldRenderProps<Contact[]>;

export const Contacts: React.FC<Props> = ({ input: { value = [], onChange } }) => (
  <div className="!mb-sm">
    <h2>Contacts</h2>
    {value.length === 0 && <div>Aucun contact</div>}
    {value.map((field, index) => (
      <div key={index} className="flex flex-wrap -mx-md mb-md">
        <div className="sm-desktop:w-3/12 sm-desktop:px-md relative">
          <InputField
            id="name"
            label="Nom du contact *"
            name={`contacts.${index}.name`}
            validate={required}
          />
        </div>
        <div className="sm-desktop:w-3/12 sm-desktop:px-md relative">
          <InputField id="email" label="Email" name={`contacts.${index}.email`} />
        </div>
        <div className="sm-desktop:w-2/12 sm-desktop:px-md relative">
          <InputField id="phoneNumber" label="Tél" name={`contacts.${index}.phoneNumber`} />
        </div>
        <div className="sm-desktop:w-3/12 sm-desktop:px-md relative">
          <InputField
            id="comment"
            label="Rôle / poste / commentaire"
            name={`contacts.${index}.comment`}
          />
        </div>
        <div className="sm-desktop:w-1/12 sm-desktop:px-md relative">
          <IconButton
            variant="black"
            onClick={() => onChange(value.filter((_, contactIndex) => contactIndex !== index))}
          >
            <Delete />
          </IconButton>
        </div>
      </div>
    ))}
    <div>
      <Button leftIcon={<PlusSymbol />} size="sm" onClick={() => onChange([...value, {}])}>
        Ajouter un contact
      </Button>
    </div>
  </div>
);
