import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class LeadsTrackingClass extends Tracker<AdminFeatures> {
  onLeadsViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const LeadsTracking = new LeadsTrackingClass(AdminFeatures.Leads);
