import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  label: string;
  montant: number;
};

export const CompteMontant: React.FC<Props> = ({ label, montant }) => (
  <div className="rounded-md px-md pb-xxs pt-xs border-1 border-neutral-300 w-[14.25rem]">
    <div className="text-ds-sm text-neutral-600">{label}</div>
    <EURCurrency
      forceFullPattern
      className="text-neutral-800 text-ds-h4 font-bold"
      amount={montant}
    />
  </div>
);
