import React from 'react';

import Draw from '@travauxlib/shared/src/components/DesignSystem/assets/DrawDuoTone.svg?react';
import HandShake from '@travauxlib/shared/src/components/DesignSystem/assets/HandShake.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { TypeSuivi } from '@travauxlib/shared/src/types';
import { ArchiType } from '@travauxlib/shared/src/types/api/domain/procompany/ArchiType';
import { CompanyType } from '@travauxlib/shared/src/types/api/domain/procompany/CompanyType';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { ChantierEdit } from './ChantierEdit';
import { ChantierInfoLine } from './ChantierInfoLine';
import { ChantierStatus } from './ChantierStatus';
import { CloseChantier } from './CloseChantier';

type Props = {
  chantier: Chantier;
};

export const ChantierInformations: React.FC<Props> = ({ chantier }) => {
  const {
    proCompany,
    address,
    dateFinEstimee,
    dateFinReelle,
    createdAt,
    dureeEstimeeDays,
    responsableSuiviName,
    typeSuivi,
    status,
    coachCareName,
    dateDebutReelle,
    dateDebutEstimee,
  } = chantier;

  const { companyType, archiType } = proCompany;

  const isWithCustomerCare = [TypeSuivi.CustomerCare, TypeSuivi.ServiceHemea].includes(typeSuivi);

  const typeSuiviText = isWithCustomerCare ? 'Customer Care' : 'Travaux Planner';

  const isArchi = archiType && [ArchiType.externe, ArchiType.interne].includes(archiType);
  return (
    <Card state="outlined" className="flex-1">
      <div className="flex flex-col gap-md sm-desktop:gap-0 sm-desktop:flex-row items-center pb-md">
        <div className="text-ds-h5 font-bold pr-md">Détails du projet</div>
        <ChantierStatus status={status} />
        <span className="text-ds-b2 b rounded-sm py-3xs px-xs text-gray-700 border-1 border-neutral-300 flex ml-xs items-center">
          {isArchi ? (
            <>
              <Draw className="w-xmd h-xmd pr-xxs" /> Architecte
            </>
          ) : (
            <>
              <HandShake className="w-xmd h-xmd pr-xxs" /> Type de suivi : {typeSuiviText}
            </>
          )}
        </span>
      </div>
      <div className="flex flex-col sm-desktop:flex-row">
        <div className="space-y-xxs sm-desktop:w-[17.5rem]">
          <ChantierInfoLine icon="address" title="Adresse" value={address} />
          <ChantierInfoLine
            icon="travaux"
            title="Type de prestation"
            value={companyType === CompanyType.Archi ? 'Conception' : 'Travaux'}
          />
          <ChantierInfoLine
            icon="dateSignature"
            title="Date de signature du devis"
            value={formatFrenchDate(createdAt)}
          />
        </div>
        <div className="sm-desktop:pl-md space-y-xxs sm-desktop:border-l-1 border-neutral-300 sm-desktop:w-[17.5rem]">
          <ChantierInfoLine
            icon="dateDebut"
            title={status === 'non_demarre' ? 'Date de début estimée' : 'Date de début'}
            value={
              status === 'non_demarre'
                ? formatFrenchDate(dateDebutEstimee)
                : formatFrenchDate(dateDebutReelle)
            }
          />
          <ChantierInfoLine
            icon="tempsEstime"
            title="Temps estimé"
            value={`${dureeEstimeeDays} jours`}
          />
          <ChantierInfoLine
            icon="dateFin"
            title={status === 'non_demarre' ? 'Date de fin estimée' : 'Date de fin réelle'}
            value={formatFrenchDate(dateFinReelle) ?? formatFrenchDate(dateFinEstimee)}
          />
        </div>
        <div className="sm-desktop:border-l-1 border-neutral-300 sm-desktop:pl-md mt-md md-desktop:mt-0">
          <ChantierInfoLine
            icon="responsableSuivi"
            title="Responsable suivi"
            value={responsableSuiviName ?? 'Pas de responsable assigné'}
          />
          {coachCareName && (
            <ChantierInfoLine icon="coachCare" title="Coach Care" value={coachCareName} />
          )}
        </div>
      </div>
      <div className="flex justify-start sm-desktop:justify-end mt-md">
        <div className="flex flex-col sm-desktop:flex-row items-baseline sm-desktop:items-center gap-md">
          {status === 'demarre' && <CloseChantier chantier={chantier} />}
          <ChantierEdit chantier={chantier} />
        </div>
      </div>
    </Card>
  );
};
