import React from 'react';

import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import { useDownloadAsZip } from 'components/ProjectFiles/api/useDownloadAsZip';

type Props = {
  className?: string;
  zipName: string;
  urlsToDownload: string[];
};

export const DownloadAllButton: React.FC<Props> = ({ className, zipName, urlsToDownload }) => {
  const { downloadAsZip, isLoading } = useDownloadAsZip();
  const parsedZipName = `${slugify(zipName)}.zip`;

  return (
    <Button
      className={className}
      variant="tertiary"
      rightIcon={<Download />}
      size="sm"
      onClick={() =>
        downloadAsZip({
          zipName: parsedZipName,
          urls: urlsToDownload,
        })
      }
      disabled={isLoading || urlsToDownload.length < 1}
      loading={isLoading}
    >
      Tout télécharger
    </Button>
  );
};
