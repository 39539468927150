import React from 'react';

import { Field } from 'react-final-form';
import { v4 as uuidV4 } from 'uuid';

import { Counter } from '@travauxlib/shared/src/components/DesignSystem/components/Counter';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Location } from '@travauxlib/shared/src/types';
import { TypeLocation } from '@travauxlib/shared/src/types';
import { removeItemAtIndex } from '@travauxlib/shared/src/utils/listMethods';

import { rooms } from '../utils/rooms';

// if we define it dynamically we will have infinite rerenders
const emptyArray: Location[] = [];
export const LocalisationModalContent: React.FC = () => {
  const handleOnChangeCounter = (
    typeLocation: TypeLocation,
    newCount: number,
    count: number,
    locations: Location[],
  ): Location[] => {
    if (newCount > count) {
      if (newCount >= 2) {
        return [
          ...locations.map(location =>
            location.label === typeLocation
              ? { ...location, label: `${typeLocation} N°1` }
              : location,
          ),
          {
            label: `${typeLocation} N°${newCount}`,
            typeLocation,
            surface: 0,
            uuid: uuidV4(),
          },
        ];
      }
      return [
        ...locations,
        {
          label: newCount === 1 ? typeLocation : `${typeLocation} N°${newCount}`,
          typeLocation,
          surface: 0,
          uuid: uuidV4(),
        },
      ];
    } else {
      let maxIndex = -1;
      locations.forEach((location, index) => {
        if (location.typeLocation === typeLocation) {
          maxIndex = index;
        }
      });

      if (newCount === 1) {
        const data = removeItemAtIndex(locations, maxIndex);

        return [
          ...data.map(location => {
            if (location.label === `${typeLocation} N°${newCount}`) {
              return { ...location, label: typeLocation };
            }
            return location;
          }),
        ];
      } else {
        return removeItemAtIndex(locations, maxIndex);
      }
    }
  };

  return (
    <>
      <FormLabel
        className="mb-md"
        id="typeLogement"
        label="Quelles pièces souhaitez-vous rénover ?"
      />
      <Field<Location[]> name="locations" defaultValue={emptyArray}>
        {({ input: { value, onChange } }) => (
          <>
            <div className="text-ds-b2 flex gap-y-md flex-wrap mb-md">
              {rooms.map(({ typeLocation, sublabel }) => {
                const Picto = locationsPicto[typeLocation];
                const count =
                  value.length && value?.filter(l => l.typeLocation === typeLocation).length;

                return (
                  <div
                    key={typeLocation}
                    className="flex w-full tablet:w-1/2 items-center tablet:odd:pr-xl tablet:even:pl-xl"
                    role="group"
                    aria-labelledby={typeLocation}
                  >
                    <div className="flex justify-start w-1/2">
                      <Picto className="mr-xs text-primary" height="24px" width="24px" />
                      <div>
                        <div id={typeLocation}>{typeLocation}</div>
                        {sublabel && <div className="text-neutral-700">{sublabel}</div>}
                      </div>
                    </div>
                    <Counter
                      canEditInput={false}
                      onChange={(newCount = count) =>
                        onChange(handleOnChangeCounter(typeLocation, newCount, count, value))
                      }
                      min={0}
                      max={100}
                      value={count}
                      name={typeLocation}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Field>
    </>
  );
};
