import React, { useReducer } from 'react';

import ChevronLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronLeft.svg?react';
import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Dropdown } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown';
import { DropdownMulti } from '@travauxlib/shared/src/components/DesignSystem/components/DropdownMulti';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { SearchBar } from '@travauxlib/shared/src/components/DesignSystem/components/SearchBar';
import { useDebouncedValue } from '@travauxlib/shared/src/hooks/useDebouncedValue';

import { useAuth } from 'api/useAuth';
import { useLatestProjects } from 'features/Project/api/useLatestProjects';
import { statusFilterOptions } from 'features/Project/utils/constants';
import { useSalesTeamMembers } from 'features/Trombinoscope/api/useTeams';
import { ProjectListView, ProjectStatus } from 'types';

import { ProjectCard } from './components/ProjectCard';

const Projects: React.FC<{ projects: ProjectListView[] }> = ({ projects }) => {
  if (!projects.length) {
    return (
      <EmptyState
        illustration={<ProjectPicto />}
        description="Vous n'avez pas de projets clients associés"
      />
    );
  }
  return (
    <div className="grid sm-desktop:grid-cols-3 gap-md mb-md">
      {projects.map(project => (
        <ProjectCard key={project.uuid} project={project} />
      ))}
    </div>
  );
};

type SearchQuery = {
  dealOwnerFilter: string | null;
  offset: number;
  clientSearch: string;
  statuses: ProjectStatus[];
};

type Action =
  | {
      type: 'changeStatuses';
      value: ProjectStatus[];
    }
  | {
      type: 'changeClientSearch';
      value: string;
    }
  | {
      type: 'changeDealOwnerFilter';
      value: string | null;
    }
  | {
      type: 'nextPage';
    }
  | {
      type: 'previousPage';
    };

const formReducer = (state: SearchQuery, action: Action): SearchQuery => {
  switch (action.type) {
    case 'changeStatuses':
      return { ...state, statuses: action.value, offset: 0 };
    case 'changeClientSearch':
      return { ...state, clientSearch: action.value, offset: 0 };
    case 'changeDealOwnerFilter':
      return { ...state, dealOwnerFilter: action.value };
    case 'previousPage':
      return { ...state, offset: state.offset - 50 };
    case 'nextPage':
      return { ...state, offset: state.offset + 50 };
  }
  return state;
};

export const ProjectListing: React.FC = () => {
  const [{ clientSearch, dealOwnerFilter, offset, statuses }, dispatch] = useReducer(formReducer, {
    offset: 0,
    clientSearch: '',
    statuses: [],
    dealOwnerFilter: null,
  });
  const { teamMembers } = useSalesTeamMembers();
  const debouncedClientSearch = useDebouncedValue(clientSearch, 500);
  const { isLoading, projects } = useLatestProjects(
    debouncedClientSearch,
    dealOwnerFilter || undefined,
    offset,
    statuses,
  );

  const {
    adminUserProfile: { hasPermission },
    ability,
  } = useAuth();

  const canSeeAllLeads = hasPermission('READ_ALL_LEADS') || ability.can('manage', 'Lead');

  const Pagination: React.FC = () => (
    <>
      <IconButton onClick={() => dispatch({ type: 'previousPage' })} disabled={offset <= 0}>
        <ChevronLeft />
      </IconButton>
      <div className="text-neutral-700 text-ds-b1 hidden md-desktop:block">
        Projet
        {projects.length > 1 && 's'} {offset + 1} à {projects.length + offset}{' '}
      </div>
      <IconButton disabled={projects.length < 50} onClick={() => dispatch({ type: 'nextPage' })}>
        <ChevronRight />
      </IconButton>
    </>
  );

  return (
    <div className="-mx-md sm-desktop:-mx-xl -mt-md">
      <div className="bg-neutral-0 pt-sm pb-lg shadow-ds-sm">
        <div className="px-xl">
          <div className="text-ds-h3 font-bold pb-lg mb-0 text-neutral-800">Gestion de projet</div>
          <div className="flex justify-between items-center flex-wrap">
            <SearchBar
              value={clientSearch}
              onChange={value => dispatch({ type: 'changeClientSearch', value })}
              className="w-[20.75rem] h-xxl"
            />
            <div className="flex items-center gap-md flex-wrap">
              <div className="flex items-center gap-md flex-wrap w-full md-desktop:w-auto pt-md md-desktop:pt-0">
                <div className="flex items-center gap-xs">
                  {!isLoading && (offset !== 0 || projects.length !== 0) && <Pagination />}
                </div>
                <div className="w-full md-desktop:w-[20rem]">
                  <DropdownMulti<ProjectStatus>
                    label="Statut"
                    id="statuses"
                    options={statusFilterOptions}
                    onChange={statuses => dispatch({ type: 'changeStatuses', value: statuses })}
                    value={statuses}
                    combobox
                  />
                </div>
              </div>
              {canSeeAllLeads && (
                <Dropdown
                  value={dealOwnerFilter}
                  id="dealOwnerFilter"
                  options={[
                    { label: 'Tous', value: null },
                    ...teamMembers.map(teamMember => ({
                      label: `${teamMember.firstName} ${teamMember.lastName}`,
                      value: teamMember.email,
                    })),
                  ]}
                  onChange={(dealOwnerFilter: string | null) =>
                    dispatch({ type: 'changeDealOwnerFilter', value: dealOwnerFilter })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-lg px-xl">
        {isLoading ? <LoaderWrapper /> : <Projects projects={projects} />}
      </div>
      <div className="flex items-center justify-center gap-xs mb-md">
        {!isLoading && !!projects.length && <Pagination />}
      </div>
    </div>
  );
};
