import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { LeadAdminView } from '@travauxlib/shared/src/types/api/admin/LeadAdminView';

import { idealConfiguration } from '../../api/configurateur';
import { BaseStep, ExecuteParams, StepField } from '../BaseStep';

type Input = { lead?: LeadAdminView };

export class UpdateProjectTypologie extends BaseStep<
  Input,
  { configuration: Configuration },
  StepField[]
> {
  public name: string = 'Update Project Typologie';

  public fields = [];

  async execute({
    lead,
    apiURL,
    request,
  }: ExecuteParams<Input>): Promise<{ configuration: Configuration }> {
    if (!lead?.project?.uuid) {
      super.error('Lead project is undefined. Cannot update project !');
      return Promise.reject();
    }

    super.log(`Udpating project with uuid: ${lead.project.uuid}`);

    await request(`${apiURL}/admin/projects/${lead.project.uuid}`, {
      method: 'PUT',
      body: {
        ...idealConfiguration,
        title: 'Mon super projet',
        slotsRendezVous: [],
        minutesBetweenRdv: 90,
      },
    });

    super.log(`Project updated !`);

    const requestConfigurateurURL = `${apiURL}/admin/configuration/${lead.project.uuid}`;
    const configuration = await request<Configuration>(requestConfigurateurURL);

    super.setCreatedEntities({ configuration });

    return { configuration };
  }
}
