import React from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useHandleRedirect } from '@travauxlib/shared/src/hooks/useHandleRedirect';

import { Sidebar } from 'components/Sidebar';
import { Chantiers } from 'features/Chantiers';
import { ClientAccounts } from 'features/ClientAccounts';
import { DashboardCDP } from 'features/DashboardCDP';
import { DevisComparator } from 'features/DevisComparator';
import { Entreprises } from 'features/Entreprises';
import { Leads } from 'features/Leads';
import { LogicielDeDevis } from 'features/LogicielDeDevis/Loadable';
import { Modalites } from 'features/Modalites';
import { Partners } from 'features/Partners';
import { Project } from 'features/Project';
import { ProRegistrations } from 'features/ProRegistrations';
import { SuiviChantierHistory } from 'features/SuiviChantierV2/features/ReadOnly/Loadable';
import { Trombinoscope } from 'features/Trombinoscope';
import { adminUrls } from 'utils/urls';

import { GodMode } from '../features/GodMode';

export const AuthenticatedRoutes: React.FC = () => {
  useHandleRedirect();

  return (
    <Routes>
      <Route
        path="*"
        element={
          <div className="flex">
            <Sidebar />
            <div className="w-full mx-auto px-md sm-desktop:px-xl pt-md bg-neutral-100 min-h-dvh shrink overflow-auto">
              <Outlet />
            </div>
          </div>
        }
      >
        <Route path="comparateur/:token1?/:token2?" element={<DevisComparator.Index />} />
        <Route path="inscriptions-pro" element={<ProRegistrations.Index />}>
          <Route path="" element={<ProRegistrations.LeadListing />} />
          <Route path="new-lead" element={<ProRegistrations.CreateLead />} />
        </Route>
        <Route path="dashboard-cdp" element={<DashboardCDP.Index />}>
          <Route path="" element={<DashboardCDP.LeadListing />} />
          <Route path="new-lead" element={<DashboardCDP.CreateLead />} />
          <Route path="assignment-config/edit" element={<DashboardCDP.EditAssignmentConfig />} />
        </Route>
        <Route path="chantiers" element={<Chantiers.Index />}>
          <Route path="" element={<Chantiers.ChantierListing />} />
          <Route path=":slug" element={<Chantiers.ChantierDetails />} />
        </Route>
        <Route path="god">
          <Route path="" element={<GodMode.Index />} />
          <Route path="flow/:flowName" element={<GodMode.Flow />} />
        </Route>
        <Route path="entreprises" element={<Entreprises.Index />}>
          <Route path="*" element={<Entreprises.Search />}>
            <Route path="details" element={<Entreprises.SearchCompany />} />
            <Route path="club-pro" element={<Entreprises.ClubProSearch />} />
            <Route path="status" element={<Entreprises.CheckStatuses />} />
            <Route path="" element={<Navigate replace to="details" />} />
          </Route>
          <Route path=":slug" element={<Entreprises.EditEntreprise />}>
            <Route path="infos" element={<Entreprises.GlobalInfos />} />
            <Route path="settings" element={<Entreprises.Settings />} />
            <Route path="insurances" element={<Entreprises.InsurancesComponent />} />
            <Route path="consultations" element={<Entreprises.Consultation />} />
            <Route path="club-pro" element={<Entreprises.ClubPro />} />
            <Route path="site" element={<Entreprises.SiteVitrine />}>
              <Route path="projects" element={<Entreprises.ProjectListing />} />
              <Route path="reviews" element={<Entreprises.ReviewListing />} />
              <Route path="*" element={<Navigate replace to="projects" />} />
            </Route>
            <Route path="payment" element={<Entreprises.Payment />} />
            <Route path="users" element={<Entreprises.ProCompanyUsersControlPannelContainer />} />
            <Route path="devis" element={<Entreprises.Devis />} />
            <Route path="*" element={<Navigate replace to="infos" />} />
          </Route>
        </Route>
        <Route path="leads" element={<Leads />} />
        <Route path="project/*" element={<Project.RedirectToNewProject />} />
        <Route path="projects" element={<Project.Index />}>
          <Route path="" element={<Project.ProjectListing />} />
          <Route path=":uuid/documents/:tag?" element={<Project.ProjectDocumentsViewer />} />
          <Route path=":uuid/configurateur" element={<Project.Configurateur />}>
            <Route path="" element={<Project.ConfigurateurHub />} />
            <Route
              path="ouvertures-demolitions"
              element={<Project.ConfigurateurOuverturesDemolition />}
            />
            <Route path="platrerie-isolation" element={<Project.ConfigurateurPlatrerie />} />
            <Route path="electricite" element={<Project.ConfigurateurElec />} />
            <Route path="plomberie-cvc" element={<Project.ConfigurateurPlomberie />} />
            <Route path="revetements-sol" element={<Project.ConfigurateurSols />} />
            <Route path="revetements-murs" element={<Project.ConfigurateurMurs />} />
            <Route
              path="menuiseries-exterieures"
              element={<Project.ConfigurateurMenuiseriesExterieures />}
            />
            <Route
              path="menuiseries-interieures"
              element={<Project.ConfigurateurMenuiseriesInterieures />}
            />
            <Route path="cuisine" element={<Project.ConfigurateurCuisine />} />
            <Route path="salle-de-bain" element={<Project.ConfigurateurSalleDeBain />} />
            <Route path="devis" element={<Project.ConfigurateurDevis />} />
          </Route>
          <Route path=":uuid" element={<Project.ProjectPage />}>
            <Route path="information" element={<Project.ProjectInfos />} />
            <Route path="cadrage" element={<Project.ProjectCadrage />} />
            <Route path="chantier">
              <Route index element={<Project.ProjectChantierListing />} />
              <Route index path=":slug" element={<Project.ProjectChantier />} />
            </Route>

            <Route path="consultation">
              <Route path="" element={<Project.ProjectConsultation />} />
              <Route path=":consultationInfoId">
                <Route path="" element={<Project.ConsultationInfo />} />
                <Route
                  path="entreprises-pro"
                  element={<Project.ConsultationOffer manualCompanyTypes={['pro', 'mandataire']} />}
                />
                <Route
                  path="entreprises-architecte"
                  element={<Project.ConsultationOffer manualCompanyTypes={['architecte']} />}
                />
              </Route>
            </Route>
            <Route path="actions" element={<Project.ProjectAction />} />
            <Route path="" element={<Navigate replace to="information" />} />
          </Route>
        </Route>
        <Route
          path="projects/:uuid/chantier/:slug/suivi-chantier/:suiviChantierUuid"
          element={<Chantiers.SuiviChantier />}
        />
        <Route
          path="projects/:uuid/chantier/:slug/suivi-chantier/:suiviChantierUuid/history"
          element={<SuiviChantierHistory />}
        />
        <Route path="projects/:uuid/chantier/:slug/debug" element={<Chantiers.ChantierDebug />} />
        <Route path="comptes-clients" element={<ClientAccounts />} />
        <Route path="trombinoscope" element={<Trombinoscope.Index />}>
          <Route path="" element={<Trombinoscope.PersonListing />} />
          <Route path=":personUuid" element={<Trombinoscope.Person />} />
          <Route path="edit" element={<Trombinoscope.EditPersonListing />} />
          <Route path=":personUuid/edit" element={<Trombinoscope.EditPerson />} />
          <Route path="new" element={<Trombinoscope.CreatePerson />} />
          <Route path="teams/:teamUuid" element={<Trombinoscope.Team />} />
        </Route>
        <Route path="partners">
          <Route index element={<Partners.PartnerListing />} />
          <Route path="accounts" element={<Partners.AccountsTable />} />
          <Route path="agencies" element={<Partners.AgencyTable />} />
          <Route path="commissions-actives" element={<Partners.Commissions />} />
          <Route path="commissions-reglees" element={<Partners.Commissions displayPaid />} />
          <Route path=":uuid" element={<Partners.PartnerDetailsModal />}>
            <Route index element={<Partners.EditOrganization />} />
            <Route path="agencies" element={<Partners.AgencyListing />} />
            <Route path="agencies/new" element={<Partners.CreateAgency />} />
            <Route path="agencies/:agencyUuid" element={<Partners.EditAgency />} />
            <Route
              path="agencies/:agencyUuid/accounts"
              element={<Partners.AgencyAccountListing />}
            />
            <Route path="agencies/:agencyUuid/accounts/new" element={<Partners.CreateAccount />} />
            <Route path="accounts" element={<Partners.AccountListing />} />
            <Route path="accounts/new" element={<Partners.CreateAccount />} />
          </Route>
        </Route>
        <Route path="modalites/devis/:devisToken" element={<Modalites.ModalitesDevis />}>
          <Route path="commission" element={<Modalites.Commission />} />
          <Route path="hemea" element={<Modalites.ModalitesHemea />} />
          <Route path="modalites-paiment" element={<Modalites.ModalitesPaiement />} />
          <Route path="*" element={<Navigate replace to="commission" />} />
        </Route>
        <Route path="*" element={<Navigate replace to={adminUrls.leadsUrl} />} />
      </Route>
      <Route path="projects/:uuid/devis/:token" element={<LogicielDeDevis />} />
    </Routes>
  );
};
