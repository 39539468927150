import { Dayjs } from 'dayjs';

import { Variant } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ClientAccount, DevisStatuses, Signature, TypeSuivi } from '@travauxlib/shared/src/types';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

export type ChantierStatus = 'non_demarre' | 'demarre' | 'termine' | 'annule';

export const ChantierStatusOption: {
  [K in ChantierStatus]: {
    label: string;
    value: ChantierStatus;
    style: Variant;
  };
} = {
  non_demarre: {
    label: '🚧 Non démarré',
    value: 'non_demarre',
    style: 'primary',
  },
  demarre: {
    label: '🏗️ Démarré',
    value: 'demarre',
    style: 'success',
  },
  termine: {
    label: '✔️ Terminé',
    value: 'termine',
    style: 'light',
  },
  annule: {
    label: '❌ Annulé',
    value: 'annule',
    style: 'error',
  },
};

export const ChantierStatusesOptions = [
  ChantierStatusOption.non_demarre,
  ChantierStatusOption.demarre,
  ChantierStatusOption.termine,
  ChantierStatusOption.annule,
];

export type TransactionType =
  | 'liberation_de_fonds'
  | 'encaissement_tp'
  | 'encaissement_service_hemea'
  | 'encaissement_archi_interne';

export type ChantierTransaction = {
  id: number;
  amount: number;
  date: string;
  creditedAccountName: string;
  url: string;
  internalAuthor?: string;
  transactionType?: TransactionType;
  isIgnored?: boolean; // Is technical is a better wording
  isUserVisible?: boolean; // Admin only
};

export type AppelDeProvision = {
  uuid: string;
  montant: number;
  type: string;
  dateEffet: string;
  createdBy: string;
  deletedBy?: string;
  deletedAt?: string;
  factureAcompteToken?: string;
  devisToken?: string;
  status?: string;
};

export type AppelDePaiementStatus = 'pending' | 'initiated' | 'validated' | 'processed' | 'deleted';

export type AppelDePaiement = {
  uuid: string;
  montant: number;
  createdAt: string;
  createdBy: string;
  status: AppelDePaiementStatus;
  updatedBy?: string;
  updatedAt?: string;
  validatedAt?: string;
  validatedBy?: string;
  processedAt?: string;
  processedBy?: string;
  deletedAt?: string;
  deletedBy?: string;
  montantCommissionTTC?: number;
};

export type LiberationDeFonds = {
  date: string;
  author: string;
  total: number;
  commission: number;
  paidToCompany: number;
};

export type ChantierListItem = Pick<
  Chantier,
  | 'clientAccounts'
  | 'createdAt'
  | 'dateDebutReelle'
  | 'enableEcheancierAuto'
  | 'initialClientAccount'
  | 'lastEventDate'
  | 'lemonwayAccountUrl'
  | 'montantArchiInterneEncaisseTTC'
  | 'montantCommissionPreleveeTTC'
  | 'montantCommissionTTC'
  | 'montantProvisionsAttendues'
  | 'montantTotalTTC'
  | 'montantTPEncaisseTTC'
  | 'montantTravauxPlannerTTC'
  | 'pipedriveDealId'
  | 'pipedriveDealUrl'
  | 'proCompany'
  | 'project'
  | 'slug'
  | 'status'
  | 'responsableSuiviName'
  | 'responsableSuiviUuid'
  | 'totalPaiements'
  | 'totalProvisions'
  | 'typeSuivi'
  | 'uuid'
> & {
  countSignatures: number;
  countLiberationDeFonds: number;
  countAppelsDePaiementInitiated: number;
  countAppelsDePaiementValidated: number;
};

export type ChantierDevis = {
  devisLink: string;
  token: string;
  title: string;
  numero?: string;
  version: number;
  status: DevisStatuses;
  dateSignature?: string;
  sentAt?: string;
  deletedAt?: string;
  prixTotalTTC: number;
};

export type ProFacture = {
  token: string;
  title: string;
  prixTotalTTC: number;
  paidAt?: string;
  date: string;
};

export type Chantier = {
  uuid: string;
  createdAt: Dayjs;
  slug: string;
  dealUuid: string;
  address: string;
  customerName: string;
  customerEmail: string;
  clientAccounts: ClientAccount[];
  initialClientAccount?: ClientAccount;
  proCompany: ProCompanyAdminView;
  proUserName: string;
  proUserPhoneNumber: string;
  pipedriveDealId?: number;
  pipedriveDealUrl?: string;
  signatures: Signature[];
  devis: ChantierDevis[];
  proFactures: ProFacture[];
  montantTotalTTC: number;
  montantCommissionTTC: number;
  montantCommissionPreleveeTTC: number;
  montantCommissionRestantTTC: number;
  tauxCommissionHemea: number;
  montantRestantAPayerAuPro: number;
  montantTotalDuAuPro: number;
  montantVerseAuPro: number;
  montantSignatureInitiale?: number;
  resteProvisionsAAppeler: number;
  restePaiementsAAppeler: number;
  tauxTravauxPlanner: number;
  montantTravauxPlannerTTC: number;
  montantTravauxPlannerRestantTTC: number;
  montantTPEncaisseTTC: number;
  tauxServiceHemea: number;
  montantServiceHemeaTTC: number;
  montantServiceHemeaEncaisseTTC: number;
  montantServiceHemeaRestantTTC: number;
  montantArchiInterneEncaisseTTC: number;
  dateDebutEstimeeInterne?: Dayjs;
  dureeEstimeeDaysInterne?: number;
  dateDebutEstimee: Dayjs;
  dateFinEstimee: Dayjs;
  dureeEstimeeDays: number;
  dateDebutReelle?: Dayjs;
  dateFinReelle?: Dayjs;
  dureeReelleDays: number;
  dateAnnulation?: Dayjs;
  lemonwayAccountUrl?: string;
  transactions: ChantierTransaction[];
  iban: string;
  ibanPdfUrl?: string;
  appelsDeProvision: AppelDeProvision[];
  appelsDePaiement: AppelDePaiement[];
  totalProvisions: number;
  totalPaiements: number;
  soldeCompteSequestre: number;
  montantProvisionsAttendues: number;
  lastEventDate: string;
  status: ChantierStatus;
  typeSuivi: TypeSuivi;
  responsableSuiviUuid?: string;
  responsableSuiviName?: string;
  coachCareUuid?: string;
  coachCareName?: string;
  liberationsDeFonds: LiberationDeFonds[];
  hemeaLemonwayAccountUrl: string;
  blocNote?: string;
  enableEcheancierAuto: boolean;
  enableRelanceProvision: boolean;
  enableSuiviRenforce: boolean;
  debug: {
    relancesDeProvision: { emailSentAt: string; montantDemande: number }[];
    totalAppelsDeProvision: number;
    allAppelsDeProvision: AppelDeProvision[];
    cumulAppelsDeProvision: AppelDeProvision[];
  };
  customerPhoneNumber?: string;
  project?: { uuid: string; title: string };
  enableSuiviChantier: boolean;
  withMockedLemonway: boolean;
  montantTotalHT: number;
};
