import React, { useState } from 'react';

import classNames from 'classnames';

import AlertCircle from '@travauxlib/shared/src/components/DesignSystem/assets/AlertCircle.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';

import { ConsultationInfo, ProCompany } from 'types';

import { CompanyDetails } from './CompanyDetails';
import { DisabledReasonsTooltip } from './DisabledReasonsTooltip';
import { ReportResultModal } from './ReportResultModal';

type Props = {
  proCompany: ProCompany;
  consultationInfo: ConsultationInfo;
  rank: number;
  proCompaniesForConsultation: number[];
  setProCompaniesForConsultation: (proCompanies: number[]) => void;
  isLastRow: boolean;
  disabledReasons: string[];
};

export const ProCompanyRow: React.FC<Props> = ({
  proCompany,
  consultationInfo,
  rank,
  proCompaniesForConsultation,
  setProCompaniesForConsultation,
  isLastRow,
  disabledReasons,
}) => {
  const [showReportResultModal, setShowReportResultModal] = useState(false);

  return (
    <div
      className={classNames(
        'py-sm relative flex flex-wrap -mx-md border-solid border border-gray-300 shadow-ds-xs',
        {
          '!bg-white': !proCompaniesForConsultation.includes(proCompany.id),
          '!bg-primarylight': proCompaniesForConsultation.includes(proCompany.id),
          'border-b-0': isLastRow,
        },
      )}
      key={proCompany.id}
    >
      <ReportResultModal
        isOpen={showReportResultModal}
        consultationInfoId={consultationInfo.id}
        proCompany={proCompany}
        onClose={() => setShowReportResultModal(false)}
      />
      <CompanyDetails rank={rank} proCompany={proCompany} />
      <div className="flex flex-col items-center justify-center sm-desktop:w-2/12 sm-desktop:px-md relative">
        {proCompany.status === LabellisationStatus.labellisee && (
          <Tag size="md" className="mb-xs" variant="info" label="Pro à tester" />
        )}
        <DisabledReasonsTooltip disabledReasons={disabledReasons} proCompanyUuid={proCompany.uuid}>
          <div>
            <Checkbox
              id={`test-${proCompany.id}`}
              label="Choisir cette entreprise"
              checked={proCompaniesForConsultation.includes(proCompany.id)}
              onChange={checked =>
                setProCompaniesForConsultation(
                  checked
                    ? [...proCompaniesForConsultation, proCompany.id]
                    : proCompaniesForConsultation.filter((v: number) => v !== proCompany.id),
                )
              }
              disabled={disabledReasons.length > 0}
            />
          </div>
        </DisabledReasonsTooltip>
        <Button
          onClick={() => setShowReportResultModal(true)}
          rightIcon={<AlertCircle />}
          size="sm"
        >
          Signaler ce résultat...
        </Button>
      </div>
    </div>
  );
};
