import React from 'react';

type Props = {
  label: string | JSX.Element;
  icon: JSX.Element;
  className?: string;
};

export const Indicator: React.FC<Props> = ({ label, icon, className }) => (
  <div className="rounded-xs bg-beige-50 border border-primary-400 py-3xs sm-desktop:py-[3px] pl-xxs pr-xs sm-desktop:pr-sm">
    <div className="text-ds-b2 flex items-center">
      <span className="mr-xxs flex items-center">{icon}</span>
      <div className={`flex items-center sm-desktop:text-ds-b1 text-neutral-900 ${className}`}>
        {label}
      </div>
    </div>
  </div>
);
