import React from 'react';

import Clock from '@travauxlib/shared/src/components/DesignSystem/assets/Clock.svg?react';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { Consultation } from 'types';

type Props = {
  consultation: Consultation;
};

export const ConsultationSentAt: React.FC<Props> = ({ consultation: { consultationOffer } }) => (
  <div>
    {consultationOffer.expiresIn && (
      <div className="text-ds-sm text-info flex items-center">
        <Clock className="w-md h-md mr-xxs" /> Expire dans {consultationOffer.expiresIn}
      </div>
    )}
    {consultationOffer.isExpired && consultationOffer.expiredFrom && (
      <InlineAlert level="warning">Expirée depuis {consultationOffer.expiredFrom}</InlineAlert>
    )}
    <div>{formatFrenchDate(consultationOffer.createdAt)}</div>
  </div>
);
