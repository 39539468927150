import React from 'react';

import classNames from 'classnames';

import { ComparisonStatus } from '@travauxlib/shared/src/types';

import { getBGColorFromComparisonStatus } from '../utils/getBGColorFromComparisonStatus';

type Props = {
  displayFirstColumnClosingElement?: boolean;
  secondColumnComparisonStatus?: ComparisonStatus;
};

/**
 * Ce composant n'a pour but que d'afficher une fin de "card" si jamais c'est nécessaire.
 * Je n'ai pas trouvé de meilleur moyen de faire des containers au sein d'un tableau tout en conservant
 * la structure en ligne permettant d'aligner les prix de la 3e colonnes avec la ligne correspondante
 */
export const DevisComparatorLotContainerBottom: React.FC<Props> = ({
  displayFirstColumnClosingElement: displayFirst = true,
  secondColumnComparisonStatus,
}) => (
  <>
    <div className="px-md border-r-1 sm-desktop:block hidden">
      {displayFirst && <div className="bg-neutral-0 rounded-b-xxs pb-md mb-md shadow-ds-xxs" />}
    </div>
    <div className="sm-desktop:px-md border-r-1">
      <div
        className={classNames(
          'bg-neutral-0 rounded-b-xxs pb-md mb-md shadow-ds-xxs',
          getBGColorFromComparisonStatus(secondColumnComparisonStatus),
        )}
      />
    </div>
    <div className="sm-desktop:block hidden" />
  </>
);
