import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ProCompanyUsersControlPannel } from '@travauxlib/shared/src/features/ProCompanyUsersControlPannel';
import { ProUser } from '@travauxlib/shared/src/types/company';

import { useSignInAsPro } from 'features/Entreprises/api/useSignInAsPro';
import { useProCompany } from 'features/Entreprises/features/Entreprise/api/useProCompany';

import { useCreateNewProUserAccount } from '../api/useCreateNewProUserAccount';
import { PRO_COMPANY_KEY } from '../api/useProCompany';
import { useUpdateProCompany } from '../api/useUpdateProCompany';

export const ProCompanyUsersControlPannelContainer: React.FC = () => {
  const [showArchived, setShowArchived] = useState(false);
  const { slug } = useParams();
  const proCompany = useProCompany(slug!);
  const queryClient = useQueryClient();
  const { createNewProUserAccount, isLoading } = useCreateNewProUserAccount({
    onSuccess: () => queryClient.refetchQueries({ queryKey: [PRO_COMPANY_KEY] }),
  });
  const { archiveProUser, updateProUser } = useUpdateProCompany();
  const handleSignInAsPro = useSignInAsPro();

  const onSubmit = (user: ProUser, mode: 'create' | 'edit'): Promise<ProUser | string> => {
    if (mode === 'create') {
      return createNewProUserAccount({
        user,
        slug: slug!,
      });
    }

    return updateProUser({ updatedProUser: user, slug: slug! });
  };

  return (
    <ProCompanyUsersControlPannel
      className="py-md"
      proUsers={proCompany?.proUsers || []}
      isLoading={isLoading}
      showArchived={showArchived}
      setShowArchived={setShowArchived}
      onSubmit={onSubmit}
      archiveProUserAccount={archiveProUser}
      updateProUser={updateProUser}
      handleSignInAsPro={handleSignInAsPro}
      slug={slug}
    />
  );
};
