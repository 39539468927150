import React from 'react';

import _sum from 'lodash/sum';

import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Chantier, LiberationDeFonds } from '@travauxlib/shared/src/features/Chantiers/types';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  chantier: Chantier;
  paidToCompanyColumnLabel?: string;
  showAuthor: boolean;
  onClose: () => void;
};

export const LiberationDesFondsHistoryModal: React.FC<Props> = ({
  chantier,
  paidToCompanyColumnLabel,
  showAuthor,
  onClose,
}) => {
  const getFieldTotal = (mapFn: (l: LiberationDeFonds) => number): number =>
    _sum(chantier.liberationsDeFonds.map(mapFn));

  return (
    <SimpleModal isOpen title="Libérations de fonds" handleClose={onClose}>
      <Table
        defaultSort={{ columnKey: 'date', order: 'desc' }}
        columnConfigs={[
          {
            name: 'Date',
            columnKey: 'date' as const,
            renderValue: (date: string) => <span>{dateTimeFormat(date)}</span>,
          },
          {
            name: 'Auteur',
            columnKey: 'author' as const,
          },
          {
            name: 'Total',
            columnKey: 'total' as const,
            renderValue: (total: number) => <EURCurrency amount={total} forceFullPattern />,
          },
          {
            name: paidToCompanyColumnLabel || "Versé à l'entreprise",
            columnKey: 'paidToCompany' as const,
            renderValue: (paidToCompany: number) => (
              <span className="block text-right">
                <EURCurrency amount={paidToCompany} forceFullPattern />
              </span>
            ),
          },
          {
            name: 'Commission',
            columnKey: 'commission' as const,
            renderValue: (commission: number) => (
              <span className="text-right block">
                <EURCurrency amount={commission} forceFullPattern />
              </span>
            ),
          },
        ].filter(col => showAuthor || col.columnKey !== 'author')}
        items={chantier.liberationsDeFonds}
        footer={
          <tr className="!text-right font-bold text-ds-b2">
            <th />
            {showAuthor && <th />}
            <td className="px-xs text-left">
              <EURCurrency amount={getFieldTotal(l => l.total)} forceFullPattern />
            </td>
            <td className="px-xs text-right">
              <EURCurrency amount={getFieldTotal(l => l.paidToCompany)} forceFullPattern />
            </td>
            <td className="px-xs">
              <EURCurrency amount={getFieldTotal(l => l.commission)} forceFullPattern />
            </td>
          </tr>
        }
      />
    </SimpleModal>
  );
};
