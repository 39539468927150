import React from 'react';

import HelpCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircleOutline.svg?react';
import RequestQuote from '@travauxlib/shared/src/components/DesignSystem/assets/RequestQuoteDuoTone.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Popover } from '@travauxlib/shared/src/components/DesignSystem/components/Popover';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { BaseDePrix, Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { getConfigurationPrixHT } from '@travauxlib/shared/src/features/Configurateur/utils/getConfigurationPrixHT';
import { getConfigurationPrixTTC } from '@travauxlib/shared/src/features/Configurateur/utils/getConfigurationPrixTTC';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';

const POURCENTAGE_SERVICE_HEMEA = 3;
export const TotalConfigurationTTC: React.FC<{
  configuration: Configuration;
  baseDePrix: BaseDePrix;
}> = ({ configuration, baseDePrix }) => {
  const prixTTC = getConfigurationPrixTTC(baseDePrix, configuration);
  const prixHT = getConfigurationPrixHT(baseDePrix, configuration);
  const prixServiceHemea = Math.round((prixTTC * POURCENTAGE_SERVICE_HEMEA) / 100);
  const isTabletOrMobile = useIsTabletOrMobile();
  if (prixTTC === 0) {
    return null;
  }

  return (
    <div className="rounded-xs bg-beige-50 border border-primary-400 py-3xs sm-desktop:py-[3px] pl-xxs pr-xxs">
      <div className="text-ds-b2 sm-desktop:text-ds-b1 flex items-center">
        <span className="mr-xxs flex items-center">
          <RequestQuote width={isTabletOrMobile ? 16 : 24} height={isTabletOrMobile ? 16 : 24} />
        </span>
        <div className="flex items-center text-neutral-900">
          <div className="flex items-center">
            <EURCurrency className="block mr-xxs" amount={prixTTC} suffix=" TTC" />
            <Popover
              title="Détails du TTC :"
              content={
                <div>
                  Total TVA : <EURCurrency amount={prixTTC - prixHT} /> <br />
                  Total Service hemea : <EURCurrency amount={prixServiceHemea} /> <br />
                  Offre support suivi de chantier incluse
                </div>
              }
              position="bottom-end"
              label="Fermer"
              trigger={(onClose, referenceProps) => (
                <IconButton
                  {...referenceProps}
                  size={isTabletOrMobile ? 'xs' : 'sm'}
                  onClick={onClose}
                >
                  <HelpCircleOutline
                    className="text-neutral-600"
                    width={isTabletOrMobile ? 16 : 20}
                    height={isTabletOrMobile ? 16 : 20}
                  />
                </IconButton>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
