import React from 'react';

import classNames from 'classnames';

import ArchitectureOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ArchitectureOutline.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/Construction.svg?react';
import PlusCircle from '@travauxlib/shared/src/components/DesignSystem/assets/PlusCircle.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink, Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

import { Consultation, ConsultationInfo } from 'types';

import { ConsultationTable } from './ConsultationTable';
import { ConsultationTableFicheEntreprise } from './ConsultationTableFicheEntreprise';
import { EmptyConsultationTable } from './EmptyConsultationTable';

import { validateConsultationInfo } from '../../utils';

type Props = {
  consultationInfo: ConsultationInfo;
  consultations: Consultation[];
  showNewCompanyLink: boolean;
  ficheEntrepriseMode?: boolean;
};

export const ConsultationBlock: React.FC<Props> = ({
  consultationInfo,
  consultations,
  showNewCompanyLink,
  ficheEntrepriseMode,
}) => {
  const isConsultationArchi =
    consultationInfo.companyType === 'architecte' ||
    consultations.some(c => c.proCompany.companyType == 'architecte');

  const shouldDisableConsultation = Object.values(validateConsultationInfo(consultationInfo)).some(
    value => value !== undefined,
  );

  const consultationBaseUrl = `/projects/${consultationInfo.projectUuid}/consultation/${consultationInfo.id}`;

  return (
    <Card state="elevated" className="!rounded-md mb-md" bodyClassNames="!px-0">
      <div className="flex items-center mb-md justify-between">
        <div className="flex items-center">
          <Link className="flex items-center" to={consultationBaseUrl} variant="secondary">
            <div className="w-xl h-xl rounded-full ml-md mr-xs bg-beige-50 p-xxs">
              {isConsultationArchi ? (
                <ArchitectureOutline className="text-primary" />
              ) : (
                <Construction className="text-primary" />
              )}
            </div>
            <div className="font-bold text-ds-h4 mr-xs">
              {ficheEntrepriseMode ? (
                <>
                  {consultationInfo.title} - Budget:{' '}
                  <EURCurrency amount={consultationInfo.budgetClient} />
                </>
              ) : (
                <>Consultation {isConsultationArchi ? 'archi' : 'pro'}</>
              )}
            </div>
          </Link>
          <div className="flex gap-xs">
            {consultationInfo.typesTravaux.map(typeTravaux => (
              <Tag key={typeTravaux} label={typeTravaux} variant="light" size="md" />
            ))}
          </div>
        </div>
        {consultationInfo.pipedriveDealUrl && ficheEntrepriseMode && (
          <ButtonLink
            href={consultationInfo.pipedriveDealUrl}
            target="_blank"
            variant="secondary"
            size="sm"
            className="mr-md"
          >
            Accéder au Pipe
          </ButtonLink>
        )}
      </div>
      {consultations.length > 0 ? (
        ficheEntrepriseMode ? (
          <ConsultationTableFicheEntreprise consultations={consultations} />
        ) : (
          <ConsultationTable consultations={consultations} />
        )
      ) : (
        <EmptyConsultationTable consultationBaseUrl={consultationBaseUrl} />
      )}
      {showNewCompanyLink && (
        <div
          className={classNames(
            'text-center py-xs',
            consultations.length && consultations.length % 2 === 0
              ? 'bg-neutral-100'
              : 'bg-neutral-0',
          )}
        >
          <ButtonLink
            size="sm"
            variant="tertiary"
            disabledMessageTooltip="Il manque des informations dans les infos de consultation pour proposer une entreprise"
            disabled={shouldDisableConsultation}
            to={
              isConsultationArchi
                ? `${consultationBaseUrl}/entreprises-architecte`
                : `${consultationBaseUrl}/entreprises-pro`
            }
          >
            <PlusCircle className="h-md w-md mr-xxs" /> Proposer à une nouvelle entreprise
          </ButtonLink>
        </div>
      )}
    </Card>
  );
};
