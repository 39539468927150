import React from 'react';

import Straighten from '@travauxlib/shared/src/components/DesignSystem/assets/Straighten.svg?react';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { Configuration } from '@travauxlib/shared/src/features/Configurateur/types';
import { getLocationLabel } from '@travauxlib/shared/src/features/Configurateur/utils/getLocationLabel';
import { locationsPicto } from '@travauxlib/shared/src/features/DevisDisplay/components/LocalisationIcon';
import { Location } from '@travauxlib/shared/src/types';

import { LocationCard } from 'features/Configurateur/components/LocationCard';

type Props = {
  configuration: Configuration;
  locations: Location[];
  getFields: (locationUuid: string) => {
    name: string;
    defaultValue?: number;
  }[];
};

export const LocationsDimensions: React.FC<Props> = ({ configuration, locations, getFields }) => (
  <>
    {locations.map((location, index) => {
      const Picto = locationsPicto[location.typeLocation];
      const [longueurField, hauteurField] = getFields(location.uuid);
      return (
        <LocationCard
          key={location.uuid}
          className="mb-md"
          label={getLocationLabel(configuration, location)}
          centerIcon={<Picto />}
          inline={false}
          separator={index !== locations.length - 1}
        >
          <div className="flex flex-wrap justify-between text-left">
            <div className="flex items-center mb-md">
              <Straighten className="shrink-0 text-primary h-lg w-lg mr-xs" />
              <label
                htmlFor={longueurField.name}
                className="text-neutral-800 shrink-0 text-ds-b2 mb-xxs mr-md"
              >
                Longueur
              </label>
              <NumberInputField
                className="max-w-[9rem]"
                defaultValue={longueurField.defaultValue}
                suffix="m"
                name={longueurField.name}
                id={longueurField.name}
              />
            </div>
            <div className="flex items-center mb-md">
              <Straighten className="shrink-0 text-primary h-lg w-lg mr-xs" />
              <label
                htmlFor={hauteurField.name}
                className="text-neutral-800 shrink-0 text-ds-b2 mb-xxs mr-md"
              >
                Hauteur
              </label>
              <NumberInputField
                className="max-w-[9rem]"
                defaultValue={hauteurField.defaultValue}
                suffix="m"
                name={hauteurField.name}
                id={hauteurField.name}
              />
            </div>
          </div>
        </LocationCard>
      );
    })}
  </>
);
