import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DatePickerField } from '@travauxlib/shared/src/components/DesignSystem/components/DatePicker/Field';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InlineAlert } from '@travauxlib/shared/src/components/DesignSystem/components/InlineAlert';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { typeSuiviWordings } from '@travauxlib/shared/src/utils/constants';
import { formatLocalDate } from '@travauxlib/shared/src/utils/time';

import { useChantierAdvancedActionAuthorized } from 'components/Auth/api/useChantierAdvancedActionAuthorized';
import { useSuiviByOptions } from 'features/Chantiers/api/useSuiviByOptions';

import { useUpdateChantier } from '../../api/useUpdateChantier';

type Props = {
  chantier: Chantier;
};

export const ChantierEdit: React.FC<Props> = ({ chantier }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { updateChantier } = useUpdateChantier();

  const onCloseModal = (): void => setModalVisible(false);
  const { isAuthorized } = useChantierAdvancedActionAuthorized();
  const { allMembers, ccMembers } = useSuiviByOptions();

  return (
    <>
      <Button
        disabled={!isAuthorized}
        onClick={() => setModalVisible(true)}
        size="sm"
        variant="secondary"
      >
        Modifier
      </Button>
      <Modal
        isOpen={modalVisible}
        handleClose={onCloseModal}
        title="Modifier les détails du projet"
      >
        <Form
          onSubmit={async values => {
            const {
              dateDebutReelle,
              dateFinReelle,
              dateAnnulation,
              typeSuivi,
              responsableSuiviUuid,
              coachCareUuid,
            } = values;

            updateChantier({
              slug: chantier.slug,
              dateDebutReelle: formatLocalDate(dateDebutReelle),
              dateFinReelle: formatLocalDate(dateFinReelle),
              dateAnnulation: formatLocalDate(dateAnnulation),
              responsableSuiviUuid,
              coachCareUuid,
              typeSuivi,
            });

            onCloseModal();
          }}
          initialValues={chantier}
        >
          {({ handleSubmit, invalid, submitting }) => (
            <ModalContent
              handleSubmit={handleSubmit}
              validateAction={{
                label: 'Valider',
                type: 'submit',
                disabled: invalid || submitting || !isAuthorized,
                loading: submitting,
              }}
              cancelAction={{ label: 'Annuler', onClick: onCloseModal }}
            >
              <DropdownField
                className="mb-md"
                name="typeSuivi"
                id="typeSuivi"
                label="Suivi"
                options={[
                  typeSuiviWordings.service_hemea,
                  typeSuiviWordings.travaux_planner_with_service,
                  typeSuiviWordings.customer_care,
                  typeSuiviWordings.travaux_planner,
                  typeSuiviWordings.architecte,
                ]}
              />
              <DropdownField
                className="mb-md"
                name="responsableSuiviUuid"
                id="responsableSuiviUuid"
                label="Responsable Suivi"
                options={allMembers}
              />
              <InlineAlert level="info">
                Le Coach Care n'est à renseigner que lorsqu'un TP ou un CDPL effectue le suivi du
                chantier
              </InlineAlert>
              <DropdownField
                name="coachCareUuid"
                data-testid="coachCareUuid"
                id="coachCareUuid"
                label="Coach Care"
                defaultValue={chantier.coachCareUuid}
                options={ccMembers}
              />
              <hr />
              <DatePickerField
                className="mb-md"
                name="dateDebutReelle"
                id="dateDebutReelle"
                label="Date de début"
              />
              <DatePickerField
                className="mb-md"
                name="dateFinReelle"
                data-testid="dateFinReelle"
                id="dateFinReelle"
                label="Date de fin"
              />
              <DatePickerField
                name="dateAnnulation"
                id="dateAnnulation"
                label="Date d'annulation"
              />
            </ModalContent>
          )}
        </Form>
      </Modal>
    </>
  );
};
