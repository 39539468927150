import { AccountListing } from './components/AccountListing';
import { AgencyAccountListing } from './components/AgencyAccountListing';
import { AgencyListing } from './components/AgencyListing';
import { CreateAccount } from './components/CreateAccount';
import { CreateAgency } from './components/CreateAgency';
import { EditAgency } from './components/EditAgency';
import { EditOrganization } from './components/EditOrganization';
import { PartnerDetailsModal } from './components/PartnerDetailsModal';
import { PartnersListContainer } from './components/PartnersListContainer';
import { AccountsTable } from './components/Table/AccountsTable';
import { AgencyTable } from './components/Table/AgencyTable';
import { PartnerListing } from './components/Table/PartnerListing';
import { Commissions } from './features/Commissions';

export const Partners = {
  AccountListing,
  AgencyAccountListing,
  AgencyListing,
  CreateAccount,
  CreateAgency,
  PartnerDetailsModal,
  PartnerListing: () => <PartnersListContainer component={PartnerListing} />,
  AgencyTable: () => <PartnersListContainer component={AgencyTable} />,
  AccountsTable: () => <PartnersListContainer component={AccountsTable} />,
  Commissions,
  EditAgency,
  EditOrganization,
};
