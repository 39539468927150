import React from 'react';

import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links/ButtonLink';

import { FlowExecution } from './components/FlowExecution';

import { PageTitle } from '../../components/PageTitle';

const Index: React.FC = () => (
  <div className="flex flex-col gap-md">
    <PageTitle title="GodMode" />
    <ButtonLink to="flow/create_adressable_lead">Lead --- Adressable</ButtonLink>

    <ButtonLink to="flow/create_lead_consultation">Lead --- Consultation</ButtonLink>
    <ButtonLink to="flow/create_lead_chantier_pro_archi">Lead --- Chantier Archi & Pro</ButtonLink>
    <ButtonLink to="flow/create_lead_consultation_offer">Lead --- Consultation OK</ButtonLink>
    <ButtonLink to="flow/create_lead_and_devis">Lead --- Devis</ButtonLink>
    <ButtonLink to="flow/create_lead_send_devis">Lead --- Devis sent</ButtonLink>
    <ButtonLink to="flow/create_lead_sign_devis">Lead --- Devis signed</ButtonLink>
    <ButtonLink to="flow/create_lead_start_chantier">
      Lead --- Chantier started + LW mock
    </ButtonLink>
    <ButtonLink to="flow/create_lead_suivi_chantier">Lead --- Suivi Chantier v2</ButtonLink>
    <ButtonLink to="flow/create_lead_complete_configurateur">
      Lead --- Complete configurateur
    </ButtonLink>
    <ButtonLink to="flow/create_lead_complete_configurateur_consultation">
      Lead --- Complete configurateur --- Consultation
    </ButtonLink>
    <ButtonLink to="flow/create_lead_complete_configurateur_devis">
      Lead --- Complete configurateur --- Auto generated devis
    </ButtonLink>
    <ButtonLink to="flow/create_partenaire">Partenaire</ButtonLink>
    <ButtonLink to="flow/create_pro_without_subscription">
      Create pro --- No subscription
    </ButtonLink>
    <ButtonLink to="flow/create_pro_without_subscription_pro_client">
      Create pro --- No subscription --- Pro Client
    </ButtonLink>
    <ButtonLink to="flow/create_lead_and_sign_devis_and_devis_ts">
      Lead --- Devis signed --- Devis TS
    </ButtonLink>
    <ButtonLink to="flow/create_pro_and_client_and_devis">
      Create pro --- Client --- Devis
    </ButtonLink>
    <ButtonLink to="flow/create_employee">Create Employee</ButtonLink>
  </div>
);

export const GodMode = {
  Index: () => <Index />,
  Flow: () => <FlowExecution />,
};
