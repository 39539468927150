import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class ChantiersTrackingClass extends Tracker<AdminFeatures> {
  onChantiersViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const ChantiersTracking = new ChantiersTrackingClass(AdminFeatures.Chantiers);
