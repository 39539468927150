import React from 'react';

import { useUpdateAgency } from 'features/Partners/api/mutations';
import { useAgency } from 'features/Partners/hooks/useAgency';

import { AgencyForm } from './AgencyForm';

export const EditAgency: React.FC = () => {
  const agency = useAgency();
  const { updateAgency } = useUpdateAgency();
  if (!agency) {
    return null;
  }

  return <AgencyForm onSubmit={updateAgency} initialValues={agency} />;
};
