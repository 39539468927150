import React, { useMemo } from 'react';

import classNames from 'classnames';

import ChevronDown from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronDown.svg?react';
import ChevronUp from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronUp.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

import { SelectedOptions } from './SelectedOptions';
import { DropdownOption } from './types';

import {
  iconSuffixClassName,
  InputErrorText,
  InputHelperText,
  makeInputContainerClassnames,
  makeInputLabelClassnames,
  makeInputMainClassnames,
} from '../Input/commons';

export type Props<T> = Omit<React.HTMLProps<HTMLDivElement>, 'onChange' | 'value'> & {
  value?: Array<T>;
  combobox?: boolean;
  options: DropdownOption<T>[];
  id: string;
  onChange: (newValue: Array<T>) => void;
  label?: string | JSX.Element;
  className?: string;
  containerClassName?: string;
  inputClassName?: string;
  optionsContainerClassName?: string;
  optionClassName?: string;
  placeholder?: string;
  helperText?: string | React.ReactElement;
  error?: string;
  disabled?: boolean;
  onFilterFn?: (filter?: string | undefined) => (option: DropdownOption<T>) => boolean;
  defaultOption?: DropdownOption<T>;
  autoFocus?: boolean;
};

const genericLowerCaseFilter = <T,>(
  callBack: (option: DropdownOption<T>, filter: string) => boolean,
  filter?: string,
): ((option: DropdownOption<T>) => boolean) => {
  const lowerCasedFilter = filter?.toLocaleLowerCase();
  return (option: DropdownOption<T>) => !lowerCasedFilter || callBack(option, lowerCasedFilter);
};

export const filterStartsWithNonCaseSensitive = <T,>(
  filter?: string,
): ((option: DropdownOption<T>) => boolean) =>
  genericLowerCaseFilter(
    (option, lowerCasedFilter) => option.label.toLocaleLowerCase().startsWith(lowerCasedFilter),
    filter,
  );

export function DropdownMulti<T>({
  id,
  options,
  value = [],
  onChange,
  label,
  placeholder,
  helperText,
  error,
  onFilterFn,
  disabled,
  containerClassName,
  className,
  inputClassName,
  optionsContainerClassName,
  optionClassName,
  defaultOption,
  combobox,
  ...rest
}: Props<T>): JSX.Element {
  const [searchText, setSearchText] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState(false);
  const dropDownRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  useOnClickOutside(dropDownRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
    setSearchText('');
  });

  const filterWithDefault = React.useCallback(
    onFilterFn ? onFilterFn : filterStartsWithNonCaseSensitive,
    [onFilterFn],
  );

  const renderedOptions = useMemo(
    () =>
      options.filter(filterWithDefault(searchText)).map((option: DropdownOption<T>) => (
        <Checkbox
          className="!mb-0 !flex pl-sm py-sm hover:bg-gray-100"
          key={`${option.label}${option.value}`}
          label={option.label}
          onChange={checked => {
            checked
              ? onChange([...value, option.value])
              : onChange(value.filter(v => v !== option.value));
            setSearchText('');
          }}
          checked={value.includes(option.value)}
        />
      )),
    [options, value, filterWithDefault, searchText],
  );

  const selectedOptions = useMemo(
    () =>
      value
        .map(v => options.find(option => option.value === v))
        .filter(Boolean) as DropdownOption<T>[],
    [options, value],
  );

  return (
    <div
      onClick={() => {
        if (!disabled) {
          setIsOpen(true);
          inputRef.current?.focus();
        }
      }}
      ref={dropDownRef}
      {...rest}
      className={classNames(containerClassName || className, !disabled && 'cursor-pointer')}
    >
      <div
        className={classNames(
          makeInputContainerClassnames({ disabled, error }),
          '!w-full !pr-sm !pl-md !py-xxs',
        )}
      >
        <div
          className={classNames(
            'flex items-center w-full overflow-hidden pt-md gap-y-xxs -my-[1px] flex-wrap',
          )}
        >
          <SelectedOptions
            selectedOptions={selectedOptions}
            value={value}
            onChange={onChange}
            disabled={disabled}
            combobox={combobox}
          />

          <input
            size={Math.max(searchText.length, 1)}
            ref={inputRef}
            className={classNames(
              makeInputMainClassnames({ disabled, hasLabel: true }),
              '!p-0 !m-0 !h-[1.5rem]',
              selectedOptions.length !== 0 && '!w-auto',
            )}
            autoFocus={isOpen}
            id={id}
            placeholder={selectedOptions.length === 0 ? placeholder : ''}
            value={searchText}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onChange={event => setSearchText?.(event.target.value)}
            disabled={disabled}
          />
          {label && (
            <label
              className={classNames(
                makeInputLabelClassnames({
                  value: searchText,
                  disabled,
                  hasPrefix: selectedOptions.length !== 0,
                }),
                '!pl-0',
              )}
              htmlFor={id}
            >
              {label}
            </label>
          )}
        </div>
        <div className={classNames(iconSuffixClassName, 'ml-xs self-start mt-xs')}>
          {isOpen ? (
            <ChevronUp
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            />
          ) : (
            <ChevronDown />
          )}
        </div>
      </div>
      {isOpen && (
        <>
          {renderedOptions && renderedOptions.length > 0 && (
            <div
              data-testid="open-menu"
              onClick={e => e.stopPropagation()}
              className="relative z-30 shadow-ds-xs"
            >
              <Card
                className={classNames(
                  optionsContainerClassName,
                  'absolute w-full mt-xxs border !rounded',
                )}
                bodyClassNames="px-0 py-xs"
              >
                <div className="max-h-[12rem] overflow-auto">{renderedOptions}</div>
              </Card>
            </div>
          )}
        </>
      )}
      <InputErrorText error={error} />
      <InputHelperText helperText={helperText} />
    </div>
  );
}
