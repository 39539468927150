import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class InscriptionsProTrackingClass extends Tracker<AdminFeatures> {
  onInscriptionsProViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const InscriptionsProTracking = new InscriptionsProTrackingClass(
  AdminFeatures.InscriptionsPro,
);
