import React, { useState } from 'react';

import Edit from '@travauxlib/shared/src/components/DesignSystem/assets/Edit.svg?react';
import InfoCircle from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircle.svg?react';
import Location from '@travauxlib/shared/src/components/DesignSystem/assets/Location.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { Link } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Tag } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { AvailabilityStatus } from 'features/Entreprises/components/AvailabilityStatus';
import { ProCompany } from 'types';
import { clicDataScoreURL } from 'utils/urls';

import { PauseThresholdsModal } from './PauseThresholdsModal';
import { ToggleFreezeModal } from './ToggleFreezeModal';
import { UpdatePauseStatusModal } from './UpdatePauseStatusModal';

type Props = {
  proCompany: ProCompany;
};

export const CompanyHeader: React.FC<Props> = ({ proCompany }) => {
  const { logoUrl, companyType } = proCompany;
  const companyTypeLabel = companyType.toUpperCase();
  const [toggleFreezeModalVisible, setToggleFreezeModalVisible] = useState(false);
  const [updatePauseStatusModalVisible, setUpdatePauseStatusModalVisible] = useState(false);
  const [pauseThresholdsModalVisible, setPauseThresholdsModalVisible] = useState(false);

  const googleMapsUri = `https://maps.google.com/?q=${[
    proCompany.address,
    proCompany.postalCode,
    'France',
  ].join(' ')}`;

  return (
    <>
      <ToggleFreezeModal
        isOpen={toggleFreezeModalVisible}
        onClose={() => setToggleFreezeModalVisible(false)}
        slug={proCompany.slug}
        frozenAt={proCompany.frozenAt}
      />
      <UpdatePauseStatusModal
        isOpen={updatePauseStatusModalVisible}
        onClose={() => setUpdatePauseStatusModalVisible(false)}
        proCompany={proCompany}
      />
      <PauseThresholdsModal
        isOpen={pauseThresholdsModalVisible}
        onClose={() => setPauseThresholdsModalVisible(false)}
        pauseThresholds={proCompany.pauseStats.pauseThresholds}
        updatedAt={proCompany.pauseStats?.updatedAt}
        companyType={companyType}
      />
      <Card state="filled">
        <div className="flex flex-wrap -mx-md !mb-md">
          <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative">
            <div className="!mb-xs">
              <Tag size="md" variant="info" label={companyTypeLabel} />{' '}
              <Tag
                size="md"
                label={proCompany.status}
                variant={proCompany.status === LabellisationStatus.activee ? 'success' : 'info'}
              />
            </div>
            <h2>{proCompany.name}</h2>
            <div>
              <Link to={`/chantiers?proCompanyName=${proCompany.name}`}>Voir ses chantiers</Link>
            </div>
            <div>
              <Link href={clicDataScoreURL} target="_blank">
                Trouver la note du pro
              </Link>
            </div>
          </div>
          <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative !text-right">
            {logoUrl ? (
              <Link data-testid="logo" href={logoUrl} target="_blank" rel="noopener noreferrer">
                <img
                  alt="Entreprise logo"
                  src={logoUrl}
                  className="h-auto max-w-full max-w-[10rem] max-h-[5rem]"
                />
              </Link>
            ) : (
              <div>Pas encore de logo</div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-md">
          <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative">
            👷 {proCompany.firstNameRepresentantLegal} {proCompany.lastNameRepresentantLegal}
            <br />
            📧 {proCompany.email}
            <br />
            📞 {proCompany.phoneNumber}
            <br />
            {proCompany.googleMapsAddress && (
              <Link
                href={googleMapsUri}
                target="_blank"
                rel="noopener noreferrer"
                icon={<Location />}
                inline
              >
                {proCompany.address} {proCompany.postalCode} {proCompany.city}
              </Link>
            )}
          </div>
          <div className="sm-desktop:basis-0 sm-desktop:flex-grow sm-desktop:px-md relative text-right">
            <div className="flex-row flex justify-end items-center">
              {proCompany.frozenAt ? (
                <span className="relative px-lg py-sm rounded bg-info-50 !text-info !font-bold">
                  ❄️ Gelée le {formatFrenchDate(proCompany.frozenAt)}
                </span>
              ) : (
                '❄️ Non gelée'
              )}
              <IconButton onClick={() => setToggleFreezeModalVisible(true)}>
                <Edit />
              </IconButton>
            </div>
            <div className="flex-row flex justify-end items-center">
              <AvailabilityStatus proCompany={proCompany} advanced />
              <IconButton onClick={() => setUpdatePauseStatusModalVisible(true)}>
                <Edit />
              </IconButton>
            </div>
            {proCompany.pauseStats && (
              <div className="flex-row flex justify-end items-center">
                Seuils de mise en pause
                <IconButton onClick={() => setPauseThresholdsModalVisible(true)}>
                  <InfoCircle />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};
