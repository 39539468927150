import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { getUrlParams } from '@travauxlib/shared/src/utils/urls';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { Table } from 'features/DashboardCDP/components/Table';
import { LeadsTracking } from 'utils/tracking/LeadsTracking';

import { useLeadClients } from './api/useLeadClients';
import { LeadForm } from './components/Form';

export const Leads: React.FC = () => {
  const location = useLocation();
  const criteria = getUrlParams(location);
  const { leads, isLoading } = useLeadClients(criteria);

  useEffect(() => {
    LeadsTracking.onLeadsViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.leads} />
      <div>
        <LeadForm initialValues={criteria} />
        {isLoading ? <LoaderWrapper /> : <Table leads={leads} />}
      </div>
    </>
  );
};
