import React from 'react';

import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { PlacesAutocompleteField } from '@travauxlib/shared/src/components/PlacesAutocomplete/Field';
import {
  required,
  requiredValidEmail,
  requiredValidPhoneNumber,
  validateGoogleMapAddress,
} from '@travauxlib/shared/src/utils/form';

type Props = {
  address?: string;
};

export const ContactInfos: React.ComponentType<Props> = ({ address = '' }) => (
  <div className="!mb-sm">
    <h2>Coordonnées</h2>
    <div className="flex flex-wrap -mx-md">
      <div className="tablet:w-6/12 tablet:px-md relative">
        <InputField id="name" name="name" label="Nom de l'entreprise*" validate={required} />
      </div>
      <div className="tablet:w-3/12 tablet:px-md relative pb-md">
        <InputField
          label="Prénom du gérant*"
          id="firstNameRepresentantLegal"
          name="firstNameRepresentantLegal"
          validate={required}
        />
      </div>
      <div className="tablet:w-3/12 tablet:px-md relative">
        <InputField
          id="lastNameRepresentantLegal"
          name="lastNameRepresentantLegal"
          label="Nom du gérant*"
          validate={required}
        />
      </div>
    </div>
    <div className="flex flex-wrap -mx-md mb-md">
      <div className="tablet:w-6/12 tablet:px-md relative">
        <InputField id="email" name="email" label="Email*" validate={requiredValidEmail} />
      </div>
      <div className="tablet:w-6/12 tablet:px-md relative">
        <InputField
          id="phoneNumber"
          name="phoneNumber"
          label="Numéro de téléphone*"
          validate={requiredValidPhoneNumber}
        />
      </div>
    </div>
    <div className="mb-md">
      <PlacesAutocompleteField
        id="googleMapsAddress"
        name="googleMapsAddress"
        label="Adresse complète"
        initialInputValue={address}
        validate={validateGoogleMapAddress('postal_code')}
        resetOnBlur
        googleApiKey={APP_CONFIG.googleApiKey}
        onlyAllowFrance={false}
      />
    </div>
    <div className="flex flex-wrap -mx-md pb-md">
      <div className="sm-desktop:w-6/12 sm-desktop:px-md relative">
        <InputField
          id="websites.personalWebsite"
          name="websites.personalWebsite"
          type="url"
          label="Site internet"
        />
      </div>
      <div className="sm-desktop:w-6/12 sm-desktop:px-md relative">
        <InputField id="websites.pinterest" name="websites.pinterest" label="Compte pinterest" />
      </div>
    </div>
    <div className="flex flex-wrap -mx-md">
      <div className="sm-desktop:w-6/12 sm-desktop:px-md relative">
        <InputField id="websites.instagram" name="websites.instagram" label="Compte instagram" />
      </div>
      <div className="sm-desktop:w-6/12 sm-desktop:px-md relative">
        <InputField label="Compte facebook" id="websites.facebook" name="websites.facebook" />
      </div>
    </div>
  </div>
);
