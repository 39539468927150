import React from 'react';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { LotComparison } from '@travauxlib/shared/src/features/DevisDisplay/types';

import { DevisComparatorLineRow } from './DevisComparatorLineRow';
import { DevisComparatorLotColumn } from './DevisComparatorLotColumn';
import { DevisComparatorLotContainerBottom } from './DevisComparatorLotContainerBottom';

type Props = {
  comparison: LotComparison;
};

export const DevisComparatorLotRow: React.FC<Props> = ({ comparison }) => {
  const base = comparison.base;
  const compare = comparison.compare;
  const rightColumnElement = compare || base;
  const priceDiff = (compare?.prixTotalHT || 0) - (base?.prixTotalHT || 0);
  let baseBottomAlreadyDisplayed = false;
  const lotComparisonStatus = comparison.status;

  return (
    <>
      <div className="sm-desktop:px-md first:pt-md border-r-1 sm-desktop:block hidden">
        {base && (
          <DevisComparatorLotColumn
            label={base.label}
            prixTotalHT={base.prixTotalHT || 0}
            comparisonStatus="unchanged"
          />
        )}
      </div>
      <div className="sm-desktop:px-md [&:nth-child(2)]:pt-md border-r-1">
        <DevisComparatorLotColumn
          label={rightColumnElement!.label}
          prixTotalHT={rightColumnElement!.prixTotalHT || 0}
          comparisonStatus={comparison.status}
          priceDifference={(compare?.prixTotalHT || 0) - (base?.prixTotalHT || 0)}
        />
      </div>
      <div className="pl-md pt-md [&:nth-child(3)]:pt-xl pr-xl font-bold text-ds-b1 text-right sm-desktop:block hidden">
        {priceDiff > 0 && '+'}
        <EURCurrency amount={(compare?.prixTotalHT || 0) - (base?.prixTotalHT || 0)} /> HT
      </div>
      {comparison.lignes.map((ligneComparison, index) => {
        if (!baseBottomAlreadyDisplayed && !ligneComparison.base) {
          baseBottomAlreadyDisplayed = true;
          return (
            <DevisComparatorLineRow
              lotComparisonStatus={lotComparisonStatus}
              key={index}
              comparison={ligneComparison}
              addBaseLotContainerBottom={lotComparisonStatus !== 'added'}
            />
          );
        }

        return (
          <DevisComparatorLineRow
            lotComparisonStatus={lotComparisonStatus}
            key={index}
            comparison={ligneComparison}
          />
        );
      })}
      <DevisComparatorLotContainerBottom
        displayFirstColumnClosingElement={
          lotComparisonStatus !== 'added' && !baseBottomAlreadyDisplayed
        }
        secondColumnComparisonStatus={lotComparisonStatus}
      />
    </>
  );
};
