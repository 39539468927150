import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class EntreprisesTrackingClass extends Tracker<AdminFeatures> {
  onEntreprisesViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const EntreprisesTracking = new EntreprisesTrackingClass(AdminFeatures.Entreprises);
