import { ChantierListItem } from '@travauxlib/shared/src/features/Chantiers/types';

import { ChantierTableItem } from '../components/ChantierTableItem';
import { Filters } from '../types';

export const getFilteredChantiers = (
  chantiers: ChantierListItem[],
  filters: Filters,
): ChantierListItem[] => {
  const chantierItems = chantiers.map(c => new ChantierTableItem(c));
  return chantierItems.filter(c => c.filterWith(filters)).map(i => i.chantier);
};
