import React from 'react';

import Person from '@travauxlib/shared/src/components/DesignSystem/assets/Person.svg?react';
import { EventCard } from '@travauxlib/shared/src/components/DesignSystem/components/EventCard';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { eventCardClassName } from './constants';

type Props = {
  chantier: Chantier;
};

export const ProKYC2Card: React.FC<Props> = ({ chantier }) =>
  !chantier.proCompany.isKycCompliant ? (
    <EventCard
      className={eventCardClassName}
      icon={<Person />}
      variant="warning"
      title="Marquer le pro en KYC2"
    >
      <span className="text-ds-b2 italic">Voir directement avec l'équipe réseau pro d’hemea.</span>
    </EventCard>
  ) : (
    <></>
  );
