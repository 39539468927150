import React from 'react';

import { Field, Form } from 'react-final-form';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbol.svg?react';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { toast } from '@travauxlib/shared/src/components/Notifications';

import { useUpdateProCompany } from 'features/Entreprises/features/Entreprise/api/useUpdateProCompany';
import { ProProject } from 'types';

import { Photos } from './Photos';

type Props = {
  index?: number;
  slug: string;
  projectId?: number;
  initialValues: ProProject;
};

export const Project: React.FC<Props> = ({ index, projectId, initialValues, slug }) => {
  const { uploadPhoto, updateProject, deleteProject } = useUpdateProCompany();
  const uploadAllPhotos = async (files: File[]): Promise<{ path: string }[]> => {
    let result: { path: string }[] = [];
    for (const file of files) {
      const s3File = await uploadPhoto({ slug, file });
      result = [...result, s3File];
    }
    toast.success('Photos créés avec succès, pensez à sauvegarder !');
    return result;
  };
  return (
    <div data-testid="project">
      <Form<ProProject>
        onSubmit={async project => {
          await updateProject({ project, slug });
        }}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <>
            {index !== undefined ? <h2>Projet n°{index + 1}</h2> : <h2>Nouveau projet</h2>}
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap -mx-md">
                <div className="sm-desktop:w-4/12 sm-desktop:px-md relative">
                  <div className="mb-md">
                    <InputField label="Titre" id="title" type="text" name="title" required />
                  </div>
                  <div className="mb-md">
                    <InputField label="Montant final TTC" id="budget" type="text" name="budget" />
                  </div>
                  <div>
                    <NumberInputField
                      label="Délais"
                      id="duration-in-days"
                      type="number"
                      name="durationInDays"
                      suffix="jours"
                      step={1}
                      min={0}
                    />
                  </div>
                </div>
                <div className="sm-desktop:w-8/12 sm-desktop:px-md relative">
                  <Field
                    name="photos"
                    component={Photos}
                    uploadPhoto={uploadAllPhotos}
                    onReject={(fileRejections: { file: File }[]) => {
                      const message = `Ces fichiers sont trop gros: ${fileRejections
                        .map(({ file }) => file.name)
                        .join(',')}`;
                      toast.error(message);
                    }}
                  />
                </div>
              </div>
              <div className="!flex !flex-row-reverse !mt-sm">
                <Button size="sm" type="submit" leftIcon={<CheckSymbol />}>
                  Sauvegarder les modifications
                </Button>
                {projectId !== undefined && (
                  <Button
                    size="sm"
                    type="button"
                    leftIcon={<Delete />}
                    className="!mr-sm"
                    onClick={() => deleteProject({ projectId, slug })}
                  >
                    Supprimer le projet
                  </Button>
                )}
              </div>
              <hr />
            </form>
          </>
        )}
      </Form>
    </div>
  );
};
