import React from 'react';

import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form';

import { ChipList as RawChipList, Props as ChipListProps } from './ChipList';
import {
  ChipMultipleList as RawChipMultipleList,
  Props as ChipMultipleListProps,
} from './ChipMultipleList';
import {
  ChipPictoVerticalList as RawChipPictoVerticalList,
  ChipPictoVerticalProps,
} from './ChipPictoVerticalList';

import { FormControlListProps, FormControlListVerticalChipProps } from '../FormControl/commons';

const ChipPictoVerticalListWrapper: React.FC<
  FieldRenderProps<string> & Omit<FormControlListVerticalChipProps<string>, 'onChange' | 'value'>
> = ({ input: { onChange, value }, ...rest }) => (
  <RawChipPictoVerticalList value={value} onChange={onChange} {...rest} />
);

const ChipListWrapper: React.FC<
  FieldRenderProps<string> & Omit<FormControlListProps<string>, 'onChange' | 'value'>
> = ({ input: { onChange, value }, ...rest }) => (
  <RawChipList value={value} onChange={onChange} {...rest} />
);

const ChipMultipleListWrapper: React.FC<
  FieldRenderProps<string[]> & Omit<FormControlListProps<string>, 'onChange' | 'value'>
> = ({ input: { onChange, value }, meta, ...rest }) => {
  const error = meta.error || (!meta.dirtySinceLastSubmit && meta.submitError);

  return (
    <RawChipMultipleList
      value={value}
      onChange={onChange}
      error={meta.touched && error}
      {...rest}
    />
  );
};

type ChipPictoVerticalListFieldProps = {
  name: string;
} & Omit<ChipPictoVerticalProps, 'value' | 'onChange'> &
  UseFieldConfig<string>;

export const ChipPictoVerticalListField: React.FC<ChipPictoVerticalListFieldProps> = props => (
  <Field component={ChipPictoVerticalListWrapper} {...props} />
);

type ChipListFieldProps = {
  name: string;
} & Omit<ChipListProps, 'value' | 'onChange'> &
  UseFieldConfig<string>;

export const ChipListField: React.FC<ChipListFieldProps> = props => (
  <Field component={ChipListWrapper} {...props} />
);

type ChipMultipleListFieldProps = {
  name: string;
} & Omit<ChipMultipleListProps, 'value' | 'onChange'> &
  UseFieldConfig<string[]>;

export const ChipMultipleListField: React.FC<ChipMultipleListFieldProps> = props => (
  <Field component={ChipMultipleListWrapper} {...props} />
);
