import React from 'react';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { ButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { adminUrls } from 'utils/urls';

import { ChantierInfoLine } from './ChantierInfoLine';
import { CompanyTag } from './CompanyTag';

type Props = {
  chantier: Chantier;
};

export const ChantierCompany: React.FC<Props> = ({ chantier }) => {
  const { proCompany, proUserName, proUserPhoneNumber } = chantier;
  const { name, slug } = proCompany;

  return (
    <Card state="outlined" className="sm-desktop:w-[20.5rem]">
      <div className="flex flex-col items-center sm-desktop:flex-row justify-between">
        <span className="text-ds-h5 font-bold">Entreprise</span>
        <CompanyTag proCompany={proCompany} />
      </div>
      <div className="mt-md space-y-xxs">
        <ChantierInfoLine icon="company" title="Nom de l'entreprise" value={name} />
        <ChantierInfoLine icon="name" title="Nom du Pro" value={proUserName} />
        <ChantierInfoLine icon="phone" title="Numéro de téléphone" value={proUserPhoneNumber} />
      </div>
      <div className="flex mt-md justify-end">
        <ButtonLink
          to={adminUrls.editCompanyUrl(slug)}
          target="_blank"
          size="sm"
          variant="secondary"
          rightIcon={<ChevronRight />}
        >
          Voir l'entreprise
        </ButtonLink>
      </div>
    </Card>
  );
};
