import React from 'react';

import SearchEmptyState from '@travauxlib/shared/src/components/DesignSystem/assets/SearchEmptyState.svg?react';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';

import { Lead } from 'types';

import { CDPDashboardLine } from './CDPDashboardLine';

type Props = {
  leads: Lead[];
};

export const Table: React.FC<Props> = ({ leads }) => {
  if (leads.length === 0) {
    return (
      <EmptyState description="Aucun lead n'a été trouvé" illustration={<SearchEmptyState />} />
    );
  }
  return (
    <div className="w-full shadow-ds-xs overflow-x-auto" key={leads.length}>
      <table className="table table-bordered">
        <tbody>
          {leads.map(lead => (
            <CDPDashboardLine key={lead.token} lead={lead} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
