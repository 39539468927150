import React from 'react';

import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { SlotRDV } from '@travauxlib/shared/src/types';

import { AgendaSlotsField } from './AgendaSlots/Field';

type Props = {
  bookedSlots: SlotRDV[];
};

export const SlotsRendezVous: React.FC<Props> = ({ bookedSlots }) => (
  <>
    <div>
      <h4 className="font-bold mb-md">Disponibilités du client</h4>
      <NumberInputField
        id="minutesBetweenRdv"
        name="minutesBetweenRdv"
        className="relative mb-md"
        label={<span>Délai avant l'arrivée éventuelle d'un prochain pro (en minutes) *</span>}
        parse={value => (value ? parseInt(value, 10) : 0)}
        format={value => value && value.toString()}
        type="number"
      />
      <AgendaSlotsField
        name="slotsRendezVous"
        label="Plages de disponiblité du client"
        bookedSlots={bookedSlots}
      />
    </div>
  </>
);
