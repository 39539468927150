import React from 'react';

import { Consultation } from 'types';

type Props = {
  consultation: Consultation;
};

export const ConsultationFeedback: React.FC<Props> = ({
  consultation: { deal, consultationOffer },
}) => {
  if (consultationOffer.response?.rejectionReason) {
    return (
      <div>
        <div className="text-ds-sm text-neutral-700">Raison du refus</div>
        <div className="text-ds-b2 text-neutral-800">
          “{consultationOffer.response.rejectionReason}”
        </div>
      </div>
    );
  }
  if (deal?.feedbackRDV) {
    return (
      <div>
        <div className="text-ds-sm text-neutral-700">Retour du pro</div>
        <div className="text-ds-b2 text-neutral-800">“{deal.feedbackRDV}”</div>
      </div>
    );
  }
  return <div />;
};
