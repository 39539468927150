import { Navigate } from 'react-router-dom';

import { useCreatePartner } from 'features/Partners/api/mutations';
import { usePartner } from 'features/Partners/hooks/usePartner';

import { AccountForm } from './AccountForm';
import { PartnerDetailsContent } from './PartnerDetailsModal/PartnerDetailsContent';

export const CreateAccount: React.FC = () => {
  const partner = usePartner();
  const { createAccount } = useCreatePartner(partner?.uuid);
  if (!partner) {
    return <Navigate to=".." />;
  }

  return (
    <PartnerDetailsContent>
      <h3 className="mb-md">Nouveau compte</h3>
      <AccountForm onSubmit={createAccount} agencies={partner.agencies} />
    </PartnerDetailsContent>
  );
};
