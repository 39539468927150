import React, { useState } from 'react';

import _partition from 'lodash/partition';

import InfoCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/InfoCircleOutline.svg?react';
import { Carousel } from '@travauxlib/shared/src/components/DesignSystem/components/Carousel';
import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';
import { DownloadableFileWithTag } from '@travauxlib/shared/src/types';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';
import { isURLAnImage } from '@travauxlib/shared/src/utils/urls';

import { ImagesViewer } from 'features/Project/features/ProjectDocumentsViewer/components/ImagesViewer';

import { PhotoCard } from './PhotoCard';

type Props = {
  title: string;
  images: DownloadableFileWithTag[];
  icon: JSX.Element;
  emptyState: JSX.Element;
};

export const ImagesCarousel: React.FC<Props> = ({ title, images, icon, emptyState }) => {
  const [photoIndex, setPhotoIndex] = useState<number | undefined>(undefined);
  const [imageDocuments, otherDocuments] = _partition(
    images,
    ({ name, imgixUrl }) => isURLAnImage(name) && imgixUrl,
  );

  const renderItem = (item: DownloadableFileWithTag, index: number): React.ReactElement => {
    if (isURLAnImage(item.name) && item.imgixUrl) {
      return (
        <PhotoCard
          imgixUrl={item.imgixUrl}
          downloadUrl={item.downloadUrl}
          name={item.name}
          onClick={() => setPhotoIndex(index)}
        />
      );
    } else {
      return <PhotoCard imgixUrl="Not a photo" downloadUrl={item.downloadUrl} name={item.name} />;
    }
  };

  return (
    <>
      <Carousel
        className="py-xs border-b"
        emptyState={emptyState}
        header={
          <div className="flex items-center">
            <ColoredCircle size="sm" children={icon} variant="beige" />
            <div className="ml-xs">
              <div className="text-neutral-800 text-ds-b2">{title}</div>
              {images.length < 1 ? (
                <div className="flex items-center gap-xxs text-neutral-600 text-ds-b2">
                  <InfoCircleOutline className="w-xmd h-xmd" />
                  Manquant
                </div>
              ) : (
                <div className="text-neutral-600 text-ds-b2 flex">
                  Ajoutées le{' '}
                  <div className="text-neutral-800 ml-xxs">
                    {formatFrenchDate(images[0].createdAt)}
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        items={[...imageDocuments, ...otherDocuments]}
        renderItem={(item, index) => <div className="flex gap-xs">{renderItem(item, index)}</div>}
      />
      <ImagesViewer setIndex={setPhotoIndex} images={imageDocuments} index={photoIndex} />
    </>
  );
};
