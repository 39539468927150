import React, { useEffect, useRef, useState } from 'react';

import { autoUpdate, flip, useFloating } from '@floating-ui/react';
import { fr } from 'date-fns/locale';
import dayjs, { Dayjs } from 'dayjs';
import { DayPicker } from 'react-day-picker';

import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

import 'react-day-picker/dist/style.css';
import { Input, Props as InputProps } from '../Input';

export type Props = {
  helperText?: string | React.ReactElement;
  disabled?: boolean;
  onChange: (date: Dayjs | undefined) => void;
  value?: Dayjs;
  id: string;
  dateFormat?: string;
  isValid?: boolean;
  error?: string;
  shouldBeDisabled?: (date: Date) => boolean;
  readOnly?: boolean;
  className?: string;
  label: string;
  containerClassName?: string;
  fixedFloating?: boolean;
};

const defaultDateFormat = 'DD/MM/YYYY';

export const DatePicker: React.FC<Props & Omit<InputProps, 'value' | 'input' | 'onChange'>> = ({
  value,
  onChange,
  id,
  dateFormat = defaultDateFormat,
  error,
  shouldBeDisabled,
  readOnly,
  containerClassName,
  placeholder = 'jj/mm/aaaa',
  fixedFloating = true,
  ...rest
}) => {
  const initialInputValue = value && dayjs(value).isValid() ? dayjs(value).format(dateFormat) : '';

  const [inputValue, setInputValue] = useState<string>(initialInputValue);

  useEffect(() => {
    if (initialInputValue !== inputValue) {
      setInputValue(initialInputValue);
    }
  }, [initialInputValue]);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: fixedFloating ? 'fixed' : 'absolute',
    middleware: [flip()],
    whileElementsMounted: autoUpdate,
  });
  const [shouldShowError, setShouldShowError] = useState(true);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const parentRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(parentRef, () => {
    if (isDatePickerOpen) {
      setIsDatePickerOpen(false);
      setShouldShowError(true);
    }
  });

  return (
    <div ref={parentRef}>
      <div ref={refs.setReference}>
        <Input
          id={id}
          onChange={str => {
            setInputValue(str);
            const parsedDate = dayjs(str, dateFormat);
            return onChange(parsedDate.isValid() ? parsedDate : undefined);
          }}
          value={inputValue}
          placeholder={placeholder}
          error={shouldShowError ? error : undefined}
          {...rest}
          onFocus={e => {
            rest.onFocus?.(e);
            setIsDatePickerOpen(true);
            setShouldShowError(false);
          }}
        />
      </div>
      {isDatePickerOpen && (
        <div
          data-testid="container"
          tabIndex={-1}
          ref={refs.setFloating}
          className="absolute z-50 bg-white shadow-ds-xs"
          style={floatingStyles}
          role="dialog"
          aria-label="DayPicker calendar"
        >
          <DayPicker
            mode="single"
            selected={value && dayjs(value).isValid() ? dayjs(value).toDate() : undefined}
            onSelect={value => {
              if (value) {
                setIsDatePickerOpen(false);
                setShouldShowError(true);
                setInputValue(dayjs(value).format(dateFormat));
                return onChange(dayjs(value));
              }
              return onChange(undefined);
            }}
            locale={fr}
            disabled={shouldBeDisabled}
          />
        </div>
      )}
    </div>
  );
};
