import { ChantierListItem } from '@travauxlib/shared/src/features/Chantiers/types';

import { CurrentTab, EnAttenteDe, Filters, MoneyFilterValue } from '../types';

export class ChantierTableItem {
  chantier: ChantierListItem;

  constructor(chantier: ChantierListItem) {
    this.chantier = chantier;
  }

  verifyTabCondition(tab: CurrentTab): boolean {
    if (tab === 'tous') {
      return true;
    }
    if (tab === 'en_cours') {
      return ['non_demarre', 'demarre'].includes(this.chantier.status);
    }
    if (tab === 'termines') {
      return ['termine', 'annule'].includes(this.chantier.status);
    }
    return this.enAttenteDe.includes(tab);
  }

  verifyFilterValue(filterValue: string | undefined, chantierValue?: string): boolean {
    if ([undefined, 'tous', 'indifferent'].includes(filterValue)) {
      return true;
    }
    return chantierValue === filterValue;
  }

  verifySuiviBy(suiviBy?: string): boolean {
    if (!suiviBy || suiviBy === 'tous') {
      return true;
    }
    if (suiviBy === 'aucun') {
      return !this.chantier.responsableSuiviUuid;
    }
    return this.chantier.responsableSuiviUuid === suiviBy;
  }

  stringFilterContains(filterValue: string | undefined, chantierValue: string): boolean {
    if (!filterValue) {
      return true;
    }
    return chantierValue.toLowerCase().includes(filterValue.toLowerCase());
  }

  verifyClientAccounts(clientAccountFilter: string | undefined): boolean {
    if (!clientAccountFilter) {
      return true;
    }
    return this.chantier.clientAccounts.some(
      ca =>
        clientAccountFilter
          .split(' ')
          .every(keyword =>
            (ca.firstName + ca.lastName).toLowerCase().includes(keyword.toLowerCase()),
          ) || this.stringFilterContains(clientAccountFilter, ca.email),
    );
  }

  filterWith(filters: Filters): boolean {
    const conditions = [
      this.verifyTabCondition(filters.tab),

      this.verifyFilterValue(
        filters.prestation,
        this.chantier.proCompany.companyType === 'architecte' ? 'conception' : 'travaux',
      ),

      this.verifyFilterValue(filters.suivi, this.chantier.typeSuivi),

      this.verifyFilterValue(filters.demarre, this.demarre),

      this.verifySuiviBy(filters.suiviBy),

      this.verifyFilterValue(filters.pipedriveDealId, this.chantier.pipedriveDealId?.toString()),

      this.verifyClientAccounts(filters.clientAccount),
      this.stringFilterContains(filters.proCompanyName, this.chantier.proCompany.name),

      this.verifyFilterValue(filters.commissionCaptee, this.commissionCaptee),

      this.verifyFilterValue(filters.prestationHemeaEncaissee, this.prestationHemeaEncaissee),

      this.verifyFilterValue(filters.versementALentreprise, this.versementALentreprise),
    ];
    return !conditions.includes(false);
  }

  get enAttenteDe(): EnAttenteDe[] {
    if (['termine', 'annule'].includes(this.chantier.status)) {
      return [];
    }

    const enAttenteProvision = this.chantier.montantProvisionsAttendues > 1;
    const enAttenteValidation = this.chantier.countAppelsDePaiementInitiated > 0;
    const enAttenteLiberation = this.chantier.countAppelsDePaiementValidated > 0;

    return [
      ...(enAttenteProvision ? ['provision' as EnAttenteDe] : []),
      ...(enAttenteValidation ? ['validation' as EnAttenteDe] : []),
      ...(enAttenteLiberation ? ['liberation' as EnAttenteDe] : []),
    ];
  }

  get demarre(): string {
    return this.chantier.dateDebutReelle ? 'oui' : 'non';
  }

  get commissionCaptee(): MoneyFilterValue {
    if (!this.chantier.lemonwayAccountUrl || this.chantier.proCompany.archiType === 'interne') {
      return 'n/a';
    }

    if (this.chantier.montantCommissionPreleveeTTC >= this.chantier.montantCommissionTTC) {
      return 'en_totalite';
    }

    if (this.chantier.montantCommissionPreleveeTTC === 0) {
      return 'pas_du_tout';
    }

    return 'en_partie';
  }

  get prestationHemeaEncaissee(): MoneyFilterValue {
    if (
      !this.chantier.lemonwayAccountUrl ||
      (this.chantier.montantTravauxPlannerTTC === 0 &&
        this.chantier.proCompany.archiType !== 'interne')
    ) {
      return 'n/a';
    }

    if (this.chantier.montantTravauxPlannerTTC > 0) {
      if (this.chantier.montantTPEncaisseTTC >= this.chantier.montantTravauxPlannerTTC) {
        return 'en_totalite';
      }

      if (this.chantier.montantTPEncaisseTTC === 0) {
        return 'pas_du_tout';
      }

      return 'en_partie';
    }

    if (this.chantier.montantArchiInterneEncaisseTTC >= this.chantier.montantTotalTTC) {
      return 'en_totalite';
    }

    if (this.chantier.montantArchiInterneEncaisseTTC === 0) {
      return 'pas_du_tout';
    }

    return 'en_partie';
  }

  get versementALentreprise(): string {
    if (!this.chantier.lemonwayAccountUrl || this.chantier.proCompany.archiType === 'interne') {
      return 'n/a';
    }

    return this.chantier.countLiberationDeFonds > 0 ? 'au_moins_un' : 'aucun';
  }
}
