import { Tracker } from '@travauxlib/shared/src/lib/TrackingCore';
import { AdminFeatures, TrackingEvents } from '@travauxlib/shared/src/utils/tracking';

class PartenairesTrackingClass extends Tracker<AdminFeatures> {
  onPartenairesViewed(): void {
    this.sendTracking({ event: TrackingEvents.Viewed });
  }
}

export const PartenairesTracking = new PartenairesTrackingClass(AdminFeatures.Partenaires);
