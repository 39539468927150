import React from 'react';

import classNames from 'classnames';

import HelpCircle from '@travauxlib/shared/src/components/DesignSystem/assets/HelpCircle.svg?react';
import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import {
  Modal,
  ModalContent,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Table } from '@travauxlib/shared/src/components/DesignSystem/components/Table';
import { WithTooltip } from '@travauxlib/shared/src/components/DesignSystem/components/WithTooltip';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { roundToNDecimals, roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';
import { dateTimeFormat } from '@travauxlib/shared/src/utils/time';

import { CompanyType, PauseThreshold } from 'types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  companyType: CompanyType;
  pauseThresholds: PauseThreshold[];
  updatedAt?: string;
};

export const PauseThresholdsModal: React.FC<Props> = ({
  isOpen,
  onClose,
  pauseThresholds,
  updatedAt,
  companyType,
}) => {
  const isMobileOrTablet = useIsTabletOrMobile();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={onClose}
      size="lg"
      title="Seuils de mise en pause automatique"
      isScrollable={false}
    >
      <ModalContent>
        <AlertMessage
          level="info"
          title={
            companyType === 'architecte'
              ? 'Non concernée par la mise en pause auto'
              : 'Mise en pause automatique active'
          }
        />
        <Table<PauseThreshold>
          className="mb-lg"
          columnConfigs={[
            {
              name: 'Description',
              columnKey: 'reason',
              hideSort: true,
              renderValue: (reason: string, pauseThresholds) => (
                <div className="flex items-center">
                  <WithTooltip
                    fixedFloating
                    position={isMobileOrTablet ? 'top-start' : 'top'}
                    trigger={
                      <span className="mr-md">
                        <HelpCircle className="w-md" />
                      </span>
                    }
                  >
                    <div>{pauseThresholds.description}</div>
                  </WithTooltip>
                  {reason}
                </div>
              ),
            },
            {
              name: 'Seuil',
              columnKey: 'threshold',
              hideSort: true,
              renderValue: (threshold: number, item: PauseThreshold) => (
                <div className="text-right">
                  {item.isEuros ? (
                    <EURCurrency amount={roundToTwoDecimals(threshold)} forceFullPattern />
                  ) : (
                    threshold
                  )}
                </div>
              ),
            },
            {
              name: 'Valeur',
              columnKey: 'value',
              hideSort: true,
              renderValue: (value: number, item: PauseThreshold) => (
                <div className="text-right">
                  {value !== undefined ? (
                    item.isEuros ? (
                      <EURCurrency amount={roundToTwoDecimals(value)} forceFullPattern />
                    ) : (
                      value
                    )
                  ) : (
                    'N/A'
                  )}
                </div>
              ),
            },
            {
              name: 'Pourcentage',
              columnKey: 'value',
              hideSort: true,
              renderValue: (value: number, pauseThresholds: PauseThreshold) => {
                const pct = value && roundToNDecimals((value / pauseThresholds.threshold) * 100, 0);
                return (
                  <div
                    className={classNames('text-right', {
                      'text-error-600 font-bold': pct >= 100,
                    })}
                  >
                    {pct !== undefined ? `${pct} %` : 'N/A'}
                  </div>
                );
              },
            },
          ]}
          items={pauseThresholds}
        />
        {updatedAt && (
          <div className="text-ds-sm">Données mises à jour le {dateTimeFormat(updatedAt)}</div>
        )}
      </ModalContent>
    </Modal>
  );
};
