import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { CHANTIER_KEY } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantierQuery';

const fetchChantier = ({
  queryKey: [, slug],
}: QueryFunctionContext<[string, string]>): Promise<Chantier> =>
  request(`${APP_CONFIG.apiURL}/admin/chantiers/${slug}`);

export const useChantier = (
  slug: string,
): { isLoading: boolean; chantier: Chantier | undefined } => {
  const { isLoading, data } = useQuery<Chantier>({
    queryKey: [CHANTIER_KEY, slug],
    queryFn: fetchChantier,
    staleTime: 10 * 1000,
  });

  return { isLoading, chantier: data };
};
