import React from 'react';

import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { NumberInputField } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput/Field';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { ModalitePaiement } from '@travauxlib/shared/src/types';

export type Props = {
  name: string;
  value: ModalitePaiement;
  prixTotalTTC: number;
  onDelete: () => void;
  index: number;
  fieldsetDisabled: boolean;
  isSingleItem: boolean;
};

export const ModalitesPaiementItem: React.FC<Props> = ({
  name,
  value,
  prixTotalTTC,
  onDelete,
  fieldsetDisabled,
  isSingleItem,
}) => {
  const idPourcentage = `${name}-pourcentage`;
  return (
    <div className="my-sm">
      <div className="flex items-end">
        <div className="pr-md sm-desktop:w-3/12 w-7/12">
          <InputField
            disabled={fieldsetDisabled}
            name={`${name}.label`}
            id={`${name}-label`}
            label="Motif du versement"
          />
        </div>
        <div className="pr-md sm-desktop:w-2/12 w-5/12">
          <NumberInputField
            disabled={fieldsetDisabled}
            name={`${name}.pourcentage`}
            data-testid={idPourcentage}
            id={idPourcentage}
            fractionDigits={0}
            suffix="%"
            min={0}
            max={100}
          />
        </div>
        <div className="w-1/12 hidden sm-desktop:block">
          <div className="text-gray-600 text-ds-sm">Total</div>
          <EURCurrency
            className="text-neutral-800 font-medium"
            amount={(value.pourcentage * prixTotalTTC) / 100}
          />
        </div>
        <IconButton disabled={fieldsetDisabled || isSingleItem} onClick={onDelete}>
          <Delete className="w-md h-md" />
        </IconButton>
      </div>
      <div className="w-1/12 block sm-desktop:hidden my-sm">
        <div className="text-gray-600 text-ds-sm">Total</div>
        <EURCurrency
          className="text-neutral-800 font-medium"
          amount={(value.pourcentage * prixTotalTTC) / 100}
        />
      </div>
    </div>
  );
};
