import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const SummaryCard: React.FC<Props> = ({ title, children }) => (
  <Card className="!overflow-visible shrink-0" state="outlined">
    <div className="flex justify-between items-center mb-md">
      <div className="text-neutral-800 text-ds-h5 font-bold">{title}</div>
    </div>
    {children}
  </Card>
);
