import React from 'react';

import { CircularProgress } from '@travauxlib/shared/src/components/DesignSystem/components/CircularProgress';

type Props = {
  percentage: number;
  title: string;
  subtitle: string;
};

export const DisplayStat: React.FC<Props> = ({ percentage, title, subtitle }) => (
  <div className="w-full flex items-start rounded-xs">
    <CircularProgress value={percentage} variant="primary" />
    <div className="flex flex-col ml-xs">
      <span className="text-ds-b1 font-bold">{title}</span>
      <span className="text-ds-b2 text-neutral-700">{subtitle}</span>
    </div>
  </div>
);
