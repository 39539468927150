import React from 'react';

import { ColoredCircle } from '@travauxlib/shared/src/components/DesignSystem/components/ColoredCircle';

type Props = {
  firstDevisTotalHT: number;
  secondDevisTotalHT: number;
};

export const DisplayPriceDifference: React.FC<Props> = ({
  firstDevisTotalHT,
  secondDevisTotalHT,
}) => {
  let content = '0%';

  if (firstDevisTotalHT > secondDevisTotalHT) {
    content = `- ${Math.round(100 - (secondDevisTotalHT / firstDevisTotalHT) * 100)}%`;
  } else if (secondDevisTotalHT > firstDevisTotalHT) {
    content = `+ ${Math.round(
      ((secondDevisTotalHT - firstDevisTotalHT) / firstDevisTotalHT) * 100,
    )}%`;
  }

  return (
    <div className="sm-desktop:w-1/3 sm-desktop:ml-md">
      <div className="w-full flex items-start rounded-xs">
        <ColoredCircle
          className="border-1 border-primary text-neutral-800"
          size="xxl"
          variant="secondary"
        >
          <span className="text-ds-b1">{content}</span>
        </ColoredCircle>
        <div className="flex flex-col ml-xs">
          <span className="text-ds-b1 font-bold">Différence de prix</span>
          <span className="text-ds-b2 text-neutral-700">
            Différence de prix du devis de droite par rapport au devis de gauche.
          </span>
        </div>
      </div>
    </div>
  );
};
