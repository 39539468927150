import React from 'react';

import Calendar from '@travauxlib/shared/src/components/DesignSystem/assets/Calendar.svg?react';
import CheckCircleOutline from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircleOutline.svg?react';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { Consultation } from 'types';

type Props = {
  consultation: Consultation;
};

export const ConsultationMeeting: React.FC<Props> = ({ consultation: { meeting } }) =>
  meeting ? (
    <>
      {meeting.hasOccurred ? (
        <div className="text-ds-sm text-success-800 flex items-center">
          <CheckCircleOutline className="size-md mr-xxs" />
          <span className="text-success">Rendez-vous fait</span>
        </div>
      ) : (
        <div className="text-ds-sm text-neutral-700 flex items-center">
          <Calendar className="size-md mr-xxs" />
          Rendez-vous prévu
        </div>
      )}
      <div>{formatFrenchDate(meeting.startAt)}</div>
    </>
  ) : null;
