import React, { useState } from 'react';

import { Params, useParams } from 'react-router-dom';

import { AlertMessage } from '@travauxlib/shared/src/components/DesignSystem/components/AlertMessage';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { NumberInput } from '@travauxlib/shared/src/components/DesignSystem/components/NumberInput';
import { roundToTwoDecimals } from '@travauxlib/shared/src/utils/format';

import { CommissionCard } from './CommissionCard';
import { SeparationTag } from './ModalitesDevis';

import { useDevisModalites } from '../api/useDevisModalites';

export const Commission: React.FC = () => {
  const { devisToken } = useParams<Params>();
  const { data: modalitesDevis } = useDevisModalites(devisToken!);
  const [prixDevisFinalViseAvecService, setPrixDevisFinalViseAvecService] = useState(0);

  if (!modalitesDevis) {
    return <LoaderWrapper />;
  }

  const tauxCommission =
    modalitesDevis.modalites?.tauxCommissionHemea ||
    modalitesDevis.defaultModalites.tauxCommissionHemea;
  const tauxService =
    (modalitesDevis.modalites?.tauxServiceHemea ||
      modalitesDevis.defaultModalites.tauxServiceHemea) /
      100 +
    1;

  return modalitesDevis ? (
    <Card>
      <div className="text-ds-h3">Commission hemea</div>
      <div className="text-ds-h4 flex justify-center my-md">
        Outil pour faire une remise commerciale (ne fonctionne pas avec les TP)
      </div>
      <div className="flex justify-center gap-[4rem] mb-[4rem]">
        <div className="max-w-1/2 mt-md flex-col">
          <AlertMessage className="mb-md" title="MARCHE À SUIVRE :" level="error">
            - Renseigner le prix visé final service hemea inclus du devis <br />
            <br /> - Mettre à jour le taux sur cette page
            <br />
            <br /> - Appliquer la remise sur le devis
            <br />
          </AlertMessage>
          <NumberInput
            id="simulateur-prixDevisFinalViseAvecService"
            label="Entre ici le prix final visé du devis SERVICE INCLUS"
            value={prixDevisFinalViseAvecService}
            onChange={e => setPrixDevisFinalViseAvecService(e!)}
          />
        </div>
        <div>
          {prixDevisFinalViseAvecService ? (
            <div className="mt-md">
              <div className="mb-md">
                <strong>NOUVEAU %</strong> de commission HEMEA à appliquer
              </div>
              <div className="mb-md font-bold">
                {roundToTwoDecimals(
                  ((modalitesDevis.prixTotalHT * (tauxCommission / 100) -
                    (modalitesDevis.prixTotalTTC * tauxService - prixDevisFinalViseAvecService) /
                      tauxService) /
                    modalitesDevis.prixTotalHT) *
                    100,
                )}{' '}
                %
              </div>
              <div>
                {' '}
                Remise à appliquer sur le devis en <strong>TTC</strong> :
              </div>
              <div className="mb-md font-bold">
                {roundToTwoDecimals(
                  (modalitesDevis.prixTotalTTC * tauxService - prixDevisFinalViseAvecService) /
                    tauxService,
                )}{' '}
                €
              </div>
              <div>
                {' '}
                Remise à appliquer sur le devis en <strong>HT</strong> :
              </div>
              <div className="mb-md font-bold">
                {roundToTwoDecimals(
                  (modalitesDevis.prixTotalTTC * tauxService - prixDevisFinalViseAvecService) /
                    tauxService /
                    1.2,
                )}{' '}
                €
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="flex mt-md justify-center">
        (<SeparationTag label="MontantDevisHT" />
        &nbsp;x&nbsp;
        <SeparationTag label="(TauxCommission / 100)" />) &nbsp;x&nbsp;
        <SeparationTag label="1.20 (TVA)" />
        &nbsp;=&nbsp;MontantCommissionHemeaTTC
      </div>
      <CommissionCard
        devisToken={devisToken!}
        commissionKey="tauxCommissionHemea"
        basePrice={modalitesDevis.prixTotalHT}
        customRate={modalitesDevis.modalites?.tauxCommissionHemea}
        defaultRate={modalitesDevis.defaultModalites.tauxCommissionHemea}
        doApplyTva
        devisSigned={modalitesDevis.devisSigned}
      />
    </Card>
  ) : null;
};
