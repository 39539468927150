import React from 'react';

import Bet from '@travauxlib/shared/src/components/DesignSystem/assets/BetDuoTone.svg?react';
import Draw from '@travauxlib/shared/src/components/DesignSystem/assets/DrawDuoTone.svg?react';
import Demolition from '@travauxlib/shared/src/components/DesignSystem/assets/Workman.svg?react';
import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';

type Props = {
  proCompany: ProCompanyAdminView;
};

type ValueTag = {
  icon: JSX.Element;
  value: string;
};

const ICON_CLASS = 'w-xmd h-xmd pr-xxs';
const archiTypeMap: Record<string, ValueTag> = {
  interne: { icon: <Draw className={ICON_CLASS} />, value: 'Architecte interne' },
  externe: { icon: <Draw className={ICON_CLASS} />, value: 'Architecte externe' },
  bet: { icon: <Bet className={ICON_CLASS} />, value: 'BET' },
};

const getValueCompanyTag = (proCompany: ProCompanyAdminView): ValueTag => {
  const { archiType, companyType } = proCompany;

  if (companyType === 'pro') {
    return { icon: <Demolition className={ICON_CLASS} />, value: 'PRO' };
  }

  if (archiType) {
    return archiTypeMap[archiType];
  }

  return archiTypeMap.externe;
};

export const CompanyTag: React.FC<Props> = ({ proCompany }) => {
  const companyTag = getValueCompanyTag(proCompany);

  return (
    <span className="text-ds-b2 b rounded-sm py-3xs px-xs text-gray-700 border-1 border-neutral-300 items-center flex">
      {companyTag.icon}
      {companyTag.value}
    </span>
  );
};
