import React, { useState } from 'react';

import { Badge } from '@travauxlib/shared/src/components/DesignSystem/components/Badge';
import { ChipList } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/ChipList';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { useSuiviChantierHistoryList } from 'api/useSuiviChantierHistoryList';

import { FluxFinanciersEtatAvancement } from './FluxFinanciersEtatAvancement';
import { FluxFinanciersHeader } from './FluxFinanciersHeader';
import { FluxFinanciersHistorique } from './FluxFinanciersHistorique';

type Props = {
  chantier: Chantier;
};

type FluxFinancierViewType = 'etat_avancement' | 'historique';

export const ChantierFluxFinanciers: React.FC<Props> = ({ chantier }) => {
  const [fluxFinanciersView, setFluxFinanciersView] = useState<FluxFinancierViewType>('historique');

  const { suiviChantierHistoryList } = useSuiviChantierHistoryList(chantier.slug);

  const nbCyclePending = suiviChantierHistoryList.filter(
    cycle => cycle.status === 'pending',
  ).length;

  return (
    <div className="bg-neutral-0 my-md rounded-md p-md shadow-ds-xxs">
      <FluxFinanciersHeader chantier={chantier} />
      <div className="flex flex-col sm-desktop:flex-row sm-desktop:items-center w-fit">
        <ChipList
          options={[
            { value: 'historique', label: 'Historique' },
            { value: 'etat_avancement', label: "État d'avancement" },
          ]}
          renderingOptions={{
            containerClassName: 'flex flex-col sm-desktop:flex-row mt-xl gap-md',
          }}
          value={fluxFinanciersView}
          onChange={(newType: FluxFinancierViewType) => setFluxFinanciersView(newType)}
        />
        {nbCyclePending > 0 && fluxFinanciersView !== 'etat_avancement' && (
          <Badge
            className="relative self-end -top-[2.3rem] left-3xs sm-desktop:-left-sm sm-desktop:top-xxs sm-desktop:self-auto"
            size="sm"
            variant="error"
            value={nbCyclePending}
          />
        )}
      </div>
      {fluxFinanciersView === 'historique' ? (
        <FluxFinanciersHistorique chantier={chantier} />
      ) : (
        <FluxFinanciersEtatAvancement
          chantier={chantier}
          suiviChantierHistoryList={suiviChantierHistoryList}
        />
      )}
    </div>
  );
};
