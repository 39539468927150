import React from 'react';

import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { required } from '@travauxlib/shared/src/utils/form';

import { CampaignInfos } from 'features/DashboardCDP/types';

import { SubCampaign } from './SubCampaign';

type Props = {
  partnerCampaigns: CampaignInfos[];
  partnerSubCampaigns: CampaignInfos[];
};

export const CampaignAndSubCampaign: React.FC<Props> = ({
  partnerCampaigns,
  partnerSubCampaigns,
}) => (
  <>
    <div className="col-span-full sm-desktop:col-span-6 mb-md">
      <DropdownField
        name="campaign"
        id="campaign"
        validate={required}
        options={partnerCampaigns.map(partner => ({
          label: partner.attributionName,
          value: partner,
        }))}
        label="Nom du partenaire"
      />
    </div>

    {partnerSubCampaigns.length > 0 && <SubCampaign partnerSubCampaigns={partnerSubCampaigns} />}
  </>
);
