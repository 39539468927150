import React, { useState } from 'react';

import { Form } from 'react-final-form';

import { AutoSubmitForm } from '@travauxlib/shared/src/components/AutoSubmitForm';
import BorderColor from '@travauxlib/shared/src/components/DesignSystem/assets/BorderColor.svg?react';
import Delete from '@travauxlib/shared/src/components/DesignSystem/assets/Delete.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { TextareaField } from '@travauxlib/shared/src/components/DesignSystem/components/Textarea/Field';
import { Chantier } from '@travauxlib/shared/src/features/Chantiers/types';

import { useUpdateChantier } from '../../api/useUpdateChantier';

type Props = {
  chantier: Chantier;
};

export const ChantierBlocNote: React.FC<Props> = ({ chantier }) => {
  const [editingBlocNote, setEditingBlocNote] = useState(!!chantier.blocNote);
  const { updateChantier } = useUpdateChantier({
    hideNotification: true,
  });

  const { slug } = chantier;

  return (
    <div className="mt-md">
      <Form
        onSubmit={({ blocNote }) => {
          updateChantier({
            slug,
            blocNote,
          });
        }}
        initialValues={{
          blocNote: chantier.blocNote ?? '',
        }}
      >
        {({ handleSubmit, values: { blocNote }, form }) => (
          <form onSubmit={handleSubmit}>
            <AutoSubmitForm />
            {editingBlocNote ? (
              <Card state="outlined">
                <div className="flex justify-between">
                  <div className="text-ds-h5 font-bold mb-md">Note sur le chantier</div>
                  <Delete
                    onClick={() => {
                      setEditingBlocNote(false);
                      form.change('blocNote', '');
                      handleSubmit();
                    }}
                    className="w-lg h-lg cursor-pointer"
                  />
                </div>
                <TextareaField name="blocNote" id="text" value={blocNote} placeholder="Note" />
              </Card>
            ) : (
              <div
                onClick={() => setEditingBlocNote(true)}
                className="text-center mt-md px-md cursor-pointer flex justify-center py-sm items-center"
              >
                <BorderColor className="w-lg h-lg" />
                <span className="text-ds-b1 font-bold ml-xxs">
                  Ajouter une note sur le chantier
                </span>
              </div>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};
