import { PersonRole } from '@travauxlib/shared/src/types';
import { LabellisationStatus } from '@travauxlib/shared/src/types/company';

import { ChaleurLead, CompanyType, LeadStatus, NonAdressableReason } from 'types';

export { certifications } from '@travauxlib/shared/src/utils/constants';

export enum PermissionLabel {
  'BYPASS_DEVIS_ANNULATION_CONDITIONS' = 'Autorisé a annuler un devis quelque soit la situation',
  'READ_ALL_LEADS' = 'Autorisé à voir tous les leads',
  'UPDATE_ASSIGNMENT_CONFIG' = "Autorisé à modifier les paramètres de l'attribution automatique des leads et la libération d'un pro d'une consultation",
  'UPDATE_ATTRIBUTION_LEAD' = 'Autorisé à attribuer manuellement un lead',
  'UPDATE_CHANTIER_CONFIG' = 'Autorisé à mettre en sourdine les relances de provision sur les chantiers',
  'UPDATE_MODALITE_HEMEA' = 'Autorisé à modifier les modalités hemea sur les devis',
  'UPDATE_MODE_CALCUL_COMMISSION' = 'Autorisé à modifier le mode de calcul de la commission',
  'VALIDATE_APPELS_DE_PAIEMENT' = 'Autorisé à valider les appels de paiement à la place du client',
  'ZENDESK_SUIVI_ACTIVATED' = 'Autorisé à gérer le suivi de chantier via Zendesk',
  'EDIT_FLUX_FINANCIERS' = 'Autorisé à gérer le flux financiers',
  'CREATE_PARTNER_ORGANIZATION' = 'Autorisé à créer des organisations partenaires',
}

export const specializationsArchitects = [
  'Aménagement extérieur',
  'Bureaux',
  'Changement de destination',
  'Commerce',
  'Construction',
  'Décoration',
  'Design de mobilier sur mesure',
  'Étude technique',
  'Extension',
  'Hôpital / cabinet médical',
  'Hôtels',
  'Immeuble',
  'Lumière - éclairage',
  'Petits espaces',
  'Rénovation appartement grande surface',
  'Rénovation appartement moyenne surface',
  'Rénovation appartement petite surface',
  'Rénovation maison',
  'Rénovation touchant à la structure',
  'Restaurants',
  'Scénographie',
  'Store / retail',
  'Surélévation',
  'Théâtre',
];

export const travailEnInterne = [
  'Couverture',
  'Charpente',
  'Climatisation',
  'Isolation intérieure',
  'Isolation extérieure',
  'Maçonnerie',
  'Menuiseries intérieures',
  'Menuiseries extérieures',
  'Meubles sur mesure',
  'Ouvertures de murs porteurs & trémies',
  'Ossature bois',
  'Peinture',
  'Plâtrerie',
  'Plomberie',
  'Serrurerie/Métallerie',
  'Sols durs - Carrelage',
  'Sols souples - Parquet',
  'Vitrerie/Miroiterie',
  'Électricité',
].map(value => ({ value, label: value }));

export const assurancesArchitecte = ['Suivi de chantier', "Maitrise d'œuvre +150 m²"];

export const lostReasons = [
  {
    label: 'Hors zone',
    value: 'Hors zone géographique',
  },
  {
    label: 'Trop peu de corps de métiers',
    value: 'Trop peu de corps de métiers',
  },
  {
    label: 'Moins de 2 ans',
    value: 'Moins de 2 ans',
  },
  {
    label: 'Code Naf incohérent',
    value: 'Code Naf incohérent',
  },
  {
    label: 'CA insuffisant',
    value: 'CA insuffisant',
  },
  {
    label: 'Juste logiciel de devis',
    value: 'Juste logiciel de devis',
  },
  {
    label: 'Fail Corpo',
    value: 'Fail Corpo',
  },
  {
    label: "C'était un test",
    value: "C'était un test",
  },
];

export const labellisationStatusesById: {
  [key in LabellisationStatus]: {
    label: string;
    optionLabel: string;
  };
} = {
  ACTIVEE: {
    label: 'Activées',
    optionLabel: 'Activée',
  },
  LABELLISEE: {
    label: 'Labellisées',
    optionLabel: 'Labellisée',
  },
  LABELLISATION_EN_COURS: {
    label: 'En cours de labellisation',
    optionLabel: 'En cours de labellisation',
  },
  NON_LABELLISEE: {
    label: 'Non labellisées',
    optionLabel: 'Non labellisée',
  },
  DELABELLISEE: {
    label: 'Délabellisées',
    optionLabel: 'Délabellisée',
  },
};

export const companyTypes: Array<{
  value: CompanyType;
  label: string;
}> = [
  {
    value: 'architecte',
    label: 'Architecte',
  },
  {
    value: 'pro',
    label: 'Pro',
  },
  {
    value: 'mandataire',
    label: 'CDPL',
  },
];

export type PermissionType =
  | 'UPDATE_ASSIGNMENT_CONFIG'
  | 'UPDATE_CHANTIER_CONFIG'
  | 'UPDATE_MODALITE_HEMEA'
  | 'VALIDATE_APPELS_DE_PAIEMENT'
  | 'UPDATE_ATTRIBUTION_LEAD'
  | 'READ_ALL_LEADS'
  | 'UPDATE_MODE_CALCUL_COMMISSION'
  | 'ZENDESK_SUIVI_ACTIVATED'
  | 'EDIT_FLUX_FINANCIERS'
  | 'BYPASS_DEVIS_ANNULATION_CONDITIONS'
  | 'CREATE_PARTNER_ORGANIZATION';

export const disabledMessageForPermissionLabel: (permission: PermissionType) => string = (
  permission: PermissionType,
) => `Permission requise : ${PermissionLabel[permission]}`;

export const permissionValues: Array<{
  value: PermissionType;
  label: string;
}> = [
  {
    value: 'UPDATE_ASSIGNMENT_CONFIG',
    label:
      "Autorisé à modifier les paramètres de l'attribution automatique et la libération d'un pro",
  },
  {
    value: 'VALIDATE_APPELS_DE_PAIEMENT',
    label: 'Autorisé à valider les appels de paiement à la place du client',
  },
  {
    value: 'UPDATE_CHANTIER_CONFIG',
    label: 'Autorisé à mettre en sourdine les relances de provision sur les chantiers',
  },
  {
    value: 'UPDATE_MODALITE_HEMEA',
    label: 'Autorisé à modifier les modalités hemea sur les devis',
  },
  {
    value: 'BYPASS_DEVIS_ANNULATION_CONDITIONS',
    label: 'Autorisé a annuler un devis quelque soit la situation',
  },
  {
    value: 'UPDATE_ATTRIBUTION_LEAD',
    label: 'Autorisé à attribuer manuellement un lead',
  },
  {
    value: 'READ_ALL_LEADS',
    label: 'Autorisé a consulter tous les leads',
  },
  {
    value: 'UPDATE_MODE_CALCUL_COMMISSION',
    label: 'Autorisé à modifier le mode de calcul de la commission',
  },
  {
    value: 'ZENDESK_SUIVI_ACTIVATED',
    label: 'Autorisé à gérer le suivi de chantier via Zendesk',
  },
  {
    value: 'EDIT_FLUX_FINANCIERS',
    label: 'Autorisé à gérer le flux financiers',
  },
  {
    value: 'CREATE_PARTNER_ORGANIZATION',
    label: 'Autorisé à créer des organisations partenaires',
  },
];

export const architecteProfile = [
  'Calme / Patient',
  'Commercial',
  'Ecoute et dit oui',
  'Force de proposition',
  'Rassurant / Pédagogue',
  'Technique',
];

export const profile = [...architecteProfile, 'Habitué à travailler avec un archi'];

export const additionalInformations = [
  'Amiante',
  'Boutiques',
  'B2B',
  'B2B Uniquement',
  'Bureaux',
  'Hôtels',
  'Plan 3D',
  'Restaurants',
  'Showroom',
  'Syndic',
];

export const insurancesType = ['Décennale + RC pro', 'Décennale', 'RC pro', 'Autre'].map(item => ({
  label: item,
  value: item,
}));

export const statusATraiter: LeadStatus = 'A traiter';
export const statusAdressable: LeadStatus = 'Adressable';
export const statusNonAdressable: LeadStatus = 'Non adressable';

export const nonAdressableTestEstimateur = 'Test estimateur';
export const nonAdressableDoublon = 'Doublon';
export const leadRefuse = 'Lead refusé';

export const nonAdressableReasons: Array<{
  value: NonAdressableReason;
  label: string;
  disabled?: boolean;
}> = [
  {
    value: nonAdressableDoublon,
    label: nonAdressableDoublon,
  },
  {
    value: nonAdressableTestEstimateur,
    label: nonAdressableTestEstimateur,
  },
  {
    value: 'Hors zone géographique',
    label: 'Hors zone',
  },
  {
    value: 'Trop petits travaux',
    label: 'Trop petits travaux',
  },
  {
    value: 'Nous ne savons pas faire',
    label: 'Nous ne savons pas faire',
  },
  {
    value: 'Téléphone invalide',
    label: 'Téléphone invalide',
  },
  {
    value: 'Budget inadapté',
    label: 'Budget inadapté',
  },
  {
    value: 'Lead non qualifié',
    label: 'Lead non qualifié',
  },
  {
    value: 'Score trop bas',
    label: 'Score trop bas',
    disabled: true,
  },
  {
    value: 'Score incompatible',
    label: 'Score incompatible',
    disabled: true,
  },
];

export const chaleursLead: ChaleurLead[] = ['Calendly', 'Contact tenté'];

export const noDealOwner = 'no_deal_owner';

export const pauseReasonLabels: { [K: string]: string } = {
  max_volume_mer: '[auto] Max volume consultation atteint',
  max_volume_signature: '[auto] Max volume Signature atteint',
  max_count_mers: '[auto] Max nombre de consultation atteint',
  max_chantiers: '[auto] Max nombre de chantiers en cours',
  max_count_mers_pro_labellise: '[auto] Max nombre de consultation pro labellisé atteint',
  waiting_for_end_of_two_first_chantiers: '[auto] En attente fin de ses 2 premiers chantiers',
  pro_overwhelmed: 'Pro débordé',
  too_many_projects: 'A reçu trop de projets',
  messed_up_chantier: 'Complication sur chantier',
  holidays: 'Vacances',
  complications_before_chantier: 'Complications avant chantier',
};

export const planTypeOptions = [
  {
    label: 'Aucun',
    value: undefined,
  },
  {
    label: 'Legacy Essentiel',
    value: 'legacy_essentiel',
  },
  {
    label: 'Legacy Plus',
    value: 'legacy_plus',
  },
  {
    label: 'Essentiel',
    value: 'essentiel',
  },
  {
    label: 'Pro',
    value: 'pro',
  },
];

export const pauseReasons = Object.entries(pauseReasonLabels)
  .map(([value, label]) => ({ value, label, disabled: label.includes('[auto]') }))
  .sort((a, b) => (a.label < b.label ? -1 : 1));

export const affiliateFilters = {
  allLeads: {
    label: 'Tous les leads',
    value: 'allLeads',
  },
  partenariats: {
    label: 'Partners offline',
    value: 'partenariatsOffline',
  },
  horsPartenariats: {
    label: 'Hors partners offline',
    value: 'horsPartenariatsOffline',
  },
};

export const personRoleToLabel: { [K in PersonRole]: string } = {
  admin: 'Administrateur',
  team_lead_partenariat: 'Team Lead Partenariat',
  responsable_partenariat: 'Responsable Partenariats',
  team_lead_qualite: 'Team Lead Qualité',
  customer_success_manager: 'Customer Success Manager',
  customer_care: 'Customer Care',
  travaux_planner_interne: 'Travaux Planner Interne',
  travaux_planner_externe: 'Travaux Planner Externe',
  team_lead_cdp: 'Team Lead CDP',
  cdp: 'CDP',
  cdpl: 'CDPL',
  rh: 'RH',
  recruteur: 'Recruteur',
  team_lead_marketing: 'Team Lead Marketing',
  responsable_acquisition: 'Responsable Acquisition',
};

export const personRoleOptions = Object.entries(personRoleToLabel).map(([value, label]) => ({
  label,
  value,
}));
